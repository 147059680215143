import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import withLoader from "../../layout/Loader/WithLoader";
import PaidDealerDashboardGraph from "./PaidDealerDashboardGraph";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import Enums from "../../utils/enums";
import DashboardService from "../../services/dashboard.service";

const PaidDealerDashboard = () => {
  const dashboardService = DashboardService();
  const { t } = useTranslation("common");
  const { RANGES } = Enums();
  const [totalRechargeOfDay, setTotalRechargeOfDay] = useState([]);
  const [totalAmountOfRechargeOfDay, setTotalAmountOfRechargeOfDay] = useState(
    []
  );
  const [totalPurchasedAmount, setTotalPurchasedAmount] = useState([]);
  const [totalAvailableAmount, setTotalAvailableAmount] = useState([]);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const getAdminDashboard = async () => {
    const response = await dashboardService.getPaidDealerDashboard({
      city: "",
      deviceType: null,
      fromDate: new Date(startDate).getTime(),
      toDate: new Date(endDate).getTime(),
    });
    setTotalRechargeOfDay(response.data.apiresponse.data.totalNumberOfRefills);
    setTotalAmountOfRechargeOfDay(
      response.data.apiresponse.data.totalRefillsAmount
    );
    setTotalPurchasedAmount(
      response.data.apiresponse.data.totalPurchasedAmount
    );
    setTotalAvailableAmount(
      response.data.apiresponse.data.totalAvailableAmount
    );
  };

  useEffect(() => {
    getAdminDashboard();
  }, [startDate, endDate]);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-24 mb-4 mt-2">
          {t("dashboardname")}
        </h2>
        <Col sm={4} md={4} lg={4} xl={3} xxl={2} className="mb-4">
          {/* <DateRangePicker
            initialSettings={{
              startDate: moment(new Date())
                .subtract(1, "months")
                .format("MM/DD/YYYY"),
              endDate: moment(new Date()).format("MM/DD/YYYY"),
              ranges: RANGES,
            }}
            onApply={setDates}
          >
            <input
              type="text"
              value={
                moment(startDate).format("DD/MM/YYYY") +
                " - " +
                moment(endDate).format("DD/MM/YYYY")
              }
              className="form-control"
            />
          </DateRangePicker> */}
          <DateRangePicker
            initialSettings={{
              startDate: moment(new Date()).format("DD/MM/YYYY"),
              endDate: moment(new Date()).format("DD/MM/YYYY"),
              ranges: RANGES,
              autoUpdateInput: false,
              locale: {
                format: "DD/MM/YYYY", // Specify the desired format
              },
            }}
            onApply={setDates}
            onCancel={cancelDate}
            onHide={cancelDate}
          >
            {startDate && endDate ? (
              <input
                type="text"
                placeholder={t("selectperiodvalidity")}
                value={
                  startDate && endDate
                    ? `${moment(startDate).format("DD/MM/YYYY")} - ${moment(
                        endDate
                      ).format("DD/MM/YYYY")}`
                    : ""
                }
                defaultValue=""
                onChange={(startDate, endDate) =>
                  handleDateChange(startDate, endDate)
                }
                className="form-control"
              />
            ) : (
              <input
                type="text"
                placeholder={t("selectperiodvalidity")}
                defaultValue=""
                className="form-control"
              />
            )}
          </DateRangePicker>
        </Col>

        <div className="row mb-4">
          <Col sm={12} md={6} lg={6} xl={3}>
            <Card className="custom-card pointer">
              <Card.Body>
                <div className="card-order ">
                  <label className="main-content-label font-weight-bold mb-3 pt-1">
                    {t("totalrechargeoftheday")}
                  </label>
                  <h2 className="text-end card-item-icon card-icon">
                    <i className="fas fa-wallet icon-size float-start text-primary"></i>
                    <span className="font-weight-bold">
                      {totalRechargeOfDay.totalRecords}
                    </span>
                  </h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3}>
            <Card className="custom-card pointer">
              <Card.Body>
                <div className="card-order ">
                  <label className="main-content-label font-weight-bold mb-3 pt-1">
                    {t("totalamountrechargeoftheday")}
                  </label>
                  <h2 className="text-end card-item-icon card-icon">
                    <i className="mdi mdi-account-multiple icon-size float-start text-primary"></i>
                    <span className="font-weight-bold">
                      {totalAmountOfRechargeOfDay.totalAmount}
                    </span>
                  </h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3}>
            <Card className="custom-card pointer">
              <Card.Body>
                <div className="card-order ">
                  <label className="main-content-label font-weight-bold mb-3 pt-1">
                    {t("totalpurchasedamount")}
                  </label>
                  <h2 className="text-end card-item-icon card-icon">
                    <i className="mdi mdi-currency-eur icon-size float-start text-primary"></i>
                    <span className="font-weight-bold">
                      {totalPurchasedAmount}
                    </span>
                  </h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={6} xl={3}>
            <Card className="custom-card pointer">
              <Card.Body>
                <div className="card-order ">
                  <label className="main-content-label font-weight-bold mb-3 pt-1">
                    {t("totalavailableamount")}
                  </label>
                  <h2 className="text-end card-item-icon card-icon">
                    <i className="mdi mdi-currency-eur icon-size float-start text-primary"></i>
                    <span className="font-weight-bold">
                      {totalAvailableAmount}
                    </span>
                  </h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </div>

        <Row className="row-sm">
          <Col sm={12} lg={12} xl={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Header className="border-bottom-0">
                <div>
                  <label className="main-content-label mb-2">
                    {" "}
                    {t("graph")}
                  </label>
                  {/* <span className="d-block tx-12 mb-0 text-muted">
                  The Project Budget is a tool used by project managers to
                  estimate the total cost of a car
                </span> */}
                </div>
              </Card.Header>
              <Card.Body className="ps-12">
                <div>
                  <div className="chart-dropshadow2 ht-300">
                    <div className="chart-dropshadow2 ht-300">
                      <PaidDealerDashboardGraph
                        totalAmountOfRechargeOfDay={totalAmountOfRechargeOfDay}
                        totalRechargeOfDay={totalRechargeOfDay}
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

PaidDealerDashboard.propTypes = {};

PaidDealerDashboard.defaultProps = {};

export default withLoader(PaidDealerDashboard);
