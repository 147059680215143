import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import withLoader from "../../layout/Loader/WithLoader";
import DealerReceipt from "../../../assets/invoices/DealerReceipt.pdf";
import DealerReceiptXML from "../../../assets/invoices/DealerReceipt.xml";
import PreviewPDF from "../Invoices/PreviewPDF";
import { Page } from "react-pdf";
import {
  CUSTOM_STYLES,
  PAGELIMIT,
  ReplaceFunction,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";
import InvoiceService from "../../services/invoice.service";
import moment from "moment";
import ExportPDF from "../Invoices/ExportPDF";
import UserService from "../../services/user.service";

const PaidDealerInvoices = () => {
  const invoiceService = InvoiceService();
  const userService = UserService();
  const { t } = useTranslation("common");
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [pageNumber, setPageNumber] = useState(1);
  const [openPDF, setOpenPDF] = useState(false);
  const [numPagesTerms, setNumPagesTerms] = useState(null);
  const [newRole, setNewRole] = useState("");
  const [searchString, setSearchString] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [adminDetails, setAdminDetails] = useState([]);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState("");

  const handlesearch = (e) => {
    setSearchString(e.target.value);
  };

  const viewPDF = async (id) => {
    setOpenPDF(true);
    const response = await invoiceService.invoiceDetails(id);
    setInvoiceDetail(response.data.apiresponse.data.invoiceDetail);
    setUserDetails(response.data.apiresponse.data.userDetail);

    const adminDetailResponse = await userService.getAdmin();
    setAdminDetails(adminDetailResponse.data.apiresponse.data);
    getInvoices();
  };

  function onDocumentTermsLoadSuccess({ numPages }) {
    setNumPagesTerms(numPages);
  }

  const getInvoices = async () => {
    const response = await invoiceService.getDealerInvoice(
      dataPerPage,
      pageNumber
    );
    setData(response.data.apiresponse.data.invoices);
    setTotalRows(response.data.apiresponse.data.totalRecords);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  useEffect(() => {
    getInvoices();
  }, [dataPerPage, pageNumber]);

  const PageWithNumber = ({ pageNumber }) => (
    <div style={{ position: "relative" }}>
      <Page pageNumber={pageNumber} width={900} />
      <div
        style={{
          textAlign: "center",
          background: "#FFFFFF",
          opacity: 0.8,
        }}
      >
        <span style={{ fontSize: "12px" }}>Page {pageNumber}</span>
      </div>
    </div>
  );

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("fromdate")}</strong>,
      selector: "fromDate",
      cell: (row) => (
        <div
          title={
            row.fromDate ? `${moment(row.fromDate).format("DD/MM/YYYY")}` : "--"
          }
        >
          {row.fromDate ? moment(row.fromDate).format("DD/MM/YYYY") : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("todate")}</strong>,
      selector: "toDate",
      cell: (row) => (
        <div
          title={
            row.toDate ? `${moment(row.toDate).format("DD/MM/YYYY")}` : "--"
          }
        >
          {row.toDate ? moment(row.toDate).format("DD/MM/YYYY") : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalrefills")}</strong>,
      selector: "totalRefills",
      cell: (row) => (
        <div title={`${row.totalRefills}`}>{row.totalRefills}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("totalrefillsamount")}</strong>,
      selector: "totalRefillAmount",
      cell: (row) => (
        <div
          title={`€${
            row.totalRefillAmount - row.totalCommisionAmount !== 0
              ? ReplaceFunction(
                  (
                    row.totalRefillAmount?.toFixed(2) -
                    row.totalCommisionAmount?.toFixed(2)
                  ).toFixed(2)
                )
              : row.totalRefillAmount - row.totalCommisionAmount
          }`}
        >
          {`€${
            row.totalRefillAmount - row.totalCommisionAmount !== 0
              ? ReplaceFunction(
                  (
                    row.totalRefillAmount?.toFixed(2) -
                    row.totalCommisionAmount?.toFixed(2)
                  ).toFixed(2)
                )
              : row.totalRefillAmount - row.totalCommisionAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("totalcommissionamount")}</strong>
      ),
      selector: "totalCommisionAmount",
      cell: (row) => (
        <div
          title={`€${
            row.totalCommisionAmount
              ? ReplaceFunction(row.totalCommisionAmount?.toFixed(2))
              : row.totalCommisionAmount
          }`}
        >
          {`€${
            row.totalCommisionAmount
              ? ReplaceFunction(row.totalCommisionAmount?.toFixed(2))
              : row.totalCommisionAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("action")}</strong>,
      selector: "action",
      cell: (row) => (
        <div className="d-flex">
          <div className="me-2">
            <Link
              className="btn btn-primary btn-sm"
              onClick={() => viewPDF(row.id)}
            >
              <i className="fe fe-eye"></i>
            </Link>
          </div>
          {/* <a href={DealerReceipt} download="DealerReceipt">
            <button className="btn btn-primary btn-sm">
              <i className="fa fa-download"></i>
            </button>
          </a>
          <a href={DealerReceiptXML} download="DealerReceipt">
            <button className="btn btn-primary btn-sm ms-2">
              <i class="fa fa-code"></i>
            </button>
          </a> */}
          <div>
            <ExportPDF id={row.id} />
          </div>
        </div>
      ),
      sortable: true,
      minWidth: "160px",
    },
  ];

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">{t("myreceipts")}</h2>
      <Card>
        {openPDF && (
          <PreviewPDF
            newRole={newRole}
            show={openPDF}
            onHide={() => setOpenPDF(false)}
            onDocumentTermsLoadSuccess={onDocumentTermsLoadSuccess}
            numPagesTerms={numPagesTerms}
            PageWithNumber={PageWithNumber}
            userDetails={userDetails}
            invoiceDetail={invoiceDetail}
            adminDetails={adminDetails}
          />
        )}

        {/* <div className="d-flex add-search mt-3 me-3">
          <div className="d-flex align-items-center md-form mt-auto ms-auto">
            <div className="input-group">
              <input
                type="search"
                className="form-control form-control-md py-3"
                value={searchString}
                onChange={handlesearch}
                placeholder={t("search") + "..."}
              />
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="p-3">
          <DataTable
            title="Paid Dealer Invoices"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={CUSTOM_STYLES}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(PaidDealerInvoices);
