import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import withLoader from "../../layout/Loader/WithLoader";
import CityService from "../../services/city.service";
import moment from "moment";
import { ScrollToTop } from "../../utils/ScrollTop";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  ANCHORORIGIN,
  ERROR,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import { enqueueSnackbar } from "notistack";
import STATE from "../../utils/city.json";
import * as yup from "yup";
import useDialogState from "../../hooks/useDialog";
import useMenuState from "../../hooks/useMenuState";
import AddCity from "./AddCity";
import DeleteCity from "./DeleteCity";
import Enums from "../../utils/enums";

const CityProfile = () => {
  const navigate = useNavigate();
  const cityService = CityService();
  const { BUSINESS_PARTNER_API_NAME } = Enums();
  const { t } = useTranslation("common");
  let { id } = useParams();
  const [data, setData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [newCommision, setNewCommision] = useState("");
  const [newState, setNewState] = useState("");
  const [newCity, setNewCity] = useState("");
  const [details, setDetails] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [visibleOptions, setVisibleOptions] = useState(10);
  const [search, setSearch] = useState("");
  const [cashbackInAmount, setCashbackInAmount] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [
    addBussinessPartnerAPICredentials,
    setAddBussinessPartnerAPICredentials,
  ] = useState(1);

  const [
    openBusinessPartnerAPIName,
    handleOpenBusinessPartnerAPIName,
    handleCloseBusinessPartnerAPIName,
  ] = useMenuState(Array(BUSINESS_PARTNER_API_NAME.length).fill(false));

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const NewState = [
    ...new Set(STATE.map((data) => data.STATE + " (" + data.PROVINCE + ")")),
  ];
  const [stateOptions, setStateOptions] = useState(
    NewState.map((state) => ({
      label: state,
      value: state.match(/\((.*?)\)/)[1],
    }))
  );
  const [cityOptions, setCityOptions] = useState(
    STATE.map((state) => ({
      label: state.CITY,
      value: state.CITY,
    }))
  );

  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(false);
  const [openState, handleOpenState, handleCloseState] = useMenuState(false);
  const [openComission, handleOpenComission, handleCloseComission] =
    useMenuState(false);

  const handleAddBussinessPartnerAPICredentials = () => {
    // Set empty filed when we add new slot
    formik.setFieldValue("businessPartnerAPICredentials", [
      ...formik.values.businessPartnerAPICredentials,
      {
        businessPartnerAPIName: "",
        endpointUrl: "",
        password: "",
        username: "",
      },
    ]);
    setAddBussinessPartnerAPICredentials(addBussinessPartnerAPICredentials + 1);
  };

  // remove number slot logic
  const handleRemoveBussinessPartnerAPICredentials = (index) => {
    // if (addBussinessPartnerAPICredentials > 1) {
    formik.setFieldValue(
      "businessPartnerAPICredentials",
      formik.values.businessPartnerAPICredentials.filter((_, i) => i !== index)
    );
    setAddBussinessPartnerAPICredentials(addBussinessPartnerAPICredentials - 1);
    // }
  };

  // Get City Details for profile
  const getCityDetails = async () => {
    const response = await cityService.getCity(id);
    setData(response.data.apiresponse.data);
    const result = response.data.apiresponse.data;
    // Set Formik field valus when click on edit buttons

    setDetails({ name: result.name, id });
    setNewCommision(
      result.commision
        ? { value: "true", label: "Yes" }
        : { value: "false", label: "No" }
    );
    setNewState(result.state);

    setNewCity(result.name);
    setSelectedState(result.state);
    setCity(result.name);

    formik.setFieldValue("id", result.id);
    formik.setFieldValue("name", result.name);
    // formik.setFieldValue("state", result.state);
    formik.setFieldValue("startDate", result.startDate);
    formik.setFieldValue("endDate", result.endDate);

    if (result.businessPartnerAPICredentials.length !== 0) {
      setAddBussinessPartnerAPICredentials(
        result.businessPartnerAPICredentials.length
      );
    } else {
      setAddBussinessPartnerAPICredentials(1);
    }

    result.businessPartnerAPICredentials.forEach((cred, index) => {
      formik.setFieldValue(
        `businessPartnerAPICredentials[${index}].businessPartnerAPIName`,
        cred.businessPartnerAPIName
      );
      formik.setFieldValue(
        `businessPartnerAPICredentials[${index}].username`,
        cred.username
      );
      formik.setFieldValue(
        `businessPartnerAPICredentials[${index}].password`,
        cred.password
      );
      formik.setFieldValue(
        `businessPartnerAPICredentials[${index}].endpointUrl`,
        cred.endpointUrl
      );
      formik.setFieldValue(
        `businessPartnerAPICredentials[${index}].id`,
        cred.id
      );
    });

    formik.setFieldValue(
      "holidayList",
      result.holidayList.map((res) => res)
    );
    formik.setFieldValue("commision", result.commision);
    formik.setFieldValue("updateNote", result.updateNote);
    formik.setFieldValue("cashbackAmount", result.cashbackAmount);
    formik.setFieldValue("cashbackAmountInPr", result.cashbackAmountInPr);
    formik.setFieldValue("endpointUrl", result.endpointUrl);
    formik.setFieldValue("username", result.username);
    formik.setFieldValue("password", result.password);
    ScrollToTop();

    const holidayList = result.holidayList;
    const repeatedHolidayList = [];
    const normalHolidayList = [];

    holidayList.forEach((res) => {
      const year = moment(res.date).year();
      if (year === 5000) {
        repeatedHolidayList.push(res);
      } else {
        normalHolidayList.push(res);
      }
    });

    formik.setFieldValue("repeatedholidayList", repeatedHolidayList);
    formik.setFieldValue("holidayList", normalHolidayList);
  };

  const selectState = (selected) => {
    setSelectedState(selected.value);

    formik.setFieldValue(
      "state",
      { value: selected.value, label: selected.label },
      false
    );
    setNewState(selected.label);
    const filteredCities = STATE.filter(
      (city) => city.PROVINCE === selected.value
    );
    setCityOptions(
      filteredCities.map((state) => ({
        label: state.CITY,
        value: state.CITY,
      }))
    );
  };

  const selectCity = async (selectedOption) => {
    formik.setFieldValue("name", selectedOption.label);
    setNewCity(selectedOption.label);
    setCity(selectedOption.value);
  };

  const setCity = (cityName) => {
    const filteredState = STATE.filter((city) => city.CITY === cityName);
    const state = filteredState.map((data) => ({
      label: data.STATE + " (" + data.PROVINCE + ")",
      value: data.PROVINCE,
    }));
    formik.setFieldValue("state", state.map((res) => res.value).join(", "));
  };

  cityOptions.sort((a, b) => a.label.localeCompare(b.label));

  const loadMore = () => {
    setVisibleOptions((prev) => prev + 10);
  };

  const filteredOptions = cityOptions.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  // Select COMISSION for deopdown value
  const selectCommision = (selected) => {
    formik.setFieldValue("commision", selected.value);
    setNewCommision(selected.value);
  };

  // Format date range date value (Milli to date)
  const formatDateRange = (startDate) => {
    return `${startDate}`;
  };

  const handleDateChange = (startDate) => {
    const formattedDateRange = formatDateRange(startDate);
    const newDate = formattedDateRange.split(",");
    const newStartDate = newDate[0].trim();
    const newEndDate = newDate[1]?.trim();

    const dateFormat = "DD/MM/YYYY";
    const startDateMilliseconds = moment(newStartDate, dateFormat).valueOf();
    const endDateMilliseconds = moment(newEndDate, dateFormat).valueOf();
    formik.setFieldValue("startDate", startDateMilliseconds);
    formik.setFieldValue("endDate", endDateMilliseconds);
  };

  const handleMultipleDate = (selectedDates) => {
    const formattedDates = selectedDates?.map((date) => formatDateRange(date));
    const dateFormat = "DD/MM/YYYY";
    const dateMilliseconds = formattedDates?.map((formattedDate) =>
      moment(formattedDate, dateFormat).valueOf()
    );

    const updatedHolidayList = dateMilliseconds?.map((date) => {
      const existingHoliday = formik.values.holidayList.find(
        (item) => item.date === date
      );
      return {
        id: existingHoliday ? existingHoliday.id : 0,
        date,
        type: "CITY",
      };
    });

    formik.setFieldValue("holidayList", updatedHolidayList);
  };

  const handleRepeatedHoliday = (selectedDates) => {
    const formattedDates = selectedDates?.map((date) => formatDateRange(date));

    const dateFormat = "DD/MM/YYYY";
    const dateMilliseconds = formattedDates?.map((formattedDate) =>
      moment(formattedDate, dateFormat).year(5000).valueOf()
    );

    const updatedHolidayList = dateMilliseconds?.map((date) => {
      const existingHoliday = formik.values.repeatedholidayList.find(
        (item) => item.date === date
      );
      return {
        id: existingHoliday ? existingHoliday.id : 0,
        date,
        type: "CITY",
      };
    });

    formik.setFieldValue("repeatedholidayList", updatedHolidayList);
  };

  // Update Status for city name as per their state code
  // const updatedCities = City.getCitiesOfState("IT", selectedState).map(
  //   (state) => ({
  //     value: state.name,
  //     label: state.name,
  //   })
  // );

  // const NewState = [
  //   ...new Set(STATE.map((data) => data.STATE + " (" + data.PROVINCE + ")")),
  // ];
  // const stateOptions = NewState.map((state) => ({
  //   label: state,
  //   value: state.match(/\((.*?)\)/)[1],
  // }));
  // stateOptions.sort((a, b) => a.label.localeCompare(b.label));

  // const filteredCities = STATE.filter(
  //   (city) => city.PROVINCE === selectedState
  // );
  // const cityOptions = filteredCities.map((state) => ({
  //   label: state.CITY,
  //   value: state.CITY,
  // }));
  // cityOptions.sort((a, b) => a.label.localeCompare(b.label));

  const deleteCity = async (id) => {
    try {
      await cityService.deleteCity(id);
      enqueueSnackbar(t("SUCCESSFULLY_CITY_DELETED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      navigate("/city/");
      setModalShow(false);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  // Update Status for state name as per their country code (107 - Italy)
  // const updatedStates = () => {};
  // csc.getStatesOfCountry("107").map((state) => ({
  //   label: state.name,
  //   value: state.id,
  //   ...state,
  // }));

  // csc.getCitiesOfState(stateId).map((city) => ({
  //   label: city.name,
  //   value: city.id,
  //   ...city,
  // }));

  const AddModal = () => {
    setUpdate(true);
    getCityDetails();
    handleDialogOpen();
  };

  const holidaySchema = yup.object().shape({
    date: yup.string().required("Please Enter Holidays!"),
    type: yup.string().required("Please Enter Type!"),
  });
  const repeatedHolidaySchema = yup.object().shape({
    date: yup.string().required("Please Enter Repeated Holidays!"),
    type: yup.string().required("Please Enter Type!"),
  });

  const businessPartnerAPINameSchema = yup.object().shape({
    // username: yup.string().required("User name is required"),
    // password: yup.string().required("Password is required"),
    businessPartnerAPIName: yup
      .string()
      .test(
        "required-businessPartnerAPIName",
        "Business Partner API Name is required",
        function (value) {
          const { username, password } = this.parent;
          if (
            (username && username.length > 0) ||
            (password && password.length > 0)
          ) {
            return !!value;
          }
          return true;
        }
      ),
    username: yup
      .string()
      .test("required-username", "User name is required", function (value) {
        const { businessPartnerAPIName, password } = this.parent;
        if (
          (businessPartnerAPIName && businessPartnerAPIName.length > 0) ||
          (password && password.length > 0)
        ) {
          return !!value;
        }
        return true;
      }),
    password: yup
      .string()
      .test("required-password", "Password is required", function (value) {
        const { businessPartnerAPIName, username } = this.parent;
        if (
          (businessPartnerAPIName && businessPartnerAPIName.length > 0) ||
          (username && username.length > 0)
        ) {
          return !!value;
        }
        return true;
      }),
  });

  // inital value of form
  const initialValue = {
    name: "",
    state: "",
    commision: null,
    endDate: "",
    holidayList: [
      {
        date: "",
        type: "CITY",
      },
    ],
    repeatedholidayList: [
      {
        date: "",
        type: "CITY",
      },
    ],
    startDate: "",
    updateNote: "",
    cashbackAmount: 0,
    cashbackAmountInPr: 0,
    businessPartnerAPICredentials: [
      {
        businessPartnerAPIName: "",
        endpointUrl: "",
        password: "",
        username: "",
      },
    ],
  };

  // Validation schema for form
  const validationSchema = yup.object().shape({
    name: yup.mixed().required("Please enter city name"),
    state: yup.mixed().required("Please enter State!"),
    commision: yup.mixed().required("Please Enter Commision!"),
    endDate: yup.string().required("Please enter EndDate!"),
    startDate: yup.string().required("Please enter Date!"),
    businessPartnerAPICredentials: yup.array().of(businessPartnerAPINameSchema),
    // holidayList: yup.array().of(holidaySchema),
    // repeatedholidayList: yup.array().of(repeatedHolidaySchema),
    // updateNote: yup.string().required("Please enter Update Note!"),
  });

  // Submit form for Formik
  const handleFormSubmit = async (values, action) => {
    const {
      id,
      commision,
      endDate,
      holidayList,
      name,
      startDate,
      state,
      updateNote,
      repeatedholidayList,
      cashbackAmount,
      cashbackAmountInPr,
      endpointUrl,
      username,
      password,
      businessPartnerAPICredentials,
    } = values;

    let mergedList = [];
    if (repeatedholidayList !== undefined && holidayList) {
      mergedList = holidayList.concat(repeatedholidayList);
    } else if (!holidayList && repeatedholidayList === undefined) {
      mergedList = [];
    } else if (holidayList && repeatedholidayList === undefined) {
      mergedList = holidayList;
    } else if (!holidayList && repeatedholidayList !== undefined) {
      mergedList = repeatedholidayList;
    }

    let states;
    if (typeof state === "object") {
      states = state.value;
    } else {
      states = state;
    }

    let businessPartnerCred = [];

    if (businessPartnerAPICredentials.length === 1) {
      if (
        businessPartnerAPICredentials[0].businessPartnerAPIName ||
        businessPartnerAPICredentials[0].username ||
        businessPartnerAPICredentials[0].password
      ) {
        businessPartnerCred = businessPartnerAPICredentials;
      }
    } else {
      businessPartnerCred = businessPartnerAPICredentials.filter(
        (cred) => cred.businessPartnerAPIName || cred.username || cred.password
      );
    }

    try {
      const response = await cityService.updateCity(id, {
        commision,
        endDate,
        holidayList: mergedList,
        name,
        startDate,
        state: states,
        updateNote,
        cashbackAmount,
        cashbackAmountInPr,
        // endpointUrl,
        // username,
        // password,
        businessPartnerAPICredentials: businessPartnerCred,
      });

      // getAllCity();
      getCityDetails();
      enqueueSnackbar(t("SUCCESSFULLY_CITY_UPDATED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });

      // action.resetForm();
      // setNewState("");
      // setNewCommision("");
      // handleDialogClose();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const changeCashback = (e) => {
    const value = e.target.value;
    if (value === "$") {
      setCashbackInAmount(true);
    } else {
      setCashbackInAmount(false);
    }
  };

  useEffect(() => {
    getCityDetails();
  }, []);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">{t("cityprofile")}</h2>

        {isDialogOpen && (
          <AddCity
            show={isDialogOpen}
            onHide={handleDialogClose}
            formik={formik}
            update={update}
            handleDateChange={handleDateChange}
            handleMultipleDate={handleMultipleDate}
            newState={newState}
            newCommision={newCommision}
            selectState={selectState}
            selectCommision={selectCommision}
            selectCity={selectCity}
            newCity={newCity}
            // updatedCities={updatedCities}
            // updatedStates={updatedStates}
            openState={openState}
            handleCloseState={handleCloseState}
            handleOpenState={handleOpenState}
            handleCloseCity={handleCloseCity}
            handleOpenCity={handleOpenCity}
            openCity={openCity}
            handleCloseComission={handleCloseComission}
            handleOpenComission={handleOpenComission}
            openComission={openComission}
            handleRepeatedHoliday={handleRepeatedHoliday}
            cityOptions={cityOptions}
            filteredOptions={filteredOptions}
            setSearch={setSearch}
            loadMore={loadMore}
            visibleOptions={visibleOptions}
            stateOptions={stateOptions}
            changeCashback={changeCashback}
            cashbackInAmount={cashbackInAmount}
            handleTogglePassword={handleTogglePassword}
            showPassword={showPassword}
            handleRemoveBussinessPartnerAPICredentials={
              handleRemoveBussinessPartnerAPICredentials
            }
            handleAddBussinessPartnerAPICredentials={
              handleAddBussinessPartnerAPICredentials
            }
            addBussinessPartnerAPICredentials={
              addBussinessPartnerAPICredentials
            }
            BUSINESS_PARTNER_API_NAME={BUSINESS_PARTNER_API_NAME}
            openBusinessPartnerAPIName={openBusinessPartnerAPIName}
            handleOpenBusinessPartnerAPIName={handleOpenBusinessPartnerAPIName}
            handleCloseBusinessPartnerAPIName={
              handleCloseBusinessPartnerAPIName
            }
          />
        )}

        {modalShow && (
          <DeleteCity
            show={modalShow}
            onHide={() => setModalShow(false)}
            details={details}
            deleteCity={deleteCity}
          />
        )}

        <Row className="row row-sm">
          <Col sm={12} lg={12} xl={12}>
            <Card className="custom-card">
              <Card.Body>
                <div className="border-bottom mb-3">
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <div className="ms-auto d-flex justify-content-end">
                        <button
                          className="btn btn-sm btn-primary ms-2"
                          type="button"
                          onClick={AddModal}
                        >
                          <i className="fe fe-edit"></i> {t("edit")}
                        </button>

                        <button
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => setModalShow(true)}
                        >
                          <i className="fe fe-trash"></i> {t("delete")}
                        </button>
                      </div>
                    </Row>
                  </FormGroup>
                </div>
                <div>
                  <div className="row">
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("name")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={data.name} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("province")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={data.state} disabled />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("startdate")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={moment(data.startDate).format("DD/MM/YYYY")}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("enddate")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={moment(data.endDate).format("DD/MM/YYYY")}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("holiday")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={data.holidayList
                              ?.map((date) =>
                                moment(date.date).format("DD/MM/YYYY")
                              )
                              .join(", ")}
                            disabled
                            as="textarea"
                            rows={2} // Adjust the number of rows as needed
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("commission")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={data.commision ? "Yes" : "No"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("updatenote")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={data.updateNote ? data.updateNote : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("cashbackamount")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              data.cashbackAmount
                                ? `€${ReplaceFunction(
                                    data.cashbackAmount?.toFixed(2)
                                  )}`
                                : data.cashbackAmount
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("cashbacamountinpr")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={data.cashbackAmountInPr + "%"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    {data?.businessPartnerAPICredentials?.length >= 1 && (
                      <hr className="mt-4" />
                    )}
                    <>
                      {data.businessPartnerAPICredentials &&
                        data.businessPartnerAPICredentials.map(
                          (cred, index) => (
                            <>
                              <div key={index}>
                                <h5 className="text-dark mb-4 mt-2">
                                  {t("bussinesspartnerapicredentials")}{" "}
                                  {index + 1} :
                                </h5>

                                <FormGroup className="form-group">
                                  <Row className=" row-sm">
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label className="text-dark">
                                        {t("businesspartnerapiname")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          cred?.businessPartnerAPIName
                                            ? cred?.businessPartnerAPIName
                                            : "--"
                                        }
                                        disabled
                                      />
                                    </Col>
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label className="text-dark">
                                        {t("username")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          cred?.username ? cred?.username : "--"
                                        }
                                        // onChange={formik.handleChange}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup className="form-group">
                                  <Row className=" row-sm">
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label className="text-dark">
                                        {t("password")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <div
                                        className="d-flex"
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                        }}
                                      >
                                        <Form.Control
                                          name="password"
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          value={cred.password}
                                          disabled
                                        />
                                        <i
                                          style={{
                                            position: "absolute",
                                            top: "38%",
                                            right: "4%",
                                          }}
                                          onClick={handleTogglePassword}
                                          className={`fa ${
                                            showPassword
                                              ? "fa-eye-slash"
                                              : "fa-eye"
                                          } text-dark`}
                                        ></i>
                                        {/* </button> */}
                                      </div>
                                    </Col>
                                    <Col
                                      md={2}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Label className="text-dark">
                                        {t("endpointurl")} :
                                      </Form.Label>
                                    </Col>
                                    <Col md={4}>
                                      <Form.Control
                                        value={
                                          cred?.endpointUrl
                                            ? cred?.endpointUrl
                                            : "--"
                                        }
                                        // onChange={formik.handleChange}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>

                                {data.businessPartnerAPICredentials.length -
                                  1 !==
                                  index && <hr className="mt-3" />}
                              </div>
                            </>
                          )
                        )}
                    </>

                    {/* <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("username")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={data.username} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("password")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <div
                            className="d-flex"
                            style={{
                              position: "relative",
                              display: "flex",
                            }}
                          >
                            <Form.Control
                              name="password"
                              type={showPassword ? "text" : "password"}
                              value={data.password}
                              disabled
                            />
                            <i
                              style={{
                                position: "absolute",
                                top: "38%",
                                right: "4%",
                              }}
                              onClick={handleTogglePassword}
                              className={`fa ${
                                showPassword ? "fa-eye-slash" : "fa-eye"
                              } text-dark`}
                            ></i>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup> */}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default withLoader(CityProfile);
