import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const ParkingSlotService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getParking = (pageNumber, pageSize, searchText) => {
    const response = serverCall.customAxios.get(
      `/parking/all?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`,
      { headers }
    );
    return response;
  };

  const getParkingById = (id) => {
    const response = serverCall.customAxios.get(`/parking/${id}`, { headers });
    return response;
  };

  const addParking = (body) => {
    const response = serverCall.customAxios.post(`/parking/`, body, {
      headers,
    });
    return response;
  };

  const updateParking = (id, body) => {
    const response = serverCall.customAxios.put(`/parking/${id}`, body, {
      headers,
    });
    return response;
  };

  const removeParkingSlot = (id) => {
    const response = serverCall.customAxios.delete(`/parking/slot/${id}`, {
      headers,
    });
    return response;
  };
  const removeParking = (id) => {
    const response = serverCall.customAxios.delete(`/parking/${id}`, {
      headers,
    });
    return response;
  };

  return {
    getParking,
    addParking,
    getParkingById,
    updateParking,
    removeParkingSlot,
    removeParking,
  };
};

export default ParkingSlotService;
