import React from "react";
import "../../../assets/css/transaction.css";
import moment from "moment";
import { ReplaceFunction } from "../../utils/constants";

const TransactionSlip = ({ rowData, path }) => {
  return (
    <div class="payment-slip" id="payment-slip">
      <div class="header">
        <h2>Ricarica NeosApp</h2>
      </div>
      <div class="transaction-id">
        ID della Codice NeosApp:{" "}
        {path === "manual" ? rowData?.uniqueId : rowData?.uuniqueId}
      </div>
      <div class="details">
        <p>
          <strong>Nome utente mittente:</strong>{" "}
          {path === "manual" ? rowData?.username : rowData?.uusername}
        </p>
        <p>
          <strong>Codice rivenditore del destinatario:</strong>{" "}
          {rowData?.duniqueId}
        </p>
        <p>
          <strong>Nome rivenditore destinatario:</strong> {rowData?.dusername}
        </p>
        <p>
          <strong>Importo della ricarica:</strong> €
          {rowData?.amount
            ? ReplaceFunction(rowData?.amount?.toFixed(2))
            : rowData?.amount}
        </p>
        <p>
          <strong>Data e ora:</strong>{" "}
          {moment(rowData?.creationDate).format("DD/MM/YYYY HH:mm:ss")}
        </p>
        <p>
          <strong>Tipo:</strong>{" "}
          {path === "manual"
            ? "Manuale  "
            : rowData?.type === "RECHARGE"
            ? "Ricarica"
            : rowData?.type}
        </p>
        <p>
          <strong>Stato:</strong>{" "}
          {path === "manual"
            ? "Eseguita"
            : rowData?.status === "COMPLETED"
            ? "Eseguita"
            : rowData?.status === "CANCEL"
            ? "Annullata"
            : rowData?.status === "DISPUTE"
            ? "Contestata"
            : "-"}
        </p>
      </div>
      <div class="total-amount">
        <p>
          Importo totale: €
          {rowData?.amount
            ? ReplaceFunction(rowData?.amount?.toFixed(2))
            : rowData?.amount}
        </p>
      </div>
    </div>
  );
};

export default TransactionSlip;
