import React, { useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "react-select";
import moment from "moment";
import withLoader from "../../layout/Loader/WithLoader";
import { Approve } from "./ApproveRequest";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  ANCHORORIGIN,
  CUSTOMDROPDOWNSTYLE,
  EMPTY,
  PAGELIMIT,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import { Select } from "antd";
import useDialogState from "../../hooks/useDialog";
import RejectRequest from "./RejectRequest";
import UserService from "../../services/user.service";
import { useEffect } from "react";
import PaymentService from "../../services/payment.service";
import { useTranslation } from "react-i18next";
import Enums from "../../utils/enums";
import TransactionService from "../../services/transaction.service";

const DealerRequests = () => {
  const { state } = useLocation();
  const { STATUS, RANGES } = Enums();
  const searchingName = state?.dealername;

  const transactionService = TransactionService();
  const userService = UserService();
  const paymentService = PaymentService();
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [dealerName, setDealerName] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const getRefills = async () => {
    const response = await transactionService.getDealerRefills({
      endDate: new Date(endDate).getTime(),
      needDealerList: true,
      pageLimit: dataPerPage,
      pageNumber: pageNumber,
      searchString: searchString,
      startDate: new Date(startDate).getTime(),
      status: selectedStatus,
      userId: 0,
    });
    setData(response.data.apiresponse.data.dealerRefills);
    setTotalRows(response.data.apiresponse.data.totalRecords);
  };

  useEffect(() => {
    getRefills();
  }, []);

  useEffect(() => {
    getRefills();
  }, [
    pageNumber,
    startDate,
    endDate,
    dataPerPage,
    searchString,
    selectedStatus,
  ]);

  const handleStatusChange = (
    paymentId,
    newStatus,
    currentRechargeLimit,
    percantageOfEarning,
    grossAmount
  ) => {
    if (newStatus === "APPROVED") {
      handleDialogOpen();
      formik.setFieldValue("requestId", paymentId);
      formik.setFieldValue("status", newStatus);
      formik.setFieldValue("currentRechargeLimit", currentRechargeLimit);
      formik.setFieldValue("rechargeRequest", grossAmount);
      formik.setFieldValue("percantageOfEarning", percantageOfEarning);
      const mult = grossAmount * percantageOfEarning;
      const percent = mult / 100;
      formik.setFieldValue("afterrechargelimit", percent?.toFixed(2));

      const addition = percent + grossAmount;
      formik.setFieldValue("additionalAmount", addition?.toFixed(2));
    } else {
      setShowCancelModal(true);
    }
  };

  const handlesearch = (e) => {
    setSearchString(e.target.value);
    // handleSearchDebounced(e.target.value);
  };

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: "uniqId",
      cell: (row) => <div title={`${row.uniqId}`}>{row.uniqId}</div>,
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("name")}</strong>,
      selector: "username",
      cell: (row) => <div title={`${row.username}`}>{row.username}</div>,
      sortable: true,
    },
    // {
    //   name: <strong className="tx-uppercase">{t("grossamount")}</strong>,
    //   selector: "grossAmount",
    //   cell: (row) => <div title={`${row.grossAmount}`}>{row.grossAmount}</div>,
    //   sortable: true,
    // },
    // {
    //   name: <strong className="tx-uppercase">{t("netamount")}</strong>,
    //   selector: "netAmount",
    //   cell: (row) => (
    //     <div title={`${row.netAmount ? row.netAmount : "--"}`}>
    //       {row.netAmount ? row.netAmount : "--"}
    //     </div>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: <strong className="tx-uppercase">{t("paypalfee")}</strong>,
    //   selector: "paypalFee",
    //   cell: (row) => (
    //     <div title={`${row.paypalFee ? row.paypalFee : "--"}`}>
    //       {row.paypalFee ? row.paypalFee : "--"}
    //     </div>
    //   ),
    //   sortable: true,
    // },
    {
      name: (
        <strong className="tx-uppercase">{t("importbeforerecharge")}</strong>
      ),
      selector: "beforeWalletAmount",
      cell: (row) => (
        <div
          title={`${
            row.beforeWalletAmount
              ? `€${ReplaceFunction(row.beforeWalletAmount)}`
              : "--"
          }`}
        >
          {row.beforeWalletAmount
            ? `€${ReplaceFunction(row.beforeWalletAmount)}`
            : "--"}
        </div>
      ),
      sortable: true,
    },
    // amountOfEarning
    {
      name: <strong className="tx-uppercase">{t("importrecharge")}</strong>,
      selector: "grossAmount",
      cell: (row) => (
        <div
          title={`${
            row.grossAmount
              ? `${
                  row.approvalStatus === "APPROVED"
                    ? `€${ReplaceFunction(
                        row.grossAmount - row.amountOfEarning
                      )}`
                    : `€${ReplaceFunction(row.grossAmount)}`
                }`
              : "--"
          }`}
        >
          {row.grossAmount
            ? `${
                row.approvalStatus === "APPROVED"
                  ? `€${ReplaceFunction(row.grossAmount - row.amountOfEarning)}`
                  : `€${ReplaceFunction(row.grossAmount)}`
              }`
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("importafterrecharge")}</strong>
      ),
      selector: "afterWalletAmount",
      cell: (row) => (
        <div
          title={`${
            row.afterWalletAmount
              ? `€${ReplaceFunction(row.afterWalletAmount)}`
              : "--"
          }`}
        >
          {row.afterWalletAmount
            ? `€${ReplaceFunction(row.afterWalletAmount)}`
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("paydate")}</strong>,
      selector: "payDate",
      cell: (row) => (
        <div
          title={`${
            row.payDate
              ? moment(row.payDate).format("DD/MM/YYYY HH:mm:ss")
              : moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
          }`}
        >
          {row.payDate
            ? moment(row.payDate).format("DD/MM/YYYY HH:mm:ss")
            : moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("transactionstatus")}</strong>,
      selector: "transactionStatus",
      cell: (row) => (
        <div className="d-flex">
          <div className="">
            {row.transactionStatus === "IN_PROGRESS" && (
              <div className="text-warning">
                <i class="fa fa-hourglass"></i> {t("inprogress")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "COMPLETED" && (
              <div className="text-success">
                <i className="fa fa-check"></i> {t("completed")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "REFUND" && (
              <div className="text-info">
                <i class="fa fa-rotate-left"></i> {t("refunded")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "CANCEL" && (
              <div className="text-danger">
                <i class="fa fa-close"></i> {t("cancel")}
              </div>
            )}
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("approvalstatus")}</strong>,
      selector: "approvalStatus",
      cell: (row) => (
        <div className="d-flex">
          <div className="">
            {row.approvalStatus === "PENDING" && (
              <div className="select-pending">
                <Select
                  value={"PENDING"}
                  onChange={(newStatus) =>
                    handleStatusChange(
                      row.paymentId,
                      newStatus,
                      row.currentRechargeLimit,
                      row.percantageOfEarning,
                      row.grossAmount
                    )
                  }
                  style={{ width: "110px" }}
                >
                  <option
                    value="APPROVED"
                    className="text-white"
                    style={{ backgroundColor: "#15A552" }}
                  >
                    <i className="fa fa-check"></i> {t("approved")}
                  </option>
                  <option
                    value="PENDING"
                    className="d-none"
                    style={{ backgroundColor: "#F1911D" }}
                  >
                    <div>
                      <i className="fa fa-clock"></i> {t("pending")}
                    </div>
                  </option>
                </Select>
              </div>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "APPROVED" && (
              <Link
                className="btn btn-success btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i className="fa fa-check"></i> {t("approved")}
              </Link>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "INITIATE" && (
              <Link
                className="btn btn-info btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i className="fas fa-stopwatch"></i> {t("initiated")}
              </Link>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "CANCELLED" && (
              <Link
                className="btn btn-danger btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i className="si si-close"></i> {t("canceled")}
              </Link>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "EXPIRED" && (
              <Link
                className="btn btn-sm"
                style={{
                  backgroundColor: "#fb3f3f",
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                  color: "#ffffff",
                }}
              >
                <i className="fa fa-hourglass"></i> {t("expired")}
              </Link>
            )}
          </div>
          <div className="">{row.approvalStatus === null && <div>--</div>}</div>
        </div>
      ),
      sortable: true,
      minWidth: "130px",
    },
  ];

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const formik = useFormik({
    initialValues: {
      currentRechargeLimit: "",
      rechargeLimit: "",
      requestId: "",
      status: "",
      rechargeRequest: "",
      percantageOfEarning: "",
    },

    onSubmit: async (values, action) => {
      const { rechargeLimit, requestId, status } = values;
      const response = await transactionService.changeStatusOfDealerRequest(
        status,
        "",
        rechargeLimit,
        requestId
      );
      getRefills();

      enqueueSnackbar(t("SUCCESSFULLY_DEALER_REQUEST_APPROVED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      closeDialog();
    },
  });

  const closeDialog = () => {
    formik.resetForm();
    handleDialogClose();
  };

  useEffect(() => {
    setSearchString(searchingName);
  }, [searchingName]);

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("dealerreaquest")}
      </h2>

      {isDialogOpen && (
        <Approve show={isDialogOpen} onHide={closeDialog} formik={formik} />
      )}

      {showCancelModal && (
        <RejectRequest
          show={showCancelModal}
          onHide={() => setShowCancelModal(false)}
        />
      )}

      <Card>
        <div className="p-3">
          <Row>
            {/* <Col md={2}>
              <Form.Group className="form-group">
                <Multiselect
                  classNamePrefix="Select2"
                  options={delaerName}
                  singleSelect
                  placeholder={t("dealername")}
                  displayValue="key"
                  name="dealername"
                  // onChange={(selectedOption) =>
                  //   setSelectedDealer(selectedOption.value)
                  // }
                />
              </Form.Group>
            </Col> */}
            <Col md={2}>
              <Form.Group className="form-group">
                <Multiselect
                  classNamePrefix="Select2"
                  options={STATUS}
                  singleSelect
                  placeholder={t("status")}
                  displayValue="key"
                  name="status"
                  onChange={(selectedOption) =>
                    setSelectedStatus(selectedOption.value)
                  }
                  styles={CUSTOMDROPDOWNSTYLE}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="form-group">
                {/* <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date())
                      .subtract(1, "months")
                      .format("MM/DD/YYYY"),
                    endDate: moment(new Date()).format("MM/DD/YYYY"),
                    ranges: RANGES,
                  }}
                  onApply={setDates}
                >
                  <input
                    type="text"
                    value={
                      moment(startDate).format("DD/MM/YYYY") +
                      " - " +
                      moment(endDate).format("DD/MM/YYYY")
                    }
                    className="form-control"
                  />
                </DateRangePicker> */}
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date()).format("DD/MM/YYYY"),
                    endDate: moment(new Date()).format("DD/MM/YYYY"),
                    ranges: RANGES,
                    autoUpdateInput: false,
                    locale: {
                      format: "DD/MM/YYYY", // Specify the desired format
                    },
                  }}
                  onApply={setDates}
                  onCancel={cancelDate}
                  onHide={cancelDate}
                >
                  {startDate && endDate ? (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      value={
                        startDate && endDate
                          ? `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} - ${moment(endDate).format("DD/MM/YYYY")}`
                          : ""
                      }
                      defaultValue=""
                      onChange={(startDate, endDate) =>
                        handleDateChange(startDate, endDate)
                      }
                      className="form-control"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      defaultValue=""
                      className="form-control"
                    />
                  )}
                </DateRangePicker>
              </Form.Group>
            </Col>
            <Col>
              <div className="d-flex d-lg-flex add-search ms-auto">
                <div className="d-flex flex-grow-1 flex-md-grow-0 flex-lg-grow-0 align-items-center md-form mt-2 mt-md-auto mt-lg-auto ms-auto">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-md py-3"
                      value={searchString}
                      onChange={handlesearch}
                      placeholder={t("search") + "..."}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <DataTable
            title="Dealer Request"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(DealerRequests);
