import React from "react";
import { styled } from "@mui/styles";
import { Box, CircularProgress } from "@mui/material";

const StyledLoading = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  zIndex: 9999,
}));

const Loader = () => (
  <StyledLoading>
    <Box position="relative">
      <CircularProgress style={{ color: "#fff" }} />
    </Box>
  </StyledLoading>
);
Loader.propTypes = {};
Loader.defaultProps = {};
export default Loader;
