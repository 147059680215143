import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Multiselect from "react-select";
import { Link, useNavigate } from "react-router-dom";
import withLoader from "../../layout/Loader/WithLoader";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import "jspdf-autotable";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import useDialogState from "../../hooks/useDialog";
import html2pdf from "html2pdf.js";
import TransactionSlip from "../Users/TransactionSlip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import {
  ANCHORORIGIN,
  CUSTOMDROPDOWNSTYLE,
  ERROR,
  FORMIKERROR,
  PAGELIMIT,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { AddDispute } from "./FreeDealerDisputeRequests";
import PaymentService from "../../services/payment.service";
import Enums from "../../utils/enums";
import TransactionService from "../../services/transaction.service";
import UserService from "../../services/user.service";

const FreeDealerUserRequest = () => {
  const { STATUS, RANGES } = Enums();
  const paymentService = PaymentService();
  const transactionService = TransactionService();
  const userService = UserService();
  const { t } = useTranslation("common");
  const tableRef = useRef(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState("");
  const [userData, setUserData] = useState("");
  const [searchText, setSearchText] = useState("");
  const [id, setId] = useState("");
  const [approveDetails, setApproveDetails] = useState({});
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPendingCancelModal, setShowPendingCancelModal] = useState(false);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();
  const [dates, setDatesState] = useState({
    startDate: moment(new Date()).format("DD/MM/YYYY"),
    endDate: moment(new Date()).format("DD/MM/YYYY"),
  });

  const localStorageData = localStorage.getItem("neos-storage");
  const neosStorage = JSON.parse(localStorageData);
  const delaerId = neosStorage && neosStorage.id;

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const getDealerName = async () => {
    const response = await userService.getUserById(delaerId);
    setUserData(response.data.apiresponse.data);
  };

  const getDealerToUserRefills = async () => {
    try {
      const response = await transactionService.getDealerToUserRefills({
        endDate: new Date(endDate).getTime(),
        needDealerList: false,
        pageLimit: dataPerPage,
        pageNumber: pageNumber,
        searchString: searchText,
        startDate: new Date(startDate).getTime(),
        status: selectedStatus,
        userId: delaerId,
      });
      setData(response.data.apiresponse.data.recharges);
      setTotalRows(response.data.apiresponse.data.totalRecords);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  useEffect(() => {
    getDealerToUserRefills();
    getDealerName();
  }, []);

  useEffect(() => {
    getDealerToUserRefills();
  }, [pageNumber, startDate, endDate, dataPerPage, searchText, selectedStatus]);

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handleOpen = () => {
    handleDialogOpen();
  };

  const handleDownload = (row) => {
    const receiptContainer = document.createElement("div");
    receiptContainer.classList.add("receipt-container");

    const transactionSlip = <TransactionSlip rowData={row} />;
    const paymentSlip = document.createElement("div");
    ReactDOM.render(transactionSlip, paymentSlip);
    receiptContainer.appendChild(paymentSlip);

    document.body.appendChild(receiptContainer);

    const options = {
      margin: -5,
      filename: `${row?.uusername}_Payment_Slip.pdf`,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [525, 538],
        orientation: "portrait",
      },
    };

    html2pdf().from(receiptContainer).set(options).save();

    document.body.removeChild(receiptContainer);
  };

  let totalCost = 0;
  for (let i = 0; i < data?.length; i++) {
    if (data[i].status === "COMPLETED") {
      totalCost += parseFloat(data[i]?.amount);
    }
  }

  const handleDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["Ricariche NeosApp"]);
    worksheet.mergeCells("A1:E1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    titleRow.getCell(1).fill = commonCellStyle.fill;
    titleRow.getCell(1).border = commonCellStyle.border;
    titleRow.getCell(1).font = commonCellStyle.font;
    worksheet.addRow([]);
    worksheet.mergeCells("A2:E2");

    const catania = worksheet.addRow([userData.town]);
    worksheet.mergeCells("A3:E3");
    catania.getCell(1).fill = commonCellStyle.fill;
    catania.getCell(1).border = commonCellStyle.border;
    catania.getCell(1).font = commonCellStyle.font;

    const punto = worksheet.addRow([
      `Punto ricarica:   ${userData.town} - ${userData.address}`,
    ]);
    worksheet.mergeCells("A4:E4");
    punto.getCell(1).fill = commonCellStyle.fill;
    punto.getCell(1).border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "none" },
      right: { style: "thin", color: { argb: "000000" } },
    };
    punto.getCell(1).font = commonCellStyle.font;

    const salvatore = worksheet.addRow([
      `                                ${userData.username} ${userData.surname}`,
    ]);
    worksheet.mergeCells("A5:E5");
    salvatore.getCell(1).fill = commonCellStyle.fill;
    salvatore.getCell(1).border = {
      top: { style: "none" },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    salvatore.getCell(1).font = commonCellStyle.font;

    const period = worksheet.addRow([
      `Periodo dal               ${
        startDate !== 0 ? moment(startDate).format("DD/MM/YYYY") : "Toda"
      } ${endDate !== 0 ? "al " + moment(endDate).format("DD/MM/YYYY") : ""}`,
    ]);
    worksheet.mergeCells("A6:E6");
    period.getCell(1).fill = commonCellStyle.fill;
    period.getCell(1).border = commonCellStyle.border;
    period.getCell(1).font = commonCellStyle.font;

    worksheet.addRow([]);
    worksheet.mergeCells("A7:E7");

    // Add column headers
    const headers = ["Unique ID", "Card", "Data", "Descrizione", "Ricariche"];
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 22;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 10;
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.fill = commonCellStyle.fill;
      cell.font = commonCellStyle.font; // Text color
      cell.border = commonCellStyle.border;
    });

    // Add data rows
    let newdata = [];
    for (let i = 0; i < data?.length; i++) {
      if (data[i].status === "COMPLETED") {
        newdata.push(data[i]);
      }
    }

    newdata.forEach((res) => {
      const table = worksheet.addRow([
        res.transactionId,
        res.uuniqueId,
        moment(res.creationDate).format("DD/MM/YYYY HH:mm:ss"),
        res.reasonForCancel ? res.reasonForCancel : "--",
        `€${res.amount ? ReplaceFunction(res.amount?.toFixed(2)) : res.amount}`,
      ]);
      table.eachCell((cell) => {
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
      });
    });

    const totalRicaricheRow = worksheet.addRow([
      "",
      "",
      "",
      "Total ricariche:",
      `€${totalCost ? ReplaceFunction(totalCost?.toFixed(2)) : totalCost}`,
    ]);
    totalRicaricheRow.getCell(4).fill = commonCellStyle.fill;
    totalRicaricheRow.getCell(4).border = commonCellStyle.border;
    totalRicaricheRow.getCell(5).border = commonCellStyle.border;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${userData.username}_User_Recharges.xlsx`;
    link.click();
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const pageWidth = 595.28;
    const titleFontSize = 18;
    const width = 515;
    const textColor = [0, 0, 0];
    const fillColor = [200, 200, 200];

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(titleFontSize);

    const title = "Ricariche NeosApp";
    const titleWidth = doc.getStringUnitWidth(title) * titleFontSize;
    const titleX = (pageWidth - titleWidth) / 2;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 16, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "bold");
    doc.text(title, titleX, 35);

    const catania = userData.town;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 50, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.text(catania, 45, 70);

    const punto = "Punto ricarica: ";
    const name = `${userData.town} - ${userData.address}`;
    const salvatore = `${userData.username} ${userData.surname}`;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 80, width, 54, "FD");
    doc.setTextColor(...textColor);
    doc.text(punto, 45, 100);
    doc.text(name, 200, 100);
    doc.text(salvatore, 200, 125);

    const period = "Periodo dal";
    const startTime =
      startDate !== 0 ? moment(startDate).format("DD/MM/YYYY") : "Toda";
    const endTime =
      endDate !== 0 ? `al  ${moment(endDate).format("DD/MM/YYYY")}` : "";
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 134, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.text(period, 45, 152);
    doc.text(startTime, 220, 152);
    doc.text(endTime, 330, 152);

    const headers = [["Unique ID", "Card", "Data", "Descrizione", "Ricariche"]];
    // set data table columns names
    let newdata = [];
    for (let i = 0; i < data?.length; i++) {
      if (data[i].status === "COMPLETED") {
        newdata.push(data[i]);
      }
    }
    const newData = newdata.map((elt) => [
      elt.transactionId,
      elt.uuniqueId,
      moment(elt.creationDate).format("DD/MM/YYYY HH:mm:ss"),
      elt.reasonForCancel ? elt.reasonForCancel : "--",
      `€${elt.amount ? ReplaceFunction(elt.amount?.toFixed(2)) : elt.amount}`,
    ]);

    newData.push([
      "",
      "",
      {
        content: "Total ricariche:",
        colSpan: 2,
        styles: {
          halign: "right",
          fontStyle: "bold",
          fillColor: [...fillColor],
          textColor: [...textColor],
          lineWidth: 0.5,
          lineColor: [...textColor],
        },
      },
      {
        content: `€${
          totalCost ? ReplaceFunction(totalCost?.toFixed(2)) : totalCost
        }`,
        // content: "€ " + totalCost,
        colSpan: 1,
        styles: {
          halign: "right",
          textColor: [...textColor],
          lineWidth: 0.5,
          lineColor: [...textColor],
        },
      },
    ]);

    const headStyles = {
      fillColor: [...fillColor],
      textColor: [...textColor],
      lineWidth: 0.5,
      lineColor: [...textColor],
      halign: "center",
      fontSize: 13,
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 13,
    };

    const columnStyles = {
      4: { halign: "right" },
    };

    const alternateRowStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 180,
        head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    doc.save(`${userData.username}_User_Recharges.pdf`);
  };

  const handleStatusChange = (transactionId, newStatus, username, amount) => {
    setApproveDetails({ username, amount, transactionId });
    if (newStatus === "APPROVED") {
      handleDialogOpen();
    } else {
      setShowPendingCancelModal(true);
    }
  };
  const handleApproveChange = (rowId, newStatus) => {
    setId(rowId);
    setShowCancelModal(true);
  };

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: "uuniqueId",
      cell: (row) => (
        <div title={`${row.uuniqueId ? row.uuniqueId : "--"}`}>
          {row.uuniqueId ? row.uuniqueId : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: "uusername",
      cell: (row) => (
        <div title={`${row.uusername ? row.uusername : "--"}`}>
          {row.uusername ? row.uusername : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("email")}</strong>,
      selector: "uemail",
      cell: (row) => (
        <div title={`${row.uemail ? row.uemail : "--"}`}>
          {row.uemail ? row.uemail : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("reason")}</strong>,
      selector: "reasonForCancel",
      cell: (row) => (
        <div title={`${row.reasonForCancel ? row.reasonForCancel : "--"}`}>
          {row.reasonForCancel ? row.reasonForCancel : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("mobilenumber")}</strong>,
      selector: "uphoneno",
      cell: (row) => (
        <div title={`${row.uphoneno ? row.uphoneno : "--"}`}>
          {row.uphoneno ? row.uphoneno : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("amount")}</strong>,
      selector: "amount",
      cell: (row) => (
        <div
          title={`€${
            row.amount ? ReplaceFunction(row.amount?.toFixed(2)) : row.amount
          }`}
        >
          {`€${
            row.amount ? ReplaceFunction(row.amount?.toFixed(2)) : row.amount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("creationdate")}</strong>,
      selector: "creationDate",
      cell: (row) => (
        <div
          title={`${
            row.creationDate
              ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
              : "--"
          }`}
        >
          {row.creationDate
            ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("amountbeforecharge")}</strong>,
      selector: "beforeAmount",
      cell: (row) => (
        <div
          title={`€${
            row.beforeAmount
              ? ReplaceFunction(row.beforeAmount?.toFixed(2))
              : row.beforeAmount
          }`}
        >
          {`€${
            row.beforeAmount
              ? ReplaceFunction(row.beforeAmount?.toFixed(2))
              : row.beforeAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("amountaftercharge")}</strong>,
      selector: "afterAmount",
      cell: (row) => (
        <div
          title={`€${
            row.afterAmount
              ? ReplaceFunction(row.afterAmount?.toFixed(2))
              : row.afterAmount
          }`}
        >
          {`€${
            row.afterAmount
              ? ReplaceFunction(row.afterAmount?.toFixed(2))
              : row.afterAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">
          {t("status") + " " + t("by") + " " + t("admin")}
        </strong>
      ),
      selector: "status",
      cell: (row) => (
        <div className="d-flex">
          {row.status === "DISPUTE" ? (
            <>
              {row.approvalStatus === "PENDING" && (
                <div className="tx-danger">
                  <i className="fa fa-close"></i> No
                </div>
              )}
              {row.approvalStatus === "COMPLETED" && (
                <div className="tx-success">
                  <i className="si si-check"></i> Yes
                </div>
              )}
            </>
          ) : (
            "--"
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("status")}</strong>,
      selector: "status",
      cell: (row) => (
        <div className="d-flex">
          {row.status === "IN_PROGRESS" && (
            <div className="select-pending">
              <Select
                value={"IN_PROGRESS"}
                onChange={(newStatus) =>
                  handleStatusChange(
                    row.transactionId,
                    newStatus,
                    row.uusername,
                    row.amount
                  )
                }
                style={{ width: "110px" }}
                className="custom-select-1"
              >
                <option
                  value="APPROVED"
                  className="text-white"
                  style={{ backgroundColor: "#15A552" }}
                >
                  <i className="fa fa-check"></i> {t("approve")}
                </option>
                {/* <option
                  value="CANCELED"
                  className="text-white mt-1"
                  style={{ backgroundColor: "#F53729" }}
                >
                  <i className="si si-close"></i> {t("cancel")}
                </option> */}
                <option
                  value="IN_PROGRESS"
                  className="d-none"
                  style={{ backgroundColor: "#F1911D" }}
                >
                  <div>
                    <i className="fa fa-clock"></i> {t("inprogress")}
                  </div>
                </option>
              </Select>
            </div>
          )}

          <div className="">
            {row.status === "COMPLETED" && (
              <div className="select-approve">
                <Select
                  value={"APPROVED"}
                  onChange={(newStatus) =>
                    handleApproveChange(row.id, newStatus)
                  }
                  style={{ width: "110px" }}
                  className="custom-select-2"
                >
                  <option
                    value="APPROVED"
                    className="d-none"
                    style={{ backgroundColor: "#15A552" }}
                  >
                    <i className="fe fe-check"></i> {t("approve")}
                  </option>
                  <option
                    value="CANCELED"
                    className="text-white mt-1"
                    style={{ backgroundColor: "#F53729" }}
                  >
                    <i className="si si-close"></i> {t("cancel")}
                  </option>
                </Select>
              </div>
            )}
          </div>
          <div className="">
            {row.status === "CANCEL" && (
              <Link
                className="btn btn-danger btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i class="si si-close"></i> {t("canceled")}
              </Link>
            )}
          </div>
          <div className="">
            {row.status === "DISPUTE" && (
              <Link
                className="btn btn-info btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i class="fa fa-ban"></i> {t("disputed")}
              </Link>
            )}
          </div>
          <div className="">
            {row.status === "INITIATE" && (
              <Link
                className="btn btn-dark btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i class="fa fa-hourglass"></i> {t("initiate")}
              </Link>
            )}
          </div>
        </div>
      ),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: <strong className="tx-uppercase">{t("action")}</strong>,
      cell: (row) => (
        <div className="d-flex">
          {/* <div className="me-2">
            <Link
              onClick={() => AddBusinessPartnerModal(row.username)}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-pencil"></i>
            </Link>
          </div> */}
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleDownload(row)}
          >
            <i class="fas fa-file-invoice"></i>
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  const AddBusinessPartnerModal = (id) => {
    setShowUpdateModal(true);
    if (id !== "") {
      setUpdate(true);
      formik.setFieldValue("name", "Marco");
      formik.setFieldValue("reason", "Payment failed");
      formik.setFieldValue("contactnumber", 56454552);
    } else {
      setUpdate(false);
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      reason: "",
      contactnumber: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Please enter Name!"),
      reason: yup.string().required("Please enter Reason!"),
      contactnumber: yup.number().required("Contact number is required"),
    }),
    onSubmit: () => {
      enqueueSnackbar("Dispute Updated Successfully!", {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
      setShowUpdateModal(false);
    },
  });

  const handlesearch = (e) => {
    setSearchText(e.target.value);
  };

  const customStyles = {
    headCells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "175px",
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
      },
    },
    cells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "175px",
        },
      },
    },
    rows: {
      style: {
        ":hover": {
          backgroundColor: "#EEEEEE !important",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#fafafa", // Set the background color for even rows
        },
        "&:nth-child(even)": {
          backgroundColor: "#FFFFFF", // Set the background color for odd rows
        },
        "&:nth-child(even) > :last-child": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :last-child": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even) > :nth-last-child(2)": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :nth-last-child(2)": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on even rows
          },
        },
        "&:nth-child(odd):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on odd rows
          },
        },
      },
    },
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("userrequests")}
      </h2>
      {isDialogOpen && (
        <ApproveRequest
          show={isDialogOpen}
          onHide={handleDialogClose}
          formik={formik}
          approveDetails={approveDetails}
          getDealerToUserRefills={getDealerToUserRefills}
        />
      )}
      {showCancelModal && (
        <RejectRequest
          show={showCancelModal}
          onHide={() => setShowCancelModal(false)}
          id={id}
          getDealerToUserRefills={getDealerToUserRefills}
          approveDetails={approveDetails}
        />
      )}{" "}
      {showUpdateModal && (
        <AddDispute
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
          formik={formik}
          update={update}
        />
      )}
      <div className="d-none">
        <TransactionSlip />
      </div>
      <table ref={tableRef} style={{ display: "none" }}>
        <thead>
          <tr>
            <th>UNIQUE ID</th>
            <th>USERNAME</th>
            <th>EMAIL</th>
            <th>REASON</th>
            <th>MOBILE NUMBER</th>
            <th>AMOUNT</th>
            <th>CREATION DATE</th>
            <th>AMOUNT BEFORE RECHARGE</th>
            <th>AMOUNT AFTER RECHARGE</th>
            <th>STATUS</th>
            <th>REVIEWED BY ADMIN</th>
          </tr>
        </thead>
        <tbody>
          {data.map((data, index) => (
            <tr key={index}>
              <td>{data.uuniqueId}</td>
              <td>{data.uusername}</td>
              <td>{data.uemail}</td>
              <td>{data.reasonForCancel}</td>
              <td>{data.uphoneno}</td>
              <td>{data.amount}</td>
              <td>{moment(data.creationDate).format("DD/MM/YYYY")}</td>
              <td>{data.beforeAmount}</td>
              <td>{data.afterAmount}</td>
              <td>{data.status}</td>
              <td>{data.approvalStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Card>
        <div className="p-3">
          <Row>
            <Col md={2}>
              <Form.Group className="form-group">
                <Multiselect
                  classNamePrefix="Select2"
                  options={STATUS}
                  singleSelect
                  placeholder={t("status")}
                  displayValue="key"
                  name="status"
                  onChange={(selectedOption) =>
                    setSelectedStatus(selectedOption.value)
                  }
                  styles={CUSTOMDROPDOWNSTYLE}
                />
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group className="form-group">
                {/* <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date())
                      .subtract(1, "months")
                      .format("MM/DD/YYYY"),
                    endDate: moment(new Date()).format("MM/DD/YYYY"),
                    ranges: RANGES,
                  }}
                  onApply={setDates}
                >
                  <input
                    type="text"
                    value={
                      moment(startDate).format("DD/MM/YYYY") +
                      " - " +
                      moment(endDate).format("DD/MM/YYYY")
                    }
                    className="form-control"
                  />
                </DateRangePicker> */}
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date()).format("DD/MM/YYYY"),
                    endDate: moment(new Date()).format("DD/MM/YYYY"),
                    ranges: RANGES,
                    autoUpdateInput: false,
                    locale: {
                      format: "DD/MM/YYYY", // Specify the desired format
                    },
                  }}
                  onApply={setDates}
                  onCancel={cancelDate}
                  onHide={cancelDate}
                >
                  {startDate && endDate ? (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      value={
                        startDate && endDate
                          ? `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} - ${moment(endDate).format("DD/MM/YYYY")}`
                          : ""
                      }
                      defaultValue=""
                      onChange={(startDate, endDate) =>
                        handleDateChange(startDate, endDate)
                      }
                      className="form-control"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      defaultValue=""
                      className="form-control"
                    />
                  )}
                </DateRangePicker>
              </Form.Group>
            </Col>

            <Col md={8}>
              <div className="d-flex">
                <div className="d-flex align-items-center md-form mt-auto ms-auto me-3">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-md py-3"
                      value={searchText}
                      onChange={handlesearch}
                      placeholder={t("uniqueid")}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <DownloadTableExcel
                    filename={`${userData.username}_User_Requests.pdf`}
                    sheet="users"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-primary btn-sm me-2 p-2">
                      <i class="fas fa-upload"></i> {t("excel")}
                    </button>
                  </DownloadTableExcel> */}
                  <button
                    onClick={handleDownloadExcel}
                    className="btn btn-primary btn-sm me-2 p-2"
                  >
                    <i class="fas fa-upload"></i> {t("excel")}
                  </button>
                  <button
                    onClick={exportPDF}
                    className="btn btn-primary btn-sm p-2"
                  >
                    <i class="fas fa-file-pdf"></i> PDF
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <DataTable
            title="Free Dealer User Request"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={customStyles}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(FreeDealerUserRequest);

const ApproveRequest = (props) => {
  const { approveDetails, getDealerToUserRefills } = props;
  const transactionService = TransactionService();

  const { t } = useTranslation("common");
  const Recharge = async () => {
    try {
      props.onHide();
      const response = await transactionService.changeStatusOfRechargeByDealer(
        "",
        approveDetails.transactionId,
        "COMPLETED"
      );
      enqueueSnackbar(t("SUCCESSFULLY_DEALER_REQUEST_RECHARGE"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
    getDealerToUserRefills();
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("rechargerequest")}
        </h5>
      </Modal.Header>

      <Modal.Body className="text-center p-0 pb-4 mt-3">
        <p className="mg-b-20 mg-x-20 fs-18">
          {t("collecttheamountof")} €{approveDetails?.amount} {t("from")}{" "}
          {approveDetails?.username} {t("andconfirmtherecharge")}.
        </p>
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            onClick={() => Recharge()}
            variant="primary"
            className="me-3 btn-sm"
          >
            {t("recharge")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const RejectRequest = (props) => {
  const navigate = useNavigate();
  const transactionService = TransactionService();
  const { id, getDealerToUserRefills } = props;

  const { t } = useTranslation("common");
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: yup.object().shape({
      reason: yup.string().required("Please enter valid reason!"),
    }),
    onSubmit: async (values, action) => {
      const { reason } = values;
      try {
        const response =
          await transactionService.changeStatusOfRechargeByDealer(
            reason,
            id,
            "DISPUTE"
          );
        enqueueSnackbar(t("SUCCESSFULLY_DEALER_REQUEST_REJECTED"), {
          variant: SUCCESS,
          anchorOrigin: ANCHORORIGIN,
        });
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
      props.onHide();
      getDealerToUserRefills();
    },
  });

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("dispute")}
        </h5>
      </Modal.Header>

      <Modal.Body>
        {/* <p className="mg-b-20 mg-x-20 fs-18 mt-3">
          Are you sure want to Reject request of Matteo ?
        </p> */}
        <form onSubmit={formik.handleSubmit}>
          <Row className="row-sm">
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("reason")} :</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={formik.values.reason}
                onChange={formik.handleChange}
                name="reason"
                placeholder={t("reason")}
                style={{ borderColor: "#D3D3DE" }}
              />
              {formik.errors.reason && formik.touched.reason ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.reason}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" variant="danger" className="btn-sm">
                {t("dispute")}
              </Button>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};
