import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Container,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import * as yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { ANCHORORIGIN, ERROR, FORMIKERROR } from "../../utils/constants";
import AuthService from "../../services/auth.service";
import VerifyOTP from "./VerifyOTP";
import { useTranslation } from "react-i18next";
import IT from "../../../assets/img/it.svg";
import US from "../../../assets/img/us.png";

const ForgotPassword = () => {
  const authService = AuthService();
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(localStorage.getItem("language"));
  const [loading, setLoading] = useState(false);
  const [showOTPVerify, setShowOTPVerify] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [showForgotPasssword, setShowForgotPasssword] = useState(true);

  const handleLanguageChange = (e) => {
    window.localStorage.setItem("language", e);
    i18n.changeLanguage(e);
    setValue(e);
  };

  const handleFormSubmit = async (values, action) => {
    const { email } = values;

    try {
      setLoading(true);
      const response = await authService.forgotPasssword(email);

      setEnteredEmail(email);

      // Verify OTP Page Call and Forgot Password Page Remove
      setShowOTPVerify(true);
      setShowForgotPasssword(false);
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required!"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    if (localStorage.getItem("language") !== null) {
      const lang = localStorage.getItem("language");
      i18n.changeLanguage(lang);
      setValue(lang);
    } else {
      setValue("en");
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    }
  }, []);

  return (
    <>
      {showOTPVerify && (
        <VerifyOTP email={enteredEmail} showOTPVerify={showOTPVerify} />
      )}
      {showForgotPasssword && (
        <Fragment>
          <div className="d-flex align-items-center ms-3 mt-2">
            <span className="text-muted tx-15">{t("selectlanguage")} : </span>
            <Dropdown
              id="nav-dropdown-dark-example"
              title={"en"}
              className="main-profile-menu"
              onSelect={(e) => handleLanguageChange(e)}
            >
              <Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="text-dark"
              >
                {value === "it" ? (
                  <span>
                    {" "}
                    <img src={IT} alt="" width={"20px"} /> Italian
                  </span>
                ) : (
                  <span>
                    <img src={US} alt="" width={"20px"} /> English
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-lang-login">
                <Dropdown.Item
                  eventKey={"en"}
                  // className="dropdown-item"
                  className="header-navheading d-flex align-items-center"
                  style={{ cursor: "pointer", height: "45px" }}
                >
                  <span className="align-items-center">
                    <img src={US} alt="" width={"20px"} /> English
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={"it"}
                  className="header-navheading d-flex align-items-center"
                  style={{ cursor: "pointer", height: "45px" }}
                >
                  <span className="align-items-center">
                    <img src={IT} alt="" width={"20px"} /> Italian
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className="page main-signin-wrapper"
            style={{ marginTop: "-48px" }}
          >
            <Row className="signpages text-center">
              <Col md={12}>
                <Card style={{ height: "387px" }}>
                  <Row className="row-sm" style={{ height: "387px" }}>
                    <Col
                      lg={6}
                      xl={5}
                      className="d-flex justify-content-center d-lg-block text-center bg-primary details"
                    >
                      <div className="mt-5 pt-4 p-2 pos-absolute">
                        <img
                          src={
                            require("../../../assets/img/svgs/user.svg").default
                          }
                          className="ht-100 mb-0"
                          alt="user"
                        />
                        <h5 className="mt-4 text-white">
                          {t("createyouraccount")}
                        </h5>
                        <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                          {t("signuptocreatesentance")}
                        </span>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      xl={7}
                      xs={12}
                      sm={12}
                      className="login_form d-flex align-items-center"
                    >
                      <Container fluid>
                        <Row className="row-sm">
                          <Card.Body className="mt-2 mb-2">
                            <form onSubmit={formik.handleSubmit}>
                              <Form.Group
                                className="text-start form-group"
                                controlId="formEmail"
                              >
                                <h5 className="text-start mb-2 d-flex justify-content-center">
                                  {t("forgotpassword")}
                                </h5>
                                <Form.Group
                                  className="text-start form-group"
                                  controlId="formEmail"
                                >
                                  <Form.Label className="text-dark">
                                    {t("email")}
                                  </Form.Label>
                                  <Form.Control
                                    className="form-control text-lowercase"
                                    placeholder="Enter your email"
                                    name="email"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                  />
                                  {formik.errors.email &&
                                  formik.touched.email ? (
                                    <Form.Label style={FORMIKERROR}>
                                      {formik.errors.email}
                                    </Form.Label>
                                  ) : null}
                                </Form.Group>

                                {loading ? (
                                  <Button className="btn ripple btn-main-primary btn-block mt-2">
                                    <CircularProgress
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        color: "#ffffff",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-block mt-4 me-3"
                                    // disabled={loading}
                                  >
                                    {t("submit")}
                                  </button>
                                )}
                              </Form.Group>
                            </form>
                          </Card.Body>
                        </Row>
                        <ToastContainer />
                      </Container>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Fragment>
      )}
    </>
  );
};

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
