import React, { useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import withLoader from "../../layout/Loader/WithLoader";
import "jspdf-autotable";
import { enqueueSnackbar } from "notistack";
import useDialogState from "../../hooks/useDialog";
import TransactionSlip from "../Users/TransactionSlip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import * as yup from "yup";
import { useFormik } from "formik";
import AddDisputeRequest, {
  ConfirmRequest,
} from "../UserRecharges(ByDelaer)/AddDisputeRequest";
import { PAGELIMIT } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import Enums from "../../utils/enums";

const DisputeRequest = () => {
  const { t } = useTranslation("common");
  const { RANGES } = Enums();
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [searchString, setSearchString] = useState("");
  const [showModal, setShowModal] = useState(false);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();
  const [dates, setDatesState] = useState({
    startDate: moment(new Date()).format("DD/MM/YYYY"),
    endDate: moment(new Date()).format("DD/MM/YYYY"),
  });

  const setDates = (e, { startDate, endDate }) => {
    setDatesState({
      startDate: startDate.format("DD/MM/YYYY"),
      endDate: endDate.format("DD/MM/YYYY"),
    });
  };

  const handlesearch = (e) => {
    setSearchString(e.target.value);
  };

  const filteredData = [
    {
      dealername: "Matteo",
      username: "Marco",
      reason: "Payment Failed",
      uniqueid: "FW599652",
      usercontactnumber: "+39 94633321",
      dealercontactnumber: "+39 89454653",
      creationdate: "10/07/2023 12:30:00 PM",
      status: "PENDING",
    },
    {
      dealername: "Luca",
      username: "Aleexandro",
      reason: "Parking Not Available",
      uniqueid: "KM455596",
      usercontactnumber: "+39 90445455",
      dealercontactnumber: "+39 8894653131",
      creationdate: "01/07/2023 10:00:00 AM",
      status: "APPROVED",
    },
  ];

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("dealername")}</strong>,
      selector: (row) => (
        <div title={`${row.dealername}`}>{row.dealername}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: (row) => <div title={`${row.username}`}>{row.username}</div>,
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("reason")}</strong>,
      selector: (row) => <div title={`${row.reason}`}>{row.reason}</div>,
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: (row) => <div title={`${row.uniqueid}`}>{row.uniqueid}</div>,
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">
          {t("dealer") + " " + t("mobilenumber")}
        </strong>
      ),
      selector: (row) => (
        <div title={`${row.dealercontactnumber}`}>
          {row.dealercontactnumber}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">
          {t("user") + " " + t("mobilenumber")}
        </strong>
      ),
      selector: (row) => (
        <div title={`${row.usercontactnumber}`}>{row.usercontactnumber}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("creationdate")}</strong>,
      selector: (row) => (
        <div title={`${row.creationdate}`}>{row.creationdate}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("status")}</strong>,
      selector: (row) => (
        <div className="d-flex">
          <div className="">
            {row.status === "PENDING" && (
              <button
                className="btn btn-warning btn-sm"
                onClick={() => AddBusinessPartnerModal("")}
                style={{
                  width: "95px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <i className="fa fa-clock"></i> {t("pending")}
              </button>
            )}
          </div>
          <div className="">
            {row.status === "APPROVED" && (
              <Link
                className="btn btn-success btn-sm"
                style={{
                  width: "95px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i className="fa fa-check"></i> {t("approved")}
              </Link>
            )}
          </div>
        </div>
      ),
      sortable: true,
    },
  ];

  const AddBusinessPartnerModal = () => {
    handleDialogOpen();
    formik.setFieldValue("dealername", "Matteo");
    formik.setFieldValue("username", "Marco");
    formik.setFieldValue("dealerWalletAmount", 200);
    formik.setFieldValue("userWalletAmount", 120);
  };

  const handleCloseDialog = () => {
    handleDialogClose();
    formik.resetForm();
  };

  const handleOpen = () => {
    setShowModal(true);
    handleDialogClose();
  };

  const handleClose = () => {
    setShowModal(false);
    enqueueSnackbar("Wallet Amount Updated Successfully!", {
      variant: "success",
      anchorOrigin: {
        horizontal: "right",
        vertical: "bottom",
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      dealername: "",
      dealerWalletAmount: "",
      username: "",
      userWalletAmount: "",
    },
    validationSchema: yup.object().shape({
      dealername: yup.string().required("Please enter Dealer Name!"),
      dealerWalletAmount: yup.number().required("Please enter Wallet Amount!"),
      username: yup.string().required("Please enter User Name!"),
      userWalletAmount: yup.number().required("Please enter Wallet Amount!"),
    }),
    onSubmit: () => {
      enqueueSnackbar("Dispute Added Successfully!", {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
      handleClose();
    },
  });

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("disputerequests")}
      </h2>

      {isDialogOpen && (
        <AddDisputeRequest
          show={isDialogOpen}
          onHide={handleCloseDialog}
          formik={formik}
          handleOpen={handleOpen}
          showModal={showModal}
        />
      )}

      {showModal && (
        <ConfirmRequest
          show={showModal}
          onHide={() => setShowModal(false)}
          handleClose={handleClose}
        />
      )}

      <div className="d-none">
        <TransactionSlip />
      </div>

      <Card>
        <div className="p-3">
          <Row>
            <Col md={2}>
              <Form.Group className="form-group">
                <DateRangePicker
                  onApply={setDates}
                  initialSettings={{
                    startDate: moment(new Date()).format("MM/DD/YYYY"),
                    endDate: moment(new Date()).format("MM/DD/YYYY"),
                    ranges: RANGES,
                  }}
                >
                  <input
                    type="text"
                    value={dates.startDate + " - " + dates.endDate}
                    className="form-control"
                  />
                </DateRangePicker>
              </Form.Group>
            </Col>

            <Col md={10}>
              <Form.Group className="form-group">
                <div className="d-flex">
                  <div className="d-flex align-items-center md-form mt-auto ms-auto">
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control form-control-md py-3"
                        value={searchString}
                        onChange={handlesearch}
                        placeholder="Search.."
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <DataTable
            title="Dispute Request"
            columns={columns}
            data={filteredData}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            // paginationTotalRows={totalRows}
            // paginationPerPage={dataPerPage}
            // onChangeRowsPerPage={(page) => setDataPerPage(page)}
            // onChangePage={handlePageChange}
            highlightOnHover
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(DisputeRequest);
