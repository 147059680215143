import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card, Form, Modal, Row } from "react-bootstrap";
import useDialogState from "../../hooks/useDialog";
import withLoader from "../../layout/Loader/WithLoader";
import { useFormik } from "formik";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import Multiselect from "react-select";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";
import ReactDOM from "react-dom";
import ExcelJS from "exceljs";
import {
  ANCHORORIGIN,
  CUSTOM_STYLES,
  ERROR,
  PAGELIMIT,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import UserService from "../../services/user.service";
import { useTranslation } from "react-i18next";
import TransactionService from "../../services/transaction.service";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Enums from "../../utils/enums";
import TransactionSlip from "../Users/TransactionSlip";

const PaidDealerManualRecharge = () => {
  const userService = UserService();
  const transactionService = TransactionService();
  const { t } = useTranslation("common");
  const { RANGES } = Enums();

  const neosStorageString = localStorage.getItem("neos-storage");
  const neosStorage = JSON.parse(neosStorageString);

  const userId = neosStorage && neosStorage.id;

  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [totalRows, setTotalRows] = useState("");
  const [userData, setUserData] = useState("");
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [userCode, setUserCode] = useState([]);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const handleOpen = () => {
    handleDialogOpen();
  };

  const getManualRechargeOfUserByDealer = async () => {
    const response = await transactionService.getManualRechargeOfUserByDealer({
      endDate: new Date(endDate).getTime(),
      pageLimit: dataPerPage,
      pageNumber: pageNumber,
      searchString: searchString,
      startDate: new Date(startDate).getTime(),
      userId: userId,
    });

    setData(response.data.apiresponse.data.recharges);
  };

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const getRollUsers = async () => {
    const response = await userService.getRoleUsers("USER");
    setUserCode(response.data.apiresponse.data);
  };

  const getDealerName = async () => {
    const response = await userService.getUserById(userId);
    setUserData(response.data.apiresponse.data);
  };

  useEffect(() => {
    getRollUsers();
    getManualRechargeOfUserByDealer();
    getDealerName();
  }, []);

  useEffect(() => {
    getManualRechargeOfUserByDealer();
  }, [startDate, endDate, searchString, pageNumber, dataPerPage]);

  const users = [
    ...userCode.map((code) => ({ label: code.uniqeId, value: code.id })),
  ];

  const selectUniqueCode = (selectedOption) => {
    formik.setFieldValue("uniqueid", selectedOption.value);
    // setOptions(selectedOption);
  };

  const formik = useFormik({
    initialValues: {
      uniqueid: "",
      amount: "",
    },
    validationSchema: yup.object().shape({
      uniqueid: yup.mixed().required("Please Enter Unique Code!"),
      amount: yup.string().required("Please Enter Amount!"),
    }),
    onSubmit: async (values, action) => {
      const { amount, uniqueid } = values;
      try {
        await transactionService.makeManualRecharge(amount, uniqueid);

        enqueueSnackbar(t("RECHARGE_ADDED"), {
          variant: SUCCESS,
          anchorOrigin: ANCHORORIGIN,
        });
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
        console.log(error.response.data.apierror.message);
      }
      getManualRechargeOfUserByDealer();
      getRollUsers();
      handleDialogClose();
      action.resetForm();
    },
  });

  const error = {
    fontSize: "12px",
    color: "red",
    marginTop: "3px",
  };

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: "username",
      cell: (row) => (
        <div title={`${row.username ? row.username : "--"}`}>
          {row.username ? row.username : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("email")}</strong>,
      selector: "email",
      cell: (row) => (
        <div title={`${row.email ? row.email : "--"}`}>
          {row.email ? row.email : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: "uniqueId",
      cell: (row) => (
        <div title={`${row.uniqueId ? row.uniqueId : "--"}`}>
          {row.uniqueId ? row.uniqueId : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("amount")}</strong>,
      selector: "amount",
      cell: (row) => (
        <div
          title={`€${
            row.amount ? ReplaceFunction(row.amount?.toFixed(2)) : row.amount
          }`}
        >
          {`€${
            row.amount ? ReplaceFunction(row.amount?.toFixed(2)) : row.amount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("creationdate")}</strong>,
      selector: "creationDate",
      cell: (row) => (
        <div
          title={`${
            row.creationDate
              ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
              : "--"
          }`}
        >
          {row.creationDate
            ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong>{t("action")}</strong>,
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleDownload(row)}
          >
            <i class="fas fa-file-invoice"></i>
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  const handleDownload = (row) => {
    const receiptContainer = document.createElement("div");
    receiptContainer.classList.add("receipt-container");

    const transactionSlip = <TransactionSlip rowData={row} path="manual" />;
    const paymentSlip = document.createElement("div");
    ReactDOM.render(transactionSlip, paymentSlip);
    receiptContainer.appendChild(paymentSlip);

    document.body.appendChild(receiptContainer);

    const options = {
      margin: -5,
      filename: `${row.username}_Payment_Slip.pdf`,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [550, 595],
        orientation: "portrait",
      },
    };

    html2pdf().from(receiptContainer).set(options).save();

    document.body.removeChild(receiptContainer);
  };

  let totalCost = 0;
  for (let i = 0; i < data?.length; i++) {
    totalCost += parseFloat(data[i]?.amount);
  }

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const pageWidth = 595.28;
    const titleFontSize = 18;
    const width = 515;
    const textColor = [0, 0, 0];
    const fillColor = [200, 200, 200];

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(titleFontSize);

    const title = "Ricariche NeosApp";
    const titleWidth = doc.getStringUnitWidth(title) * titleFontSize;
    const titleX = (pageWidth - titleWidth) / 2;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 16, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "bold");
    doc.text(title, titleX, 35);

    const catania = userData.town;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 50, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.text(catania, 45, 70);

    const punto = "Punto ricarica: ";
    const name = `${userData.town} - ${userData.address}`;
    const salvatore = `${userData.username} ${userData.surname}`;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 80, width, 54, "FD");
    doc.setTextColor(...textColor);
    doc.text(punto, 45, 100);
    doc.text(name, 200, 100);
    doc.text(salvatore, 200, 125);

    const period = "Periodo dal";
    const startTime =
      startDate !== 0 ? moment(startDate).format("DD/MM/YYYY") : "Toda";
    const endTime =
      endDate !== 0 ? `al  ${moment(endDate).format("DD/MM/YYYY")}` : "";
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 134, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.text(period, 45, 152);
    doc.text(startTime, 220, 152);
    doc.text(endTime, 330, 152);

    const headers = [["Unique ID", "Card", "Data", "Descrizione", "Ricariche"]];
    // set data table columns names
    const newData = data.map((elt) => [
      elt.id,
      elt.uniqueId,
      moment(elt.creationDate).format("DD/MM/YYYY HH:mm:ss"),
      elt.reasonForCancel ? elt.reasonForCancel : "--",
      `€${elt.amount ? ReplaceFunction(elt.amount?.toFixed(2)) : elt.amount}`,
    ]);

    newData.push([
      "",
      "",
      {
        content: "Total ricariche:",
        colSpan: 2,
        styles: {
          halign: "right",
          fontStyle: "bold",
          fillColor: [...fillColor],
          textColor: [...textColor],
          lineWidth: 0.5,
          lineColor: [...textColor],
        },
      },
      {
        content: `€${
          totalCost ? ReplaceFunction(totalCost?.toFixed(2)) : totalCost
        }`,
        // content: "€ " + totalCost,
        colSpan: 1,
        styles: {
          halign: "right",
          textColor: [...textColor],
          lineWidth: 0.5,
          lineColor: [...textColor],
        },
      },
    ]);

    const headStyles = {
      fillColor: [...fillColor],
      textColor: [...textColor],
      lineWidth: 0.5,
      lineColor: [...textColor],
      halign: "center",
      fontSize: 13,
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 13,
    };

    const columnStyles = {
      4: { halign: "right" },
    };

    const alternateRowStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 180,
        head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    doc.save(`${userData.username}_Manual_Recharges.pdf`);
  };

  const handleDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["Ricariche NeosApp"]);
    worksheet.mergeCells("A1:E1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    titleRow.getCell(1).fill = commonCellStyle.fill;
    titleRow.getCell(1).border = commonCellStyle.border;
    titleRow.getCell(1).font = commonCellStyle.font;
    worksheet.addRow([]);
    worksheet.mergeCells("A2:E2");

    const catania = worksheet.addRow([userData.town]);
    worksheet.mergeCells("A3:E3");
    catania.getCell(1).fill = commonCellStyle.fill;
    catania.getCell(1).border = commonCellStyle.border;
    catania.getCell(1).font = commonCellStyle.font;

    const punto = worksheet.addRow([
      `Punto ricarica:   ${userData.town} - ${userData.address}`,
    ]);
    worksheet.mergeCells("A4:E4");
    punto.getCell(1).fill = commonCellStyle.fill;
    punto.getCell(1).border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "none" },
      right: { style: "thin", color: { argb: "000000" } },
    };
    punto.getCell(1).font = commonCellStyle.font;

    const salvatore = worksheet.addRow([
      `                                ${userData.username} ${userData.surname}`,
    ]);
    worksheet.mergeCells("A5:E5");
    salvatore.getCell(1).fill = commonCellStyle.fill;
    salvatore.getCell(1).border = {
      top: { style: "none" },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    salvatore.getCell(1).font = commonCellStyle.font;

    const period = worksheet.addRow([
      `Periodo dal               ${
        startDate !== 0 ? moment(startDate).format("DD/MM/YYYY") : "Toda"
      } ${endDate !== 0 ? "al " + moment(endDate).format("DD/MM/YYYY") : ""}`,
    ]);
    worksheet.mergeCells("A6:E6");
    period.getCell(1).fill = commonCellStyle.fill;
    period.getCell(1).border = commonCellStyle.border;
    period.getCell(1).font = commonCellStyle.font;

    worksheet.addRow([]);
    worksheet.mergeCells("A7:E7");

    // Add column headers
    const headers = ["Unique ID", "Card", "Data", "Descrizione", "Ricariche"];
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 22;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 10;
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.fill = commonCellStyle.fill;
      cell.font = commonCellStyle.font; // Text color
      cell.border = commonCellStyle.border;
    });

    // Add data rows
    data.forEach((res) => {
      const table = worksheet.addRow([
        res.id,
        res.uniqueId,
        moment(res.creationDate).format("DD/MM/YYYY HH:mm:ss"),
        res.reasonForCancel ? res.reasonForCancel : "--",
        `€${res.amount ? ReplaceFunction(res.amount?.toFixed(2)) : res.amount}`,
      ]);
      table.eachCell((cell) => {
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
      });
    });

    const totalRicaricheRow = worksheet.addRow([
      "",
      "",
      "",
      "Total ricariche:",
      `€${totalCost ? ReplaceFunction(totalCost?.toFixed(2)) : totalCost}`,
    ]);
    totalRicaricheRow.getCell(4).fill = commonCellStyle.fill;
    totalRicaricheRow.getCell(4).border = commonCellStyle.border;
    totalRicaricheRow.getCell(5).border = commonCellStyle.border;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${userData.username}_Manual_Recharges.xlsx`;
    link.click();
  };

  const handlesearch = (e) => {
    setSearchString(e.target.value);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("manualreacharge")}
      </h2>
      <div className="d-none">
        <TransactionSlip />
      </div>
      <Card>
        <div className="p-3">
          <div className="d-flex mb-2">
            <Form.Group className="form-group mb-0">
              {/* <DateRangePicker
                initialSettings={{
                  startDate: moment(new Date())
                    .subtract(1, "months")
                    .format("MM/DD/YYYY"),
                  endDate: moment(new Date()).format("MM/DD/YYYY"),
                  ranges: RANGES,
                }}
                onApply={setDates}
              >
                <input
                  type="text"
                  value={
                    moment(startDate).format("DD/MM/YYYY") +
                    " - " +
                    moment(endDate).format("DD/MM/YYYY")
                  }
                  className="form-control"
                />
              </DateRangePicker> */}
              <DateRangePicker
                initialSettings={{
                  startDate: moment(new Date()).format("DD/MM/YYYY"),
                  endDate: moment(new Date()).format("DD/MM/YYYY"),
                  ranges: RANGES,
                  autoUpdateInput: false,
                  locale: {
                    format: "DD/MM/YYYY", // Specify the desired format
                  },
                }}
                onApply={setDates}
                onCancel={cancelDate}
                onHide={cancelDate}
              >
                {startDate && endDate ? (
                  <input
                    type="text"
                    placeholder={t("selectperiodvalidity")}
                    value={
                      startDate && endDate
                        ? `${moment(startDate).format("DD/MM/YYYY")} - ${moment(
                            endDate
                          ).format("DD/MM/YYYY")}`
                        : ""
                    }
                    defaultValue=""
                    onChange={(startDate, endDate) =>
                      handleDateChange(startDate, endDate)
                    }
                    className="form-control"
                  />
                ) : (
                  <input
                    type="text"
                    placeholder={t("selectperiodvalidity")}
                    defaultValue=""
                    className="form-control"
                  />
                )}
              </DateRangePicker>
            </Form.Group>

            <div className="d-flex ms-auto">
              <div className="d-flex align-items-center md-form mt-auto ms-auto me-3">
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control form-control-md py-3"
                    value={searchString}
                    onChange={handlesearch}
                    placeholder={t("uniqueid")}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="ms-auto">
                  <button
                    className="btn btn-primary btn-sm me-2 p-2"
                    onClick={handleOpen}
                  >
                    <i className="fe fe-plus"></i> {t("add")}
                  </button>

                  <button
                    onClick={handleDownloadExcel}
                    className="btn btn-primary btn-sm me-2 p-2"
                  >
                    <i class="fas fa-upload"></i> {t("excel")}
                  </button>

                  <button
                    onClick={exportPDF}
                    className="btn btn-primary btn-sm p-2"
                  >
                    <i class="fas fa-file-pdf"></i> PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isDialogOpen && (
            <AmountDialog
              show={isDialogOpen}
              onHide={handleDialogClose}
              formik={formik}
              users={users}
              selectUniqueCode={selectUniqueCode}
              error={error}
            />
          )}
          <DataTable
            title="Paid Dealer Manual Recharge"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={CUSTOM_STYLES}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(PaidDealerManualRecharge);

const AmountDialog = (props) => {
  const { t } = useTranslation("common");
  const { formik, error, selectUniqueCode, users } = props;

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("manualreacharge")}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row className="row-sm">
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("uniqueid")} :</Form.Label>
              <Multiselect
                classNamePrefix="Select2"
                options={users}
                singleSelect
                placeholder={t("uniqueid")}
                displayValue="key"
                name="uniqueid"
                onChange={selectUniqueCode}
              />
              {formik.errors.uniqueid && formik.touched.uniqueid ? (
                <Form.Label style={error}>{formik.errors.uniqueid}</Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("amount")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.amount}
                onChange={formik.handleChange}
                name="amount"
                placeholder={t("amount")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.amount && formik.touched.amount ? (
                <Form.Label style={error}>{formik.errors.amount}</Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <div className="d-flex">
                <button
                  className="btn btn-primary btn-sm ms-auto tx-uppercase"
                  type="submit"
                >
                  {t("add") + " " + t("amount")}
                </button>
              </div>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};
