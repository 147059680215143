import { enqueueSnackbar } from "notistack";
import ServerCall from "../../serverCall";
import { get } from "lodash";
import { CHILD_USER_LOGIN_ERROR } from "../utils/messages";
import { ANCHORORIGIN, ERROR } from "../utils/constants";

const AuthService = () => {
  const serverCall = ServerCall();

  const Login = async (loginPage) => {
    const { email, password } = loginPage;
    const newPass = encodeURIComponent(password);
    const response = await serverCall.customAxios.post(
      `/auth/login?email=${email}&password=${newPass}`
    );

    const data = get(response, "data.apiresponse.data.token", null);
    const Email = get(response, "data.apiresponse.data.email", null);
    const ID = get(response, "data.apiresponse.data.id", null);
    const Role = get(response, "data.apiresponse.data.role", null);
    const Type = get(response, "data.apiresponse.data.dealerType", null);

    if (Role === "CHILD_USER") {
      enqueueSnackbar(CHILD_USER_LOGIN_ERROR, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    } else if (Role !== "CHILD_USER" && data && Email) {
      const neosStorage = {
        token: data,
        email: Email,
        isLoggedIn: true,
        id: ID,
        role: Role,
        dealerType: Type,
      };
      const lang = localStorage.getItem("language");
      if (lang) {
        console.log("lang", lang);
        localStorage.setItem("language", lang);
      } else {
        localStorage.setItem("language", "it");
      }
      localStorage.setItem("neos-storage", JSON.stringify(neosStorage));
    }
    return response;
  };

  const forgotPasssword = (email) => {
    const response = serverCall.customAxios.post(
      `/auth/forgotPassword?email=${email}`
    );
    return response;
  };

  const verifyOTP = (email, otp) => {
    const response = serverCall.customAxios.post(
      `/auth/verifyForgotPwdOTP?email=${email}&otp=${otp}`
    );
    return response;
  };

  const setNewPassword = (email, newPassword, token) => {
    const response = serverCall.customAxios.post(
      `/auth/verifyForgotPwdToken?email=${email}&newPassword=${newPassword}&token=${token}`
    );
    return response;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
  };

  return {
    Login,
    logout,
    forgotPasssword,
    verifyOTP,
    setNewPassword,
  };
};

export default AuthService;
