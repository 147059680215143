import React, { useEffect, useState } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import BusinessPartnerService from "../../services/businessPartner.service";
import withLoader from "../../layout/Loader/WithLoader";
import {
  SUCCESSFULLY_BUSINESSPARTNER_DELETED,
  SUCCESSFULLY_BUSINESSPARTNER_UPDATED,
} from "../../utils/messages";
import Enums from "../../utils/enums";
import CityService from "../../services/city.service";
import { ANCHORORIGIN, EMPTY, ERROR, SUCCESS } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import UserService from "../../services/user.service";
import DataTable from "react-data-table-component";
import DeleteBusinessPartner from "./DeleteBusinessPartner";
import useDialogState from "../../hooks/useDialog";
import AddBusinessPartner from "./AddBusinessPartner";
import useMenuState from "../../hooks/useMenuState";

const BusinessPartnersProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const businessPartnerService = BusinessPartnerService();
  const cityService = CityService();
  const userService = UserService();
  const { ADDRESS_TYPE } = Enums();
  const { id } = useParams();
  const [data, setData] = useState("");
  const [details, setDetails] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [update, setUpdate] = useState(true);
  const [newCity, setNewCity] = useState([]);
  const [newAddressCity, setNewAddressCity] = useState("");
  const [newAddressType, setNewAddressType] = useState("");
  const [addNumbers, setAddNumbers] = useState(1);
  const [vatNumber, setVatNumber] = useState("");
  const [addAddressSlot, setAddAddressSlot] = useState(1);
  const [faxNumber, setFaxNumber] = useState("");
  const [controllerData, setControllerData] = useState([]);
  const [isShowStreet, setIsShowStreet] = useState(false);
  const [ctelephoneNumber, setCTelephoneNumber] = useState("");
  const [ttelephoneNumber, setTTelephoneNumber] = useState("");
  const [atelephoneNumber, setATelephoneNumber] = useState("");
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const city = [
    ...newCity.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  ];

  const [openAddressType, handleOpenAddressType, handleCloseAddressType] =
    useMenuState(Array(ADDRESS_TYPE.length).fill(false));
  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(
    Array(city.length).fill(false)
  );
  const [openPaymentType, handleOpenPaymentType, handleClosePaymentType] =
    useMenuState(false);
  const maxNumberSize = 15;

  const addAddressData = () => {
    // Set empty filed when we add new slot
    formik.setFieldValue("addressList", [
      ...formik.values.addressList,
      {
        city: "",
        id: 0,
        pincode: "",
        state: "",
        street: "",
        type: null,
      },
    ]);
    setAddAddressSlot(addAddressSlot + 1);
  };

  // remove Address slot logic
  const removeAddress = (index) => {
    if (addAddressSlot > 1) {
      formik.setFieldValue(
        "addressList",
        formik.values.addressList.filter((_, i) => i !== index)
      );
      setAddAddressSlot(addAddressSlot - 1);
    }
  };

  // Add new number slot logic
  const addNumber = () => {
    // Set empty filed when we add new slot
    formik.setFieldValue("contanctNumbers", [
      ...formik.values.contanctNumbers,
      {
        bpId: 0,
        countryCode: "",
        id: 0,
        phoneNo: "",
      },
    ]);
    setAddNumbers(addNumbers + 1);
  };

  // remove number slot logic
  const removeNumber = (index) => {
    if (addNumbers > 1) {
      formik.setFieldValue(
        "contanctNumbers",
        formik.values.contanctNumbers.filter((_, i) => i !== index)
      );
      setAddNumbers(addNumbers - 1);
    }
  };

  const handleshowStreet = (e) => {
    const isChecked = e.target.checked;

    setIsShowStreet(isChecked);
    formik.setFieldValue(`showStreet`, isChecked);
  };

  const handleFaxNumberChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    if (sanitizedInput.length > maxNumberSize) {
      return;
    }
    let formattedNumber = "";
    let countryCode = sanitizedInput.slice(0, 2);
    for (let i = 0; i < sanitizedInput.length; i++) {
      if (i === 2 || i === 5) {
        formattedNumber += ` ${sanitizedInput.charAt(i)}`;
      } else {
        formattedNumber += sanitizedInput.charAt(i);
      }
    }

    setFaxNumber(formattedNumber);
    formik.setFieldValue("countryCode", countryCode);
    formik.setFieldValue("fax", formattedNumber);
  };
  const handlePhoneNumberChange = (event, phoneNumberType) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    switch (phoneNumberType) {
      case "administrative":
        setATelephoneNumber(sanitizedInput);
        formik.setFieldValue("administrativeTelephone", sanitizedInput);
        break;
      case "technical":
        setTTelephoneNumber(sanitizedInput);
        formik.setFieldValue("technicalTelephone", sanitizedInput);
        break;
      case "commercial":
        setCTelephoneNumber(sanitizedInput);
        formik.setFieldValue("commercialTelephone", sanitizedInput);
        break;
      default:
        break;
    }
  };
  const handleInvoiceCode = (e) => {
    const uppercase = e.target.value.toUpperCase();
    if (uppercase.length > 7) {
      return;
    }
    formik.setFieldValue("einvoiceCode", uppercase);
  };
  const handleChangePhone = (index, country, value) => {
    if (country.startsWith(value.dialCode)) {
      const number = country.slice(value.dialCode.length);

      formik.setFieldValue(`contanctNumbers[${index}].phoneNo`, number);
      formik.setFieldValue(
        `contanctNumbers[${index}].countryCode`,
        value.dialCode
      );
    }
  };

  const getChildBusinessPartners = async () => {
    const response = await userService.getAllChildBusinessPartners(
      EMPTY,
      EMPTY,
      id,
      "CHILD_BUSINESS_PARTNER",
      EMPTY
    );
    setControllerData(response.data.apiresponse.data.users);
  };

  const deleteBusinessPartner = async () => {
    try {
      await businessPartnerService.deleteBusinessPartner(id);
      enqueueSnackbar(t("SUCCESSFULLY_BUSINESSPARTNER_DELETED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      navigate("/businesspartners/");
      setModalShow(false);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const handlePincodeChange = (e, index) => {
    const input = e.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    formik.setFieldValue(`addressList[${index}].pincode`, sanitizedInput);
  };

  // Add address slot
  // const addAddressData = () => {
  //   // Empty address slot with empty field data
  //   formik.setFieldValue("addressList", [
  //     ...addressList,
  //     {
  //       city: "",
  //       id: 0,
  //       pincode: "",
  //       state: "",
  //       street: "",
  //       type: null,
  //     },
  //   ]);
  //   setAddAddressSlot(addAddressSlot + 1);
  // };

  // remove slot by index
  // const removeAddress = (index) => {
  //   if (addAddressSlot > 1) {
  //
  //     formik.setFieldValue(
  //       "addressList",
  //       addressList.filter((_, i) => i !== index)
  //     );
  //     setAddAddressSlot(addAddressSlot - 1);
  //   }
  // };

  // Add number slot
  // const addNumber = () => {
  //   // Empty number slot with empty field data
  //   formik.setFieldValue("contanctNumbers", [
  //     ...contanctNumbers,
  //     {
  //       bpId: 0,
  //       countryCode: "",
  //       id: 0,
  //       phoneNo: "",
  //     },
  //   ]);
  //   setAddNumbers(addNumbers + 1);
  // };

  // remove slot by index
  // const removeNumber = (index) => {
  //   if (addNumbers > 1) {
  //     formik.setFieldValue(
  //       "contanctNumbers",
  //       contanctNumbers.filter((_, i) => i !== index)
  //     );
  //     setAddNumbers(addNumbers - 1);
  //   }
  // };

  // Get all city names
  const getAllCity = async () => {
    const response = await cityService.getAllPageCity();
    setNewCity(response.data.apiresponse.data);
  };

  // const handleInvoiceCode = (e) => {
  //
  //   const uppercase = e.target.value.toUpperCase();
  //   if (uppercase.length > 7) {
  //     return;
  //   }
  // formik.setFieldValue("einvoiceCode", uppercase);
  // };

  // Add & update fields
  const getBusinessPartner = async () => {
    const response = await businessPartnerService.getBusinessPartner(id);
    setData(response.data.apiresponse.data);

    // set field values for the business partner

    const result = response.data.apiresponse.data;
    setDetails({ name: result.name, id });
    setIsShowStreet(result.showStreet);
    setVatNumber(result.vatNo);
    setNewAddressType(result.addressList.forEach((address) => address.type));
    setTTelephoneNumber(result.technicalTelephone);
    setATelephoneNumber(result.administrativeTelephone);
    setCTelephoneNumber(result.commercialTelephone);
    formik.setFieldValue("id", result.id);
    formik.setFieldValue("commercialTelephone", result.commercialTelephone);
    formik.setFieldValue("commercialSurname", result.commercialSurname);
    formik.setFieldValue("commercialEmail", result.commercialEmail);
    formik.setFieldValue(
      "administrativeTelephone",
      result.administrativeTelephone
    );
    formik.setFieldValue("administrativeSurname", result.administrativeSurname);
    formik.setFieldValue("administrativeEmail", result.administrativeEmail);
    formik.setFieldValue("technicalTelephone", result.technicalTelephone);
    formik.setFieldValue("technicalSurname", result.technicalSurname);
    formik.setFieldValue("technicalEmail", result.technicalEmail);
    formik.setFieldValue("name", result.name);
    formik.setFieldValue("email", result.email);
    formik.setFieldValue("certificateEmail", result.certificateEmail);
    formik.setFieldValue("cig", result.cig);
    formik.setFieldValue("showStreet", result.showStreet);
    formik.setFieldValue("vatNo", result.vatNo);

    // Set city names
    const cityNames = result.cityAssignement.map((res) => res.cityDetail);
    const cityValues = cityNames.map((cityName) => ({
      value: cityName.id,
      label: cityName.name,
    }));
    formik.setFieldValue("cityIds", cityValues);

    // Set contactlist by index
    result.contactList.forEach((contact, index) => {
      formik.setFieldValue(
        `contanctNumbers[${index}].phoneNo`,
        contact.phoneNo
      );
      formik.setFieldValue(
        `contanctNumbers[${index}].countryCode`,
        contact.countryCode
      );
    });
    setAddAddressSlot(result.addressList.length);

    const formatNumber = (number) => {
      const digits = Number(number).toString();
      const areaCode = digits.substring(0, 3);
      const firstPart = digits.substring(3, 10);

      setFaxNumber(`${result.countryCode} ${areaCode} ${firstPart}`);

      if (digits.length !== 10) {
        return "Invalid number";
      }

      formik.setFieldValue(
        "fax",
        `${result.countryCode} ${areaCode} ${firstPart}`
      );
      formik.setFieldValue("countryCode", result.countryCode);

      return `${result.countryCode} ${areaCode} ${firstPart}`;
    };
    formatNumber(result.fax);

    formik.setFieldValue("note", result.note);
    formik.setFieldValue("einvoiceCode", result.einvoiceCode);
    formik.setFieldValue("taxCode", result.taxCode);
    formik.setFieldValue("fax", result.fax);
    formik.setFieldValue("countryCode", result.countryCode);
    formik.setFieldValue("preferredPaymentType", result.preferredPaymentType);
    formik.setFieldValue("holderName", result.holderName);
    formik.setFieldValue("bankIBAN", result.bankIBAN);

    setAddAddressSlot(result.addressList.length);
    setAddNumbers(result.contactList.length);
    result.addressList.forEach((address, index) => {
      setNewAddressType(address.type);
      setNewAddressCity(address.city);
      formik.setFieldValue(`addressList[${index}].type`, address.type);
      formik.setFieldValue(`addressList[${index}].city`, address.city);
      formik.setFieldValue(`addressList[${index}].street`, address.street);
      formik.setFieldValue(`addressList[${index}].state`, address.state);
      formik.setFieldValue(`addressList[${index}].pincode`, address.pincode);
    });
  };

  const AddModal = () => {
    setUpdate(true);
    handleDialogOpen();
  };

  const handleVAT = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    setVatNumber(sanitizedInput);
    formik.setFieldValue("vatNo", sanitizedInput);
  };

  const {
    name,
    email,
    certificateEmail,
    contactList,
    cityAssignement,
    note,
    einvoiceCode,
    fax,
    countryCode,
    addressList,
    taxCode,
    vatNo,
    holderName,
    bankIBAN,
    preferredPaymentType,
    showStreet,
    commercialEmail,
    commercialSurname,
    commercialTelephone,
    technicalEmail,
    technicalSurname,
    technicalTelephone,
    administrativeEmail,
    administrativeSurname,
    administrativeTelephone,
    cig,
  } = data;

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: "uniqueId",
      cell: (row) => (
        <div title={`${row.uniqueId ? row.uniqueId : "--"}`}>
          {row.uniqueId ? row.uniqueId : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: "username",
      cell: (row) => (
        <div title={`${row.username ? row.username : "--"}`}>
          {row.username ? row.username : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("email")}</strong>,
      selector: "email",
      cell: (row) => (
        <div title={`${row.email ? row.email : "--"}`}>
          {row.email ? row.email : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("city")}</strong>,
      selector: "cities",
      cell: (row) => (
        <div title={`${row.cities ? row.cities : "--"}`}>
          {row.cities ? row.cities : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("creationdate")}</strong>,
      selector: "creationDate",
      cell: (row) => (
        <div
          title={`${
            row.creationDate
              ? moment(row.creationDate).format("DD/MM/YYYY")
              : "--"
          }`}
        >
          {row.creationDate
            ? moment(row.creationDate).format("DD/MM/YYYY")
            : "--"}
        </div>
      ),
      sortable: true,
    },
  ];

  // Address schema for validation
  const addressSchema = yup.object().shape({
    type: yup.mixed().required("Type is required"),
    city: yup.mixed().required("City is required"),
    pincode: yup
      .string()
      .max(5, "Maximum 5 numbers!")
      .min(5, "Minimum 5 numbers!")
      .required("Please Enter Pincode!"),

    state: yup.string().required("State is required"),
    street: yup.string().required("Street is required"),
  });

  // Contact schema for validation
  const contactSchema = yup.object().shape({
    countryCode: yup.string().required("Country Code is required"),
    phoneNo: yup.string().required("Phone Number is required"),
  });

  // Initial values for form
  const initialValue = {
    name: "",
    addressList: [
      {
        city: "",
        id: 0,
        pincode: "",
        state: "",
        street: "",
        type: null,
      },
    ],
    administrativeEmail: "",
    administrativeSurname: "",
    administrativeTelephone: "",
    bankIBAN: "",
    certificateEmail: "",
    cityIds: [],
    commercialEmail: "",
    commercialSurname: "",
    commercialTelephone: "",
    contanctNumbers: [
      {
        bpId: 0,
        countryCode: "",
        id: 0,
        phoneNo: "",
      },
    ],
    countryCode: "",
    einvoiceCode: "",
    fax: "",
    holderName: "",
    note: "",
    preferredPaymentType: null,
    taxCode: "",
    technicalEmail: "",
    technicalSurname: "",
    technicalTelephone: "",
    vatNo: "",
    showStreet: null,
    cig: "",
    email: "",
  };

  // Validation for form
  const validationSchema = yup.object().shape({
    commercialSurname: yup.string(),
    commercialEmail: yup.string().email("Please enter Valid Commercial Email"),
    commercialTelephone: yup.string().min(8, "Min 8 digits Requires"),

    technicalSurname: yup.string(),
    technicalEmail: yup.string().email("Please enter Valid Technical Email"),
    technicalTelephone: yup.string().min(8, "Min 8 digits Requires"),

    administrativeSurname: yup.string(),
    administrativeEmail: yup
      .string()
      .email("Please enter Valid Administrative Email"),
    administrativeTelephone: yup.string().min(8, "Min 8 digits Requires"),

    einvoiceCode: yup
      .string()
      .matches(/^[A-Z0-9]+$/, "It contains only UPPERCASE letters and Numbers")
      .max(7, "Must be at most 7 characters"),

    name: yup.string().required("Please enter Name!"),
    vatNo: yup
      .string()
      .matches(/^0[0-9]/, "It must be start with 0.")
      .max(11, "Please ensure that you entered 11 digits or lesser")
      .required("VAT number is required"),
    // taxCode: yup
    //   .string()
    //   .matches(
    //     /^[A-Z]{3}[A-Z]{3}\d{2}[A-Z]{1}\d{2}[A-Z]{1}\d{3}[A-Z]{1}$/,
    //     "Invalid tax code format. Ex: RRSMRA74D22A001Q"
    //   ),

    // fax: yup
    //   .string()
    //   .matches(/^\+?\d{2}\ \d{3}\ \d{2,}$/, "Invalid fax code format"),
    // pincode: yup
    //   .string()
    //   .max(5, "Maximum 5 numbers!")
    //   .min(5, "Minimum 5 numbers!"),
    email: yup.string().email().required(),
    certificateEmail: yup.string().email(),
    contanctNumbers: yup.array().of(contactSchema),
    addressList: yup.array().of(addressSchema),
    cityIds: yup.array().min(1, "Select at least one city"),
  });

  // Submit form and update API call
  const handleSubmit = async (values, action) => {
    const {
      id,
      name,
      email,
      commercialSurname,
      commercialEmail,
      commercialTelephone,
      technicalSurname,
      technicalEmail,
      technicalTelephone,
      administrativeSurname,
      administrativeEmail,
      administrativeTelephone,
      vatNo,
      certificateEmail,
      einvoiceCode,
      contanctNumbers,
      holderName,
      bankIBAN,
      cityIds,
      addressList,
      fax,
      preferredPaymentType,
      taxCode,
      note,
      countryCode,
      showStreet,
      cig,
    } = values;

    const ids = cityIds.map((id) => (id.value ? id.value : id));
    const sanitizedFaxNumber = fax.replace(/[^\d]/g, "").substring(2);

    try {
      const response = await businessPartnerService.updateBusinessPartner(id, {
        name,
        email,
        commercialSurname,
        commercialEmail,
        commercialTelephone,
        technicalSurname,
        technicalEmail,
        technicalTelephone,
        administrativeSurname,
        administrativeEmail,
        administrativeTelephone,
        vatNo,
        certificateEmail,
        einvoiceCode,
        contanctNumbers,
        holderName,
        bankIBAN,
        cityIds: ids,
        addressList,
        fax: sanitizedFaxNumber,
        preferredPaymentType,
        taxCode,
        note,
        countryCode,
        showStreet,
        cig,
      });
      getBusinessPartner();
      // handleDialogClose();

      enqueueSnackbar(t("SUCCESSFULLY_BUSINESSPARTNER_UPDATED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });

      // action.resetForm();
    } catch (error) {
      const errorResponse = error.response.data;

      if (
        errorResponse &&
        errorResponse.apierror &&
        errorResponse.apierror.subErrors
      ) {
        const errorMessages = errorResponse.apierror.subErrors.map(
          (subError) => subError.message
        );

        errorMessages.forEach((errorMessage) => {
          enqueueSnackbar(errorMessage, {
            variant: ERROR,
            anchorOrigin: ANCHORORIGIN,
          });
        });
      } else {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getBusinessPartner();
    formatNumber(fax);
    getAllCity();
    getChildBusinessPartners();
  }, []);

  // Format number
  const formatNumber = (number) => {
    const digits = Number(number).toString();

    if (digits.length !== 10) {
      return "Invalid number";
    }

    const areaCode = digits.substring(0, 3);
    const firstPart = digits.substring(3, 10);

    return `${countryCode} ${areaCode} ${firstPart}`;
  };

  //

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">{data.name} Profile</h2>

        {isDialogOpen && (
          <AddBusinessPartner
            show={isDialogOpen}
            onHide={handleDialogClose}
            formik={formik}
            faxNumber={faxNumber}
            update={update}
            newAddressType={newAddressType}
            newAddressCity={newAddressCity}
            addAddressSlot={addAddressSlot}
            addNumbers={addNumbers}
            handleCloseAddressType={handleCloseAddressType}
            handleOpenAddressType={handleOpenAddressType}
            openAddressType={openAddressType}
            handleCloseCity={handleCloseCity}
            handleOpenCity={handleOpenCity}
            openCity={openCity}
            city={city}
            handleClosePaymentType={handleClosePaymentType}
            handleOpenPaymentType={handleOpenPaymentType}
            openPaymentType={openPaymentType}
            vatNumber={vatNumber}
            handleVAT={handleVAT}
            handleFaxNumberChange={handleFaxNumberChange}
            atelephoneNumber={atelephoneNumber}
            ctelephoneNumber={ctelephoneNumber}
            ttelephoneNumber={ttelephoneNumber}
            handlePhoneNumberChange={handlePhoneNumberChange}
            handleInvoiceCode={handleInvoiceCode}
            handleChangePhone={handleChangePhone}
            removeNumber={removeNumber}
            addNumber={addNumber}
            removeAddress={removeAddress}
            addAddressData={addAddressData}
            handleshowStreet={handleshowStreet}
            isShowStreet={isShowStreet}
            handlePincodeChange={handlePincodeChange}
          />
        )}
        {modalShow && (
          <DeleteBusinessPartner
            show={modalShow}
            onHide={() => setModalShow(false)}
            details={details}
            deleteBusinessPartner={deleteBusinessPartner}
          />
        )}
        <Row className="row row-sm">
          <Col sm={12} lg={12} xl={12}>
            <Card className="custom-card">
              <Card.Body>
                <div className="border-bottom mb-3">
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <div className="ms-auto d-flex justify-content-end">
                        <Link
                          className="btn btn-sm btn-primary me-2"
                          to={`/invoices`}
                          state={{ dealername: name }}
                        >
                          <i className="si si-docs"></i> {t("invoices")}
                        </Link>
                        {/* <button
                          className="btn btn-sm btn-primary"
                          onClick={() => navigate("/invoices")}
                        >
                          <i className="si si-docs"></i> {t("invoices")}
                        </button> */}

                        <button
                          className="btn btn-sm btn-primary ms-2"
                          type="button"
                          onClick={AddModal}
                        >
                          <i className="fe fe-edit"></i> {t("edit")}
                        </button>

                        <button
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => setModalShow(true)}
                        >
                          <i className="fe fe-trash"></i> {t("delete")}
                        </button>
                      </div>
                    </Row>
                  </FormGroup>
                </div>
                {data && (
                  <div className="row">
                    <h5 className="text-dark mb-3">{t("personaldetails")} :</h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("name")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="name"
                            value={name ? name : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="email"
                            value={email ? email : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("certifateemail")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="certificateEmail"
                            value={certificateEmail ? certificateEmail : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("mobilenumber")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            name="phoneNumber"
                            value={
                              contactList
                                ? contactList
                                    ?.map(
                                      (res) =>
                                        "+" +
                                        res.countryCode +
                                        " " +
                                        res.phoneNo
                                    )
                                    .join(", ")
                                : "--"
                            }
                            disabled
                          />
                          {/* )} */}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("city")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="cityAssignement"
                            value={
                              cityAssignement
                                ? cityAssignement
                                    .map((city) => city.cityDetail.name)
                                    .join(", ")
                                : "--"
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("note")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="note"
                            value={note ? note : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("eleinvoicecode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="einvoiceCode"
                            value={einvoiceCode ? einvoiceCode : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("taxcode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="taxCode"
                            value={taxCode ? taxCode : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("fax")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="fax"
                            value={fax ? fax : "--"}
                            disabled
                          />
                        </Col>{" "}
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("vatnumber")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="number"
                            name="vatNo"
                            value={vatNo ? vatNo : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("ccholdername")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="holderName"
                            value={holderName ? holderName : "--"}
                            disabled
                          />
                        </Col>{" "}
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("bankaccountIBAN")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="bankIBAN"
                            value={bankIBAN ? bankIBAN : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {" "}
                            {t("preferredpaymenttype")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="preferredPaymentType"
                            value={
                              preferredPaymentType ? preferredPaymentType : "--"
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {" "}
                            {t("cig")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="cig"
                            value={cig ? cig : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={6} className="d-flex align-items-center">
                          <label className="custom-checkbox d-flex">
                            <span className="tx-16">
                              <Form.Label className="text-dark">
                                {t("doyouwanttoallowshowstreet")} :
                              </Form.Label>
                            </span>
                            <input
                              type="checkbox"
                              className="custom-control-checkbox tx-16"
                              name={`example-checkbox`}
                              value="option1"
                              defaultChecked={showStreet}
                              disabled
                            />
                          </label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <hr className="mt-3" />
                    <>
                      {addressList &&
                        addressList.map((address, index) => (
                          <>
                            <div key={index}>
                              <h5 className="text-dark mb-3 tx-uppercase">
                                {t("address")} {index + 1} :
                              </h5>

                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("type") + " " + t("address")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        addressList[index]?.type
                                          ? addressList[index]?.type
                                          : "--"
                                      }
                                      // onChange={formik.handleChange}
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("province")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        addressList[index]?.state
                                          ? addressList[index]?.state
                                          : "--"
                                      }
                                      // onChange={formik.handleChange}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("streetaddress")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        addressList[index]?.street
                                          ? addressList[index]?.street
                                          : "--"
                                      }
                                      // onChange={formik.handleChange}
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("pincode")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        addressList[index]?.pincode
                                          ? addressList[index]?.pincode
                                          : "--"
                                      }
                                      // onChange={formik.handleChange}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("city")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        addressList[index]?.city
                                          ? addressList[index]?.city
                                          : "--"
                                      }
                                      // onChange={formik.handleChange}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>

                              <hr className="mt-3" />
                            </div>
                          </>
                        ))}
                    </>
                    <h5 className="text-dark mb-3 tx-uppercase">
                      {t("commercial")} :
                    </h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("surname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="commercialSurname"
                            value={commercialSurname ? commercialSurname : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="commercialEmail"
                            value={commercialEmail ? commercialEmail : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("telephone")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="commercialTelephone"
                            value={
                              commercialTelephone ? commercialTelephone : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr className="mt-3" />

                    <h5 className="text-dark mb-3 tx-uppercase">
                      {t("technical")} :
                    </h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("surname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="technicalSurname"
                            value={technicalSurname ? technicalSurname : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="technicalEmail"
                            value={technicalEmail ? technicalEmail : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("telephone")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="technicalTelephone"
                            value={
                              technicalTelephone ? technicalTelephone : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr className="mt-3" />

                    <h5 className="text-dark mb-3 tx-uppercase">
                      {t("administrative")} :
                    </h5>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("surname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="administrativeSurname"
                            value={
                              administrativeSurname
                                ? administrativeSurname
                                : "--"
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("email")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="administrativeEmail"
                            value={
                              administrativeEmail ? administrativeEmail : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("telephone")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            name="administrativeTelephone"
                            value={
                              administrativeTelephone
                                ? administrativeTelephone
                                : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                )}
              </Card.Body>
            </Card>

            <Card className="custom-card">
              <Card.Body>
                <h5 className="text-dark mb-3 tx-uppercase">
                  {t("controlleraccount")}
                </h5>
                <DataTable
                  title="Controller Accounts"
                  columns={columns}
                  data={controllerData}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  striped={true}
                  center={true}
                  persistTableHead
                  pagination
                  paginationServer
                  highlightOnHover
                ></DataTable>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default withLoader(BusinessPartnersProfile);
