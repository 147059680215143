import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import { ANCHORORIGIN, ERROR, SUCCESS } from "../../utils/constants";
import { CHILD_BUSINESS_PARTNER_PROFILE_UPDATE_SUCCESSFULLY } from "../../utils/messages";
import withLoader from "../../layout/Loader/WithLoader";
import UserService from "../../services/user.service";
import { useTranslation } from "react-i18next";

const ChildBusinessPartnerProfile = () => {
  const userService = UserService();
  const { id } = useParams();
  const { t } = useTranslation("common");
  const [data, setData] = useState("");
  const [parentUserName, setParentUserName] = useState("");

  // get child bussines partner data
  const UserDetails = async () => {
    const response = await userService.getUserById(id);
    const result = response.data.apiresponse.data;
    setData(response.data.apiresponse.data);

    formik.setFieldValue("username", result.username);
    formik.setFieldValue("email", result.email);
  };

  // Get business user names
  const getBusinessUser = async () => {
    const response = await userService.getUserById(data?.parentId);
    setParentUserName(response.data.apiresponse.data.username);
  };

  useEffect(() => {
    UserDetails();
  }, []);

  useEffect(() => {
    getBusinessUser();
  }, [data?.parentId]);

  const [update, setUpdate] = useState(true);

  const edit = () => {
    setUpdate(!update);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      surname: "",
      region: "",
    },
    validationSchema: yup.object().shape({
      username: yup.string().required("Please enter Name!"),
    }),
    onSubmit: async (values) => {
      const { username, email } = values;
      try {
        const response = await userService.updateUser(id, {
          username,
          email,
          role: "CHILD_BUSINESS_PARTNER",
        });

        setUpdate(!update);
        enqueueSnackbar(
          t("CHILD_BUSINESS_PARTNER_PROFILE_UPDATE_SUCCESSFULLY"),
          {
            variant: SUCCESS,
            anchorOrigin: ANCHORORIGIN,
          }
        );
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    },
  });

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">
          {data.username} Profile
        </h2>
        <form onSubmit={formik.handleSubmit}>
          {update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="button"
              onClick={edit}
            >
              <i className="fe fe-edit me-1"></i> Edit
            </button>
          )}
          {!update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="submit"
            >
              <i className="fas fa-save me-1"></i> Save
            </button>
          )}
          <Card className="custom-card">
            <Card.Body>
              <div className="row">
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("name")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("email")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("parentbusinessuser")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="parentUserName"
                        value={parentUserName}
                        disabled
                      />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("country")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="country"
                        value={data.country}
                        disabled
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    {" "}
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("uniqueid")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="uniqId"
                        value={data.uniqId}
                        disabled
                      />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("creationdate")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="creationDate"
                        value={moment(data.creationDate).format("DD/MM/YYYY")}
                        disabled
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </Card.Body>
          </Card>
        </form>
      </div>
    </Fragment>
  );
};

export default withLoader(ChildBusinessPartnerProfile);
