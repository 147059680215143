import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import * as yup from "yup";
import {
  ANCHORORIGIN,
  ERROR,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import { ScrollToTop } from "../../utils/ScrollTop";
import BusinessPartnerService from "../../services/businessPartner.service";
import withLoader from "../../layout/Loader/WithLoader";
import AreaService from "../../services/area.service";
import CityService from "../../services/city.service";
import useMenuState from "../../hooks/useMenuState";
import DeleteArea from "./DeleteArea";
import AddArea from "./AddArea";
import useDialogState from "../../hooks/useDialog";

const AreaProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const areaService = AreaService();
  const cityService = CityService();
  const [businessPartners, setBusinessPartners] = useState([]);
  const businessPartnerService = BusinessPartnerService();
  const [modalShow, setModalShow] = useState(false);
  const [update, setUpdate] = useState(true);
  const [newCity, setNewCity] = useState([]);
  const [cashbackInAmount, setCashbackInAmount] = useState(true);
  const [details, setDetails] = useState({});
  const [data, setData] = useState("");
  const [updateCIty, setUpdateCIty] = useState(false);
  const [latLongCount, setLatLongCount] = useState(1);
  const [freeTime, setFreeTime] = useState("");
  const [localGeolocations, setLocalGeolocations] = useState(
    Array(latLongCount).fill("")
  );
  const [existingData, setExistingData] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [errors, setErrors] = useState({});

  let timeout = null;
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  // Usemenustate for default value select in dropdown
  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(false);
  const [openManageBy, handleOpneManageBy, handleCloseManageBy] =
    useMenuState(false);

  const getAllCity = async () => {
    const response = await cityService.getAllPageCity();
    setNewCity(response.data.apiresponse.data);
  };
  const getAllBusinessPartner = async () => {
    const response = await businessPartnerService.getAllBusinessPartner();
    setBusinessPartners(response.data.apiresponse.data);
  };

  // Get Area Details
  const getAreaById = async () => {
    const response = await areaService.getAreaById(id);
    setData(response.data.apiresponse.data);

    const result = response.data.apiresponse.data;

    setDetails({ name: result.name, id });
    // SetFieldvalue when we click on edit
    formik.setFieldValue("id", result.id);
    formik.setFieldValue("name", result.name);
    formik.setFieldValue("userId", result.userDetails.id);
    formik.setFieldValue("pincode", result.pincode);
    formik.setFieldValue("cityId", result.cityDetails.id);
    formik.setFieldValue("serviceFee", result.serviceFee);
    formik.setFieldValue("minServiceFee", result.minServiceFee);
    formik.setFieldValue("easyParkCode", result.easyParkCode);
    formik.setFieldValue("cashbackAmount", result.cashbackAmount);
    formik.setFieldValue("cashbackAmountInPr", result.cashbackAmountInPr);
    formik.setFieldValue("freeTime", result.freeTime);
    formik.setFieldValue("minCost", result.minCost);
    setFreeTime(result.freeTime / (60 * 1000));

    // if (result.geolocations.length > 0) {
    //   setLatLongCount(result.geolocations.length);
    //   result.geolocations.forEach((latLong, index) => {
    //     formik.setFieldValue(
    //       `geolocations[${index}].latitude`,
    //       latLong.latitude !== 0 ? latLong.latitude : ""
    //     );
    //     formik.setFieldValue(
    //       `geolocations[${index}].longitude`,
    //       latLong.longitude !== 0 ? latLong.longitude : ""
    //     );
    //   });
    // }

    setExistingData(result.geolocations);

    if (result.geolocations.length > 0) {
      const groups = result.geolocations.reduce(
        (acc, { latitude, longitude, groupIndex }) => {
          if (!acc[groupIndex]) {
            acc[groupIndex] = [];
          }
          acc[groupIndex].push(`${longitude},${latitude}`);
          return acc;
        },
        {}
      );

      const locationData = Object.values(groups).map((group) =>
        group.join("\n")
      );

      setLocalGeolocations(locationData);
      formik.setFieldValue(`geolocations`, locationData);
      setLatLongCount(locationData.length);
    }
    ScrollToTop();
  };

  const changeCashback = (e) => {
    const value = e.target.value;
    if (value === "$") {
      setCashbackInAmount(true);
    } else {
      setCashbackInAmount(false);
    }
  };

  // Seelect Business user for manage area
  const selectBusinessUser = async (selectedOption) => {
    const id = selectedOption.value;
    formik.setFieldValue("userId", id);
    const response = await cityService.getCityByUserId(id);
    const city = response.data.apiresponse.data;
    setNewCity(city);
  };

  const selectCity = async (selectedOption) => {
    setUpdateCIty(false);
    formik.setFieldValue("cityId", selectedOption.value);
    const response = await businessPartnerService.getBusinessPartnerByCityId(
      selectedOption.value
    );
    setBusinessPartners(response.data.apiresponse.data);
  };

  // City list
  const city = [
    ...newCity.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  ];

  // Open Model for update area
  const AddModal = () => {
    setUpdate(true);
    setUpdateCIty(true);
    handleDialogOpen();
  };

  // Destructure Area Data
  const {
    name,
    pincode,
    serviceFee,
    minServiceFee,
    easyParkCode,
    cityDetails,
    userDetails,
    geolocations,
    streetName,
    cashbackAmount,
    cashbackAmountInPr,
    minCost,
  } = data;

  const groupedGeolocations = geolocations?.reduce((acc, location) => {
    const { groupIndex } = location;
    if (!acc[groupIndex]) {
      acc[groupIndex] = [];
    }
    acc[groupIndex].push(location);
    return acc;
  }, {});

  const initialValues = {
    name: "",
    userId: null,
    cityId: null,
    pincode: "",
    serviceFee: "",
    minServiceFee: "",
    easyParkCode: "",
    cashbackAmount: 0,
    cashbackAmountInPr: 0,
    freeTime: 0,
    minCost: 0,
    geolocations: [
      {
        latitude: "",
        longitude: "",
      },
    ],
  };

  const geolocationsSchema = yup.object().shape({
    latitude: yup
      .string()
      .matches(
        /^(\d{0,2}(\.\d*)?)?$/,
        "Invalide Latitude! Max 2 Letters Allowed in Decimal Number"
      ),
    longitude: yup
      .string()
      .matches(
        /^(\d{0,2}(\.\d*)?)?$/,
        "Invalide Longitude! Max 2 Letters Allowed in Decimal Number"
      ),
  });

  // Formik Validation
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is Required"),
    cityId: yup.string().required("City is required!"),
    pincode: yup
      .string()
      .max(5, "Maximum 5 numbers!")
      .min(5, "Minimum 5 numbers!"),
    // geolocations: yup.array().of(geolocationsSchema),
    userId: yup.string().required("Business Partner is required!"),
    minServiceFee: yup.number().required("Minimum service Fee is required!"),
  });

  // Formik Submit form
  const handleSubmitForm = async (values, action) => {
    const {
      id,
      cityId,
      easyParkCode,
      minServiceFee,
      name,
      pincode,
      serviceFee,
      userId,
      geolocations,
      cashbackAmount,
      cashbackAmountInPr,
      freeTime,
      minCost,
    } = values;

    setIsSubmited(true);

    if (existingData.length < 1) {
      return;
    }
    const flatLocations = existingData?.flat();

    const isValid = flatLocations.every(
      (location) => location.latitude && location.longitude
    );

    if (!isValid) {
      return;
    }

    const service = Number(serviceFee);
    try {
      const response = await areaService.updateArea({
        id,
        cityId,
        easyParkCode,
        minServiceFee,
        name,
        pincode,
        serviceFee: service,
        userId,
        geolocations: flatLocations,
        cashbackAmount,
        cashbackAmountInPr,
        freeTime,
        minCost,
      });

      enqueueSnackbar(t("SUCCESSFULLY_AREA_UPDATED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      getAreaById();
    } catch (e) {
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const handleMinimumCost = async (event) => {
    let inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    formik.setFieldValue(`minCost`, numericValue);
  };

  const handleFreeTime = (e) => {
    let inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");

    setFreeTime(numericValue);
    formik.setFieldValue(`freeTime`, numericValue * 60 * 1000);
  };

  let partners = [];
  if (!updateCIty) {
    if (!formik.values.cityId) {
      partners = [
        ...businessPartners.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    } else {
      partners = [
        ...businessPartners.map((item) => ({
          value: item.id,
          label: item.username,
        })),
      ];
    }
  } else {
    partners = [
      ...businessPartners.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    ];
  }

  const handlePincodeChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");

    formik.setFieldValue("pincode", sanitizedInput);
  };

  const handleserviceFee = (e) => {
    const value = e.target.value;
    if (timeout) {
      clearTimeout(timeout);
    }
    formatInput(value, e);
  };

  const formatInput = (value, e) => {
    if (value === "") {
      formik.setFieldValue("serviceFee", "");
      return;
    }

    const newValue = String(value);
    let formattedValue = "";

    // Check if the input is a valid number or an empty string
    if (!isNaN(newValue)) {
      const [integerPart, decimalPart] = newValue.split(".");
      const parsedInteger = parseFloat(integerPart);

      // Check if the parsed integer is a valid number
      if (!isNaN(parsedInteger)) {
        // Ensure there's always two leading zeros for the integer part

        let formattedInteger;

        if (e.target.value != 0) {
          formattedInteger =
            parsedInteger < 10
              ? `0${parsedInteger?.toFixed(0)}`
              : parsedInteger < 100
              ? parsedInteger?.toFixed(0)
              : 0;
        } else {
          formattedInteger = "";
        }

        // Add a decimal point after two digits and allow only two digits after the decimal point
        if (decimalPart !== undefined) {
          const formattedDecimal = decimalPart.slice(0, 2);
          formattedValue = `${formattedInteger}.${formattedDecimal}`;
        } else {
          formattedValue = `${formattedInteger}`;
        }
      }
    }

    formik.setFieldValue("serviceFee", formattedValue);
  };

  // Delete are API Call
  const deleteArea = async () => {
    try {
      const response = await areaService.deleteArea(id);
      enqueueSnackbar(t("SUCCESSFULLY_AREA_DELETED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      navigate("/area/");
      setModalShow(false);
    } catch (e) {
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const handleLocalChange = (e, index) => {
    setIsSubmited(false);
    const newLocalGeolocations = [...localGeolocations];
    newLocalGeolocations[index] = e.target.value;
    setLocalGeolocations(newLocalGeolocations);
  };

  const handleLocationBlur = (e, index) => {
    setIsSubmited(false);

    const input = e.target.value;
    const lines = input.split("\n");

    const bodyList = lines.map((line) => {
      const [longitude, latitude] = line.split(",");
      return {
        groupIndex: index,
        longitude: longitude ? longitude.trim() : "",
        latitude: latitude ? latitude.trim() : "",
      };
    });

    setExistingData((prev) => {
      const updatedData = prev.filter((item) => item.groupIndex !== index);
      return [...updatedData, ...bodyList];
    });

    formik.setFieldValue(`geolocations[${index}]`, bodyList);

    const errors = {};
    bodyList.forEach((item) => {
      if (!item.latitude && !item.longitude) {
        errors[index] = "Please enter latitude and longitude";
      } else if (!item.latitude) {
        errors[index] = "Please enter latitude";
      } else if (!item.longitude) {
        errors[index] = "Please enter longitude";
      } else {
        delete errors[index];
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  useEffect(() => {
    const initialErrors = existingData.reduce((acc, data, index) => {
      if (data.groupIndex !== undefined) {
        acc[data.groupIndex] = getErrorMessage(index);
      }
      return acc;
    }, {});
    setErrors(initialErrors);
  }, [existingData]);

  const getErrorMessage = (index) => {
    const data = existingData[index] || {};
    if (!data.latitude && !data.longitude) {
      return "Please enter latitude and longitude";
    }
    if (!data.latitude) {
      return "Please enter latitude";
    }
    if (!data.longitude) {
      return "Please enter longitude";
    }
    return "";
  };

  useEffect(() => {
    getAreaById();
    getAllBusinessPartner();
    getAllCity();
  }, []);

  const addLatLong = () => {
    const newGeolocation = {
      groupIndex: latLongCount,
      longitude: "",
      latitude: "",
    };

    // Update Formik's geolocations
    formik.setFieldValue("geolocations", [
      ...formik.values.geolocations,
      newGeolocation,
    ]);

    // Update existingData
    setExistingData([...existingData, newGeolocation]);

    // Increment the count
    setLatLongCount(latLongCount + 1);
  };

  const removeLatLong = (index) => {
    if (latLongCount > 1) {
      const newGeolocations = formik.values.geolocations.filter(
        (item) => item.groupIndex !== index
      );
      const newLocalGeolocations = localGeolocations.filter(
        (_, i) => i !== index
      );
      const newExistingData = existingData.filter(
        (data) => data.groupIndex !== index
      );

      const reindexedGeolocations = newGeolocations.map((item) => {
        if (item.groupIndex > index) {
          return { ...item, groupIndex: item.groupIndex - 1 };
        }
        return item;
      });

      const reindexedExistingData = newExistingData.map((item) => {
        if (item.groupIndex > index) {
          return { ...item, groupIndex: item.groupIndex - 1 };
        }
        return item;
      });

      formik.setFieldValue("geolocations", reindexedGeolocations);
      setLocalGeolocations(newLocalGeolocations);
      setExistingData(reindexedExistingData);

      setLatLongCount(latLongCount - 1);
    }
  };

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">{t("areaprofile")}</h2>
        {isDialogOpen && (
          <AddArea
            show={isDialogOpen}
            onHide={handleDialogClose}
            formik={formik}
            isDialogOpen={isDialogOpen}
            city={city}
            partners={partners}
            update={update}
            handleCloseManageBy={handleCloseManageBy}
            handleOpneManageBy={handleOpneManageBy}
            openManageBy={openManageBy}
            handleCloseCity={handleCloseCity}
            handleOpenCity={handleOpenCity}
            openCity={openCity}
            selectBusinessUser={selectBusinessUser}
            handleserviceFee={handleserviceFee}
            handlePincodeChange={handlePincodeChange}
            cashbackInAmount={cashbackInAmount}
            changeCashback={changeCashback}
            selectCity={selectCity}
            latLongCount={latLongCount}
            addLatLong={addLatLong}
            removeLatLong={removeLatLong}
            handleFreeTime={handleFreeTime}
            handleMinimumCost={handleMinimumCost}
            freeTime={freeTime}
            handleLocalChange={handleLocalChange}
            handleLocationBlur={handleLocationBlur}
            localGeolocations={localGeolocations}
            existingData={existingData}
            isSubmited={isSubmited}
            setIsSubmited={setIsSubmited}
            errors={errors}
          />
        )}
        {modalShow && (
          <DeleteArea
            show={modalShow}
            onHide={() => setModalShow(false)}
            details={details}
            deleteArea={deleteArea}
          />
        )}
        <Row className="row row-sm">
          <Col sm={12} lg={12} xl={12}>
            <Card className="custom-card">
              <Card.Body>
                {" "}
                <div className="border-bottom mb-3">
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <div className="ms-auto d-flex justify-content-end">
                        <button
                          className="btn btn-sm btn-primary ms-2"
                          type="button"
                          onClick={AddModal}
                        >
                          <i className="fe fe-edit"></i> {t("edit")}
                        </button>

                        <button
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => setModalShow(true)}
                        >
                          <i className="fe fe-trash"></i> {t("delete")}
                        </button>
                      </div>
                    </Row>
                  </FormGroup>
                </div>
                <div>
                  <div className="row">
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("uniqueid")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={userDetails?.uniqId} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("name")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={name} disabled />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("manageby")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={userDetails?.username}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("city")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={cityDetails?.name} disabled />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("province")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={cityDetails?.state} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("pincode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={pincode} disabled />
                        </Col>
                      </Row>
                    </FormGroup>

                    {/* {geolocations?.map((item, index) => {
                      return (
                        <FormGroup key={index} className="form-group">
                          {index === 0 && <hr className="mt-2 mb-4" />}
                          <h6
                            className="text-dark mb-3 tx-uppercase"
                            style={{ fontSize: "14px" }}
                          >
                            {t("location")} {index + 1} :
                          </h6>
                          <Row className=" row-sm">
                            <Col md={2} className="d-flex align-items-center">
                              <Form.Label className="text-dark mb-0">
                                {t("coordinates")} :
                              </Form.Label>
                            </Col>
                            <Col md={4}>
                              <Form.Control value={item.latitude} disabled />
                            </Col>

                            <Col md={2} className="d-flex align-items-center">
                              <Form.Label className="text-dark mb-0">
                                {t("longitude")} :
                              </Form.Label>
                            </Col>
                            <Col md={4}>
                              <Form.Control value={item.longitude} disabled />
                            </Col>
                          </Row>

                          <hr className="mt-4" />
                        </FormGroup>
                      );
                    })} */}

                    <hr className="mt-2 mb-4" />
                    <h6 className="text-dark mb-2" style={{ fontSize: "14px" }}>
                      {t("locations")} :
                    </h6>
                    <FormGroup className="form-group">
                      <Row className="">
                        {groupedGeolocations &&
                          Object.entries(groupedGeolocations).map(
                            ([groupIndex, locations], idx) => (
                              // <FormGroup key={groupIndex} className="form-group">
                              <>
                                <Col
                                  md={2}
                                  className="d-flex align-items-center mb-4"
                                >
                                  <Form.Label className="text-dark mb-0">
                                    {t("coordinates") + " " + (Number(idx) + 1)}{" "}
                                    :
                                  </Form.Label>
                                </Col>
                                <Col md={4} className="mb-4">
                                  <Form.Control
                                    as="textarea"
                                    value={locations
                                      .map(
                                        (loc) =>
                                          `${loc.longitude}, ${loc.latitude}`
                                      )
                                      .join("\n")}
                                    rows={10}
                                    disabled
                                  />
                                </Col>

                                {groupIndex % 2 === 1 && (
                                  <div className="border-bottom mb-4"></div>
                                )}
                                {/* </FormGroup> */}
                              </>
                            )
                          )}
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("streetaddress")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as={TextArea}
                            rows={1}
                            value={streetName ? streetName : "--"}
                            disabled
                            style={{ color: "#1D212F" }}
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("minimimservicefee(amount)")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              minServiceFee
                                ? `€${ReplaceFunction(
                                    minServiceFee?.toFixed(2)
                                  )}`
                                : minServiceFee
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("servidefee(percentage)")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              serviceFee
                                ? `€${ReplaceFunction(serviceFee?.toFixed(2))}`
                                : serviceFee
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("easyparkcode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={easyParkCode} disabled />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("cashbackamount")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              cashbackAmount
                                ? `€${ReplaceFunction(
                                    cashbackAmount?.toFixed(2)
                                  )}`
                                : cashbackAmount
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("cashbacamountinpr")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={cashbackAmountInPr + "%"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("creationdate")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={moment(cityDetails?.creationDate).format(
                              "DD/MM/YYYY"
                            )}
                            disabled
                          />
                        </Col>

                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("minimumcost")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={minCost ? `${minCost}` : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("initialfreetime(minutes)")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              data.freeTime ? data.freeTime / (60 * 1000) : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default withLoader(AreaProfile);
