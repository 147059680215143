import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import withLoader from "../../layout/Loader/WithLoader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PaymentService from "../../services/payment.service";
import { CUSTOMDROPDOWNSTYLE, PAGELIMIT } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import Multiselect from "react-select";
import TransactionService from "../../services/transaction.service";
import Enums from "../../utils/enums";

const UserRechargesToAdmin = () => {
  const { RANGES } = Enums();
  const paymentService = PaymentService();
  const transactionService = TransactionService();
  const { t } = useTranslation("common");
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [searchText, setSearchText] = useState("");
  const [filterRole, setFilterRole] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "months").format("MM/DD/YYYY")
    // moment(new Date()).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState("");

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlesearch = (e) => {
    setSearchText(e.target.value);
  };

  const getRefills = async () => {
    const response = await transactionService.getDealerRefills({
      endDate: new Date(endDate).getTime(),
      needDealerList: false,
      pageLimit: dataPerPage,
      pageNumber: pageNumber,
      searchString: searchText,
      startDate: new Date(startDate).getTime(),
      status: null,
      userId: 0,
    });
    setData(response.data.apiresponse.data.dealerRefills);
    setTotalRows(response.data.apiresponse.data.totalRecords);
  };
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  useEffect(() => {
    getRefills();
  }, []);

  useEffect(() => {
    getRefills();
  }, [pageNumber, startDate, endDate, dataPerPage, searchText]);

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: "uniqId",
      cell: (row) => <div title={`${row.uniqId}`}>{row.uniqId}</div>,
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("name")}</strong>,
      selector: "username",
      cell: (row) => <div title={`${row.username}`}>{row.username}</div>,
      sortable: true,
    },
    // {
    //   name: <strong className="tx-uppercase">{t("grossamount")}</strong>,
    //   selector: "grossAmount",
    //   cell: (row) => <div title={`${row.grossAmount}`}>{row.grossAmount}</div>,
    //   sortable: true,
    // },
    // {
    //   name: <strong className="tx-uppercase">{t("netamount")}</strong>,
    //   selector: "netAmount",
    //   cell: (row) => (
    //     <div title={`${row.netAmount ? row.netAmount : "--"}`}>
    //       {row.netAmount ? row.netAmount : "--"}
    //     </div>
    //   ),
    //   sortable: true,
    // },
    // {
    //   name: <strong className="tx-uppercase">{t("paypalfee")}</strong>,
    //   selector: "paypalFee",
    //   cell: (row) => (
    //     <div title={`${row.paypalFee ? row.paypalFee : "--"}`}>
    //       {row.paypalFee ? row.paypalFee : "--"}
    //     </div>
    //   ),
    //   sortable: true,
    // },
    {
      name: (
        <strong className="tx-uppercase">{t("importbeforerecharge")}</strong>
      ),
      selector: "beforeWalletAmount",
      cell: (row) => (
        <div
          title={`${
            row.beforeWalletAmount ? `€${row.beforeWalletAmount}` : "--"
          }`}
        >
          {row.beforeWalletAmount ? `€${row.beforeWalletAmount}` : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("importrecharge")}</strong>,
      selector: "grossAmount",
      cell: (row) => (
        <div
          title={`${
            row.grossAmount ? `€${row.grossAmount?.toFixed(2)}` : "--"
          }`}
        >
          {row.grossAmount ? `€${row.grossAmount?.toFixed(2)}` : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("importafterrecharge")}</strong>
      ),
      selector: "afterWalletAmount",
      cell: (row) => (
        <div
          title={`${
            row.afterWalletAmount ? `€${row.afterWalletAmount}` : "--"
          }`}
        >
          {row.afterWalletAmount ? `€${row.afterWalletAmount}` : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("paydate")}</strong>,
      selector: "payDate",
      cell: (row) => (
        <div
          title={`${
            row.payDate
              ? moment(row.payDate).format("DD/MM/YYYY HH:mm:ss")
              : moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
          }`}
        >
          {row.payDate
            ? moment(row.payDate).format("DD/MM/YYYY HH:mm:ss")
            : moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("transactionstatus")}</strong>,
      selector: "transactionStatus",
      cell: (row) => (
        <div className="d-flex">
          <div className="">
            {row.transactionStatus === "IN_PROGRESS" && (
              <div className="text-warning">
                <i class="fa fa-hourglass"></i> {t("inprogress")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "COMPLETED" && (
              <div className="text-success">
                <i className="fa fa-check"></i> {t("completed")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "CANCEL" && (
              <div className="text-danger">
                <i className="fa fa-close"></i> {t("cancel")}
              </div>
            )}
          </div>
        </div>
      ),
      sortable: true,
    },
  ];

  const role = [
    { value: "BUSINESS_USER", label: t("businessuser") },
    { value: "USER", label: t("user") },
  ];

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("userrechargesfromapp")}
      </h2>

      <Card>
        <div className="p-3">
          <Row>
            <Col md={2}>
              <Form.Group className="form-group">
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date())
                      .subtract(1, "months")
                      .format("MM/DD/YYYY"),
                    endDate: moment(new Date()).format("MM/DD/YYYY"),
                    ranges: RANGES,
                  }}
                  onApply={setDates}
                >
                  <input
                    type="text"
                    value={
                      moment(startDate).format("DD/MM/YYYY") +
                      " - " +
                      moment(endDate).format("DD/MM/YYYY")
                    }
                    className="form-control"
                  />
                </DateRangePicker>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="form-group">
                <Multiselect
                  classNamePrefix="Select2"
                  options={role.map((option) => ({
                    value: option.value,
                    label: t(option.label),
                  }))}
                  singleSelect
                  placeholder={t("role")}
                  displayValue="key"
                  name="role"
                  onChange={(selectedOption) =>
                    setFilterRole(selectedOption.value)
                  }
                  styles={CUSTOMDROPDOWNSTYLE}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={8} lg={8} xl={8}>
              <div className="d-flex d-lg-flex add-search ms-auto">
                <div className="d-flex flex-grow-1 flex-md-grow-0 flex-lg-grow-0 align-items-center md-form mt-2 mt-md-auto mt-lg-auto ms-auto">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-md py-3"
                      value={searchText}
                      onChange={handlesearch}
                      placeholder={t("search") + "..."}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <DataTable
            title="User Recharge To Admin"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(UserRechargesToAdmin);
