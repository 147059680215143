import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Multiselect from "react-select";
import { CUSTOMDROPDOWNSTYLE, FORMIKERROR } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const AddArea = (props) => {
  const { t } = useTranslation("common");
  const {
    formik,
    city,
    partners,
    update,
    handleCloseManageBy,
    handleOpneManageBy,
    openManageBy,
    openCity,
    handleOpenCity,
    handleCloseCity,
    selectBusinessUser,
    handleserviceFee,
    handlePincodeChange,
    changeCashback,
    cashbackInAmount,
    selectCity,
    latLongCount,
    addLatLong,
    removeLatLong,
    handleFreeTime,
    handleMinimumCost,
    freeTime,
    handleLocalChange,
    localGeolocations,
    handleLocationBlur,
    isError,
    isSubmited,
    setIsSubmited,
    existingData,
    errors,
  } = props;

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {update ? t("update") + " " + t("area") : t("add") + " " + t("area")}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsSubmited(true);
            formik.handleSubmit();
          }}
        >
          <Row className="row-sm">
            <Col lg={6}>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">{t("manageby")} :</Form.Label>
                <Multiselect
                  classNamePrefix="Select2"
                  options={partners}
                  singleSelect
                  placeholder={t("manageby")}
                  displayValue="key"
                  name="userId"
                  menuIsOpen={openManageBy}
                  onMenuOpen={handleOpneManageBy}
                  onMenuClose={handleCloseManageBy}
                  value={partners.find(
                    (option) => option.value === formik.values.userId
                  )}
                  onChange={selectBusinessUser}
                  styles={CUSTOMDROPDOWNSTYLE}
                />
                {formik.errors.userId && formik.touched.userId ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.userId}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">{t("city")} :</Form.Label>
                <Multiselect
                  classNamePrefix="Select2"
                  options={city}
                  singleSelect
                  placeholder={t("city")}
                  displayValue="key"
                  name="cityId"
                  menuIsOpen={openCity}
                  onMenuOpen={handleOpenCity}
                  onMenuClose={handleCloseCity}
                  value={city.find(
                    (option) => option.value === formik.values.cityId
                  )}
                  onChange={selectCity}
                  styles={CUSTOMDROPDOWNSTYLE}
                />
                {formik.errors.cityId && formik.touched.cityId ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.cityId}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">{t("name")} :</Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  name="name"
                  placeholder={t("name")}
                  style={{ borderColor: "#D3D3DE" }}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.name}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <div
                style={{
                  overflow: "auto",
                  paddingTop: "15px",
                  marginBottom: "15px",
                  maxHeight: "300px",
                  border: "0.5px solid #efefef",
                  padding: "10px 10px 0px 10px",
                }}
              >
                {[...Array(latLongCount)].map((_, index) => {
                  const groupIndex = existingData[index]?.groupIndex;

                  // Handle errors
                  // const showError =
                  //   isSubmited || (localGeolocations[index] && hasError(index));

                  return (
                    <>
                      <div className="d-flex">
                        <Form.Label className="text-black">
                          {t("location")}. {index + 1}
                        </Form.Label>
                        <div className="ms-auto mb-1">
                          {index === latLongCount - 1 ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm ms-2"
                                onClick={addLatLong}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger ms-2"
                                  onClick={() => removeLatLong(index)}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              )}
                            </>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-sm btn-danger ms-2"
                              onClick={() => removeLatLong(index)}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <Form.Group
                          className="form-group"
                          style={{ width: "-webkit-fill-available" }}
                        >
                          <Form.Label className="text-dark">
                            {t("coordinates")} :
                          </Form.Label>
                          <Form.Control
                            type="text"
                            rows={10}
                            as="textarea"
                            value={localGeolocations[index]}
                            onChange={(e) => handleLocalChange(e, index)}
                            onBlur={(e) => handleLocationBlur(e, index)}
                            name={`geolocations[${index}]`}
                            placeholder={t("longitude") + "," + t("latitude")}
                            style={{ borderColor: "#D3D3DE" }}
                          />
                          {isSubmited && existingData.length < 1 && (
                            <Form.Label style={FORMIKERROR}>
                              Please add atleast one latitude and longitude
                            </Form.Label>
                          )}
                          {isSubmited && errors[index] && (
                            <Form.Label style={FORMIKERROR}>
                              {errors[index]}
                            </Form.Label>
                          )}
                        </Form.Group>
                      </div>
                    </>
                  );
                })}
              </div>
            </Col>

            <Col lg={6}>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">{t("pincode")} :</Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.pincode}
                  // onChange={formik.handleChange}
                  name="pincode"
                  placeholder={t("pincode")}
                  onChange={(event) => handlePincodeChange(event)}
                  style={{ borderColor: "#D3D3DE" }}
                />
                {formik.errors.pincode && formik.touched.pincode ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.pincode}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("servidefee(percentage)")} :
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.serviceFee}
                  onChange={handleserviceFee}
                  name="serviceFee"
                  placeholder={t("servidefee(percentage)") + " Ex. 00.00"}
                  style={{ borderColor: "#D3D3DE" }}
                />
                {formik.errors.serviceFee && formik.touched.serviceFee ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.serviceFee}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("minimimservicefee(amount)")} :
                </Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.minServiceFee}
                  onChange={formik.handleChange}
                  name="minServiceFee"
                  placeholder={t("minimimservicefee(amount)")}
                  style={{ borderColor: "#D3D3DE" }}
                />
                {formik.errors.minServiceFee && formik.touched.minServiceFee ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.minServiceFee}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("easyparkcode")} :
                </Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.easyParkCode}
                  onChange={formik.handleChange}
                  name="easyParkCode"
                  placeholder={t("easyparkcode")}
                  style={{ borderColor: "#D3D3DE" }}
                />
                {formik.errors.easyParkCode && formik.touched.easyParkCode ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.easyParkCode}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {cashbackInAmount
                    ? t("cashbackamount")
                    : t("cashbacamountinpr")}{" "}
                  :
                </Form.Label>
                <div class="">
                  <div class="input-group">
                    <Form.Control
                      type="number"
                      value={
                        cashbackInAmount
                          ? formik.values.cashbackAmount
                          : formik.values.cashbackAmountInPr
                      }
                      onChange={formik.handleChange}
                      name={
                        cashbackInAmount
                          ? "cashbackAmount"
                          : "cashbackAmountInPr"
                      }
                      placeholder={
                        cashbackInAmount
                          ? t("cashbackamount")
                          : t("cashbacamountinpr")
                      }
                      style={{ borderColor: "#D3D3DE" }}
                    />
                    <span class="input-group-btn">
                      <select class="form-control" onChange={changeCashback}>
                        <option value="$">$</option>
                        <option value="%">%</option>
                      </select>
                    </span>
                  </div>
                </div>
                {(formik.errors.cashbackAmount ||
                  formik.errors.cashbackAmountInPr) &&
                (formik.touched.cashbackAmount ||
                  formik.touched.cashbackAmountInPr) ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.cashbackAmount}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("initialfreetime(minutes)")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name={`freeTime`}
                  value={freeTime}
                  onChange={(e) => handleFreeTime(e)}
                  placeholder={t("initialfreetime(minutes)")}
                  style={{ borderColor: "#D3D3DE" }}
                />
                {formik.errors.freeTime && formik.touched.freeTime ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.freeTime}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("minimumcost")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name={`minCost`}
                  value={formik.values.minCost}
                  onChange={(data) => handleMinimumCost(data)}
                  placeholder={t("minimumcost")}
                  style={{ borderColor: "#D3D3DE" }}
                />
                {formik.errors.minCost && formik.touched?.minCost ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.minCost}
                  </Form.Label>
                ) : null}
              </Form.Group>
            </Col>

            <Form.Group>
              <Button
                type="submit"
                className="btn ripple btn-primary btn-block"
              >
                {update ? t("update") : t("add")}
              </Button>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddArea;
