import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import { ANCHORORIGIN, FORMIKERROR, SUCCESS } from "../../utils/constants";
import withLoader from "../../layout/Loader/WithLoader";
import UserService from "../../services/user.service";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import STATE from "../../utils/city.json";
import Select from "react-select";
import useMenuState from "../../hooks/useMenuState";

const AdminProfile = () => {
  const maxNumberSize = 15;
  const userService = UserService();
  const { t } = useTranslation("common");
  const { id } = useParams();

  const [data, setData] = useState("");
  const [visibleOptions, setVisibleOptions] = useState(10);
  const [search, setSearch] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const NewState = [
    ...new Set(STATE.map((data) => data.STATE + " (" + data.PROVINCE + ")")),
  ];
  const [stateOptions, setStateOptions] = useState(
    NewState.map((state) => ({
      label: state,
      value: state.match(/\((.*?)\)/)[1],
    }))
  );
  const [cityOptions, setCityOptions] = useState(
    STATE.map((state) => ({
      label: state.CITY,
      value: state.CITY,
    }))
  );
  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(false);
  const [openState, handleOpenState, handleCloseState] = useMenuState(false);

  // Get Admin details
  const UserDetails = async () => {
    const response = await userService.getUserById(id);
    const result = response.data.apiresponse.data;
    setData(response.data.apiresponse.data);

    formik.setFieldValue("username", result.username);
    formik.setFieldValue("email", result.email);
    formik.setFieldValue("surname", result.surname);
    formik.setFieldValue("region", result.region);
    formik.setFieldValue("bankName", result.bankName);
    formik.setFieldValue("address", result.address);
    formik.setFieldValue("cap", result.cap);
    formik.setFieldValue("cin", result.cin);
    formik.setFieldValue("phoneNo", result.phoneNo);
    formik.setFieldValue("province", result.province);
    formik.setFieldValue("supportedBank", result.supportedBank);
    formik.setFieldValue("taxCode", result.taxCode);
    formik.setFieldValue("town", result.town);
    formik.setFieldValue("vatNumber", result.vat);
    formik.setFieldValue("iban", result.iban);
    formik.setFieldValue("fax", result.fax);
    formik.setFieldValue("cc", result.cc);
    const formatNumber = (number) => {
      const digits = Number(number).toString();

      const areaCode = digits.substring(0, 3);
      const firstPart = digits.substring(3, 10);

      setFaxNumber(`${result.countryCode} ${areaCode} ${firstPart}`);

      if (digits.length !== 10) {
        return "Invalid number";
      }

      formik.setFieldValue(
        "fax",
        `${result.countryCode} ${areaCode} ${firstPart}`
      );
      formik.setFieldValue("countryCode", result.countryCode);

      return `${result.countryCode} ${areaCode} ${firstPart}`;
    };
    formatNumber(result.fax);
  };

  const handleFaxNumberChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");
    if (sanitizedInput.length > maxNumberSize) {
      return;
    }

    let formattedNumber = "";
    let countryCode = sanitizedInput.slice(0, 2);
    for (let i = 0; i < sanitizedInput.length; i++) {
      if (i === 2 || i === 5) {
        formattedNumber += ` ${sanitizedInput.charAt(i)}`;
      } else {
        formattedNumber += sanitizedInput.charAt(i);
      }
    }

    setFaxNumber(formattedNumber);
    formik.setFieldValue("countryCode", countryCode);
    formik.setFieldValue("fax", formattedNumber);
  };

  useEffect(() => {
    UserDetails();
  }, []);

  const [update, setUpdate] = useState(true);

  const edit = () => {
    setUpdate(!update);
  };

  const selectState = (selected) => {
    formik.setFieldValue(
      "province",
      { value: selected.value, label: selected.label },
      false
    );
    // setNewState(selected.label);
    const filteredCities = STATE.filter(
      (city) => city.PROVINCE === selected.value
    );
    setCityOptions(
      filteredCities.map((state) => ({
        label: state.CITY,
        value: state.CITY,
      }))
    );
  };

  const selectCity = async (selectedOption) => {
    formik.setFieldValue("town", selectedOption.label);
    setCity(selectedOption.value);
  };

  const setCity = (cityName) => {
    const filteredState = STATE.filter((city) => city.CITY === cityName);
    const state = filteredState.map((data) => ({
      label: data.STATE + " (" + data.PROVINCE + ")",
      value: data.PROVINCE,
    }));
    formik.setFieldValue("province", state.map((res) => res.value).join(", "));
  };

  cityOptions.sort((a, b) => a.label.localeCompare(b.label));

  const loadMore = () => {
    setVisibleOptions((prev) => prev + 10);
  };

  const filteredOptions = cityOptions.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  const validationSchema = yup.object().shape({
    username: yup.string().required("Please enter Name!"),
    email: yup.string().email().required("Please Enter Email"),

    taxCode: yup
      .string()
      .matches(
        /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/,
        "Invalid tax code Example : RRSMRA74D22A001Q"
      )
      .max(16, "Maximun 16 characters allowed"),
    vatNumber: yup
      .string()
      .matches(/^0[0-9]/, "It must be start with 0.")
      .max(11, "Please ensure that you entered 11 digits or lesser"),

    address: yup.string(),
    town: yup.mixed(),

    province: yup.mixed().required("Please Enter State"),
    cap: yup.string().max(5, "Maximum 5 numbers!").min(5, "Minimum 5 numbers!"),
  });

  // set formik values
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      surname: "",
      region: "",
      supportedBank: "",
      bankName: "",
      cin: "",
      iban: "",
      phoneNo: "",
      fax: "",
      taxCode: "",
      vatNumber: "",
      address: "",
      town: "",
      province: "",
      cap: "",
      countryCode: "",
      cc: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {
        username,
        email,
        surname,
        region,
        supportedBank,
        bankName,
        cin,
        iban,
        phoneNo,
        fax,
        taxCode,
        vatNumber,
        address,
        town,
        province,
        cap,
        countryCode,
        cc,
      } = values;
      const sanitizedFaxNumber = fax.replace(/[^\d]/g, "").substring(2);
      const response = await userService.updateUser(id, {
        username,
        email,
        role: "ADMIN",
        region,
        surname,
        supportedBank,
        bankName,
        cin,
        iban,
        phoneNo,
        fax: sanitizedFaxNumber,
        taxCode,
        vatNumber,
        address,
        town,
        province,
        cap,
        countryCode,
        cc,
      });

      setUpdate(!update);
      enqueueSnackbar(t("ADMIN_PROFILE_UPDATE_SUCCESSFULLY"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
    },
  });

  const handleChangePhone = (country, value) => {
    if (country.startsWith(value.dialCode)) {
      // Remove the countryCode from the beginning of the Number
      const number = country.slice(value.dialCode.length);

      formik.setFieldValue("phoneNo", number);
      formik.setFieldValue("countryCode", value.dialCode);
    }
  };

  const handleCAPChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");

    formik.setFieldValue("cap", sanitizedInput);
  };

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">
          {data.username} {t("profile")}
        </h2>
        <form onSubmit={formik.handleSubmit}>
          {/* Set Edit and save button for form */}
          {update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="button"
              onClick={edit}
            >
              <i className="fe fe-edit me-1"></i> {t("edit")}
            </button>
          )}
          {!update && (
            <button
              className="btn btn-primary btn-sm d-flex ms-auto mb-2 align-items-center"
              type="submit"
            >
              <i className="fas fa-save me-1"></i> {t("save")}
            </button>
          )}
          <Card className="custom-card">
            <Card.Body>
              <div className="row">
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("name")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("surname")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="surname"
                        value={formik.values.surname}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("email")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="adminEmail"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("uniqueid")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="uniqId"
                        value={data.uniqId}
                        disabled
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("region")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="region"
                        value={formik.values.region}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("walletamount")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="walletAmount"
                        value={data.walletAmount}
                        disabled
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("supportedbank")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="supportedBank"
                        value={formik.values.supportedBank}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                      {formik.errors.supportedBank &&
                      formik.touched.supportedBank ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.supportedBank}
                        </Form.Label>
                      ) : null}
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">CC :</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="cc"
                        value={formik.values.cc}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                      {formik.errors.cc && formik.touched.cc ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.cc}
                        </Form.Label>
                      ) : null}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    {" "}
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">CIN :</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="cin"
                        value={formik.values.cin}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                      {formik.errors.cin && formik.touched.cin ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.cin}
                        </Form.Label>
                      ) : null}
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">IBAN :</Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="iban"
                        value={formik.values.iban}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                      {formik.errors.iban && formik.touched.iban ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.iban}
                        </Form.Label>
                      ) : null}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    {" "}
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("bankname")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="bankName"
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                      {formik.errors.bankName && formik.touched.bankName ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.bankName}
                        </Form.Label>
                      ) : null}
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("mobilenumber")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      {update && (
                        <Form.Control
                          type="text"
                          name="phoneNo"
                          value={formik.values.phoneNo}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                      )}
                      {!update && (
                        <PhoneInput
                          country={"it"}
                          value={`${formik.values.countryCode} ${formik.values.phoneNo}`}
                          onChange={handleChangePhone}
                          inputStyle={{ width: "inherit" }}
                        />
                      )}
                      {formik.errors.phoneNo && formik.touched.phoneNo ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.phoneNo}
                        </Form.Label>
                      ) : null}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("fax")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="fax"
                        value={faxNumber}
                        onChange={handleFaxNumberChange}
                        disabled={update ? true : false}
                      />
                      {formik.errors.fax && formik.touched.fax ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.fax}
                        </Form.Label>
                      ) : null}
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("taxcode")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="taxCode"
                        value={formik.values.taxCode}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />
                      {formik.errors.taxCode && formik.touched.taxCode ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.taxCode}
                        </Form.Label>
                      ) : null}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("vatnumber")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="vatNumber"
                        value={formik.values.vatNumber}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />{" "}
                      {formik.errors.vatNumber && formik.touched.vatNumber ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.vatNumber}
                        </Form.Label>
                      ) : null}
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("address")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="text"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        disabled={update ? true : false}
                      />{" "}
                      {formik.errors.address && formik.touched.address ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.address}
                        </Form.Label>
                      ) : null}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("town")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      {update && (
                        <Form.Control
                          type="text"
                          name="town"
                          value={formik.values.town}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                      )}
                      {!update && (
                        <Select
                          classNamePrefix="Select2"
                          id="town"
                          name="town"
                          displayValue="town" // Update to the correct property for display value
                          placeholder="Select a city"
                          singleSelect
                          options={filteredOptions.slice(0, visibleOptions)}
                          menuIsOpen={openCity}
                          onMenuOpen={handleOpenCity}
                          onMenuClose={handleCloseCity}
                          onMenuScrollToBottom={loadMore}
                          onChange={selectCity}
                          value={cityOptions.find(
                            (option) => option.label === formik.values.town
                          )}
                          onInputChange={(inputValue) => setSearch(inputValue)}
                        />
                      )}
                      {formik.errors.town && formik.touched.town ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.town}
                        </Form.Label>
                      ) : null}
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("province")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      {update && (
                        <Form.Control
                          type="text"
                          name="province"
                          value={formik.values.province}
                          onChange={formik.handleChange}
                          disabled={update ? true : false}
                        />
                      )}
                      {!update && (
                        <Select
                          id="province"
                          name="province"
                          options={stateOptions}
                          menuIsOpen={openState}
                          onMenuOpen={handleOpenState}
                          onMenuClose={handleCloseState}
                          value={stateOptions.find(
                            (option) => option.value === formik.values.province
                          )}
                          placeholder={t("province")}
                          onChange={selectState}
                        />
                      )}
                      {formik.errors.province && formik.touched.province ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.province}
                        </Form.Label>
                      ) : null}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="form-group">
                  <Row className=" row-sm">
                    <Col md={2} className="d-flex align-items-center">
                      <Form.Label className="text-dark">
                        {t("pincode")} :
                      </Form.Label>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        type="number"
                        name="cap"
                        value={formik.values.cap}
                        onChange={(event) => handleCAPChange(event)}
                        disabled={update ? true : false}
                      />
                      {formik.errors.cap && formik.touched.cap ? (
                        <Form.Label style={FORMIKERROR}>
                          {formik.errors.cap}
                        </Form.Label>
                      ) : null}
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </Card.Body>
          </Card>
        </form>
      </div>
    </Fragment>
  );
};

export default withLoader(AdminProfile);
