import moment from "moment";
import { useTranslation } from "react-i18next";

const Enums = () => {
  const { t } = useTranslation("common");
  const ROLE = [
    {
      value: "BUSINESS_USER",
      label: t("BUSINESSUSER"),
    },
    {
      value: "CHILD_USER",
      label: t("CHILDUSER"),
    },
    {
      value: "DEALER",
      label: t("DEALER"),
    },
  ];

  const FILTERROLE = [
    {
      value: null,
      label: t("ALL"),
    },
    {
      value: "BUSINESS_USER",
      label: t("BUSINESSUSER"),
    },
    {
      value: "USER",
      label: t("USER"),
    },
    {
      value: "CHILD_USER",
      label: t("CHILDUSER"),
    },
    {
      value: "DEALER",
      label: t("DEALER"),
    },
  ];

  const USER_VERIFIED = [
    {
      value: null,
      label: t("ALL"),
    },
    {
      value: true,
      label: t("yes"),
    },
    {
      value: false,
      label: t("no"),
    },
  ];

  const PREFERRED_PAYMENT = [
    {
      value: "BANK_TRANSFER",
      label: t("BANKTRANSFER"),
    },
    {
      value: "SEPA_ADEBBITO",
      label: t("SEPAADEBBITO"),
    },
    {
      value: "CREDIT_CARD",
      label: t("CREDITCARD"),
    },
  ];
  const ADDRESS_TYPE = [
    {
      value: "BILLING",
      label: t("BILLING"),
    },
    {
      value: "SHIPPING",
      label: t("SHIPPING"),
    },
  ];

  const BUSINESS_PARTNER_API_NAME = [
    {
      value: "PARKING_HUB",
      label: t("PARKING_HUB"),
    },
    {
      value: "API_REST_DI",
      label: t("API_REST_DI"),
    },
    {
      value: "GESTO_CLOUD",
      label: t("GESTO_CLOUD"),
    },
    {
      value: "PARKING_CONTROLLER",
      label: t("PARKING_CONTROLLER"),
    },
    {
      value: "GGTV_APP",
      label: t("GGTV_APP"),
    },
  ];

  const DEALER_TYPE = [
    {
      value: "FREE",
      label: t("FREE"),
    },
    {
      value: "PAID",
      label: t("PAID"),
    },
  ];

  const HOLIDAY_TYPE = [
    {
      value: "CITY",
      label: t("City"),
    },
    {
      value: "NATIONAL_HOLIDAY",
      label: t("nationalholidays"),
    },
  ];

  const DAYSVALIDITY = [
    { value: "EVERYDAY", label: "Everyday" },
    { value: "WORKINGDAY", label: "Workingday" },
    { value: "HOLIDAY", label: "Holiday" },
  ];

  const DAYS = [
    { value: 0, label: t("SUNDAY") },
    { value: 1, label: t("MONDAY") },
    { value: 2, label: t("TUESDAY") },
    { value: 3, label: t("WEDNESDAY") },
    { value: 4, label: t("THURSDAY") },
    { value: 5, label: t("FRIDAY") },
    { value: 6, label: t("SATURDAY") },
  ];

  const COST_OCCURANCE = [
    { value: "DAY", label: t("DAY") },
    { value: "HOUR", label: t("HOUR") },
    { value: "MINUTE", label: t("MINUTE") },
  ];

  const RATE_FREQUENCY_UNIT = [
    { value: "DAY", label: t("DAY") },
    { value: "HOUR", label: t("HOUR") },
    { value: "MINUTE", label: t("MINUTE") },
    { value: "SECOND", label: t("SECOND") },
  ];

  const RATE_FREQUENCY = [
    { value: 1, label: 1 },
    { value: 5, label: 5 },
    { value: 15, label: 15 },
    { value: 30, label: 30 },
  ];

  const OPERATING_SYSTEM = [
    {
      value: null,
      label: t("ALL"),
    },
    {
      value: "ANDROID",
      label: t("android"),
    },
    {
      value: "IOS",
      label: t("ios"),
    },
    {
      value: "OTHERS",
      label: t("others"),
    },
  ];

  const COMISSION = [
    { value: true, label: t("YES") },
    { value: false, label: t("NO") },
  ];

  const LANGUAGE = [
    { value: "en", label: "English" },
    { value: "it", label: "Italian" },
  ];

  const STATUS = [
    {
      value: null,
      label: t("ALL"),
    },
    {
      value: "INITIATE",
      label: t("initiate"),
    },
    {
      value: "IN_PROGRESS",
      label: t("inprogress"),
    },
    {
      value: "COMPLETED",
      label: t("completed"),
    },
    {
      value: "DISPUTE",
      label: t("dispute"),
    },
    {
      value: "REFUND",
      label: t("refunded"),
    },
    {
      value: "CANCEL",
      label: t("cancel"),
    },
  ];

  const USER_RECHARGES_STATUS = [
    {
      value: null,
      label: t("ALL"),
    },
    {
      value: "IN_PROGRESS",
      label: t("inprogress"),
    },
    {
      value: "COMPLETED",
      label: t("completed"),
    },
    {
      value: "DISPUTE",
      label: t("dispute"),
    },
    {
      value: "REFUND",
      label: t("refunded"),
    },
    {
      value: "CANCEL",
      label: t("cancel"),
    },
  ];

  const STATE = [
    { value: "65", label: "Abruzzo" },
    { value: "77", label: "Basilicata" },
    { value: "78", label: "Calabria" },
    { value: "72", label: "Campania" },
    { value: "45", label: "Emilia-Romagna" },
    { value: "36", label: "Friuli Venezia Giulia" },
    { value: "62", label: "Lazio" },
    { value: "42", label: "Liguria" },
    { value: "25", label: "Lombardia" },
    { value: "57", label: "Marche" },
    { value: "67", label: "Molise" },
    { value: "21", label: "Piemonte" },
    { value: "75", label: "Puglia" },
    { value: "88", label: "Sardegna" },
    { value: "82", label: "Sicilia" },
    { value: "52", label: "Toscana" },
    { value: "32", label: "Trentino-Alto Adige" },
    { value: "55", label: "Umbria" },
    { value: "23", label: "Valle d'Aosta" },
    { value: "34", label: "Veneto" },
  ];

  const RANGES = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  return {
    ROLE,
    FILTERROLE,
    PREFERRED_PAYMENT,
    ADDRESS_TYPE,
    DEALER_TYPE,
    HOLIDAY_TYPE,
    DAYS,
    DAYSVALIDITY,
    COST_OCCURANCE,
    RATE_FREQUENCY,
    RATE_FREQUENCY_UNIT,
    COMISSION,
    LANGUAGE,
    STATE,
    STATUS,
    RANGES,
    OPERATING_SYSTEM,
    USER_VERIFIED,
    USER_RECHARGES_STATUS,
    BUSINESS_PARTNER_API_NAME,
  };
};

export default Enums;
