import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

const lan = localStorage.getItem("language") || "it";
i18next.init({
  fallbackLng: "it",
  interpolation: { escapeValue: false }, // React already does escaping
  lng: lan, // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    it: {
      common: common_it,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <Router basename={process.env.REACT_APP_HOME_PAGE}>
      <SnackbarProvider maxSnack={1} preventDuplicate autoHideDuration={1500}>
        <Provider store={store}>
          <App />
        </Provider>
      </SnackbarProvider>
    </Router>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
