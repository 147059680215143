import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import withLoader from "../../layout/Loader/WithLoader";
import ParkingHistoryService from "../../services/parkingHistory.service";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ScrollToTop } from "../../utils/ScrollTop";
import { ANCHORORIGIN, ERROR, ReplaceFunction } from "../../utils/constants";
import { enqueueSnackbar } from "notistack";

const ViewParkHistory = () => {
  const navigate = useNavigate();
  const parkingHistoryService = ParkingHistoryService();
  const { t } = useTranslation("common");
  const { id } = useParams();
  const [data, setData] = useState("");

  const getParkingHistoryById = async () => {
    try {
      const response = await parkingHistoryService.getParkingHistoryById(id);
      setData(response.data.apiresponse.data);
      ScrollToTop();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
      setTimeout(() => {
        navigate(`/parkhistory/`);
      }, 1000);
    }
  };

  const {
    afterCredit,
    areaName,
    beforeCredit,
    charge,
    endDateTime,
    cityName,
    numberPlate,
    parkingName,
    serviceFee,
    startDateTime,
    status,
    surname,
    uniqId,
    username,
    walletAmount,
    bookingStreetName,
  } = data;

  useEffect(() => {
    getParkingHistoryById();
  }, []);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">
          {t("parkhistory") + " " + t("profile")}
        </h2>
        <Row className="row row-sm">
          <Col sm={12} lg={12} xl={12}>
            <Card className="custom-card">
              <Card.Body>
                <div>
                  <div className="row">
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("uniqueid")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={uniqId ? uniqId : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("username")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={username ? username : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("surname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={surname ? surname : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("parkingname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={parkingName ? parkingName : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("area")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={areaName ? areaName : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("city")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={cityName ? cityName : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("starttime")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              startDateTime
                                ? moment(startDateTime).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )
                                : "--"
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("endtime")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              endDateTime
                                ? moment(endDateTime).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )
                                : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("numberplate")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={numberPlate ? numberPlate : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("cost")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              charge !== 0
                                ? ReplaceFunction(charge?.toFixed(2))
                                : charge
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("servicefeepaid")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              serviceFee
                                ? `€${ReplaceFunction(serviceFee?.toFixed(2))}`
                                : serviceFee
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("remainingcredit")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              afterCredit
                                ? `€${ReplaceFunction(afterCredit?.toFixed(2))}`
                                : afterCredit
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("beforecredit")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              beforeCredit
                                ? `€${ReplaceFunction(
                                    beforeCredit?.toFixed(2)
                                  )}`
                                : beforeCredit
                            }
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("walletamount")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              walletAmount
                                ? `€${ReplaceFunction(
                                    walletAmount?.toFixed(2)
                                  )}`
                                : walletAmount
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("status")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={status ? status : "--"}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("streetname")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={bookingStreetName ? bookingStreetName : "--"}
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default withLoader(ViewParkHistory);
