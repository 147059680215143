import React, { useState, useEffect } from "react";
import { Modal, Row } from "react-bootstrap";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useTranslation } from "react-i18next";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import moment from "moment";
import { ReplaceFunction } from "../../utils/constants";

const PreviewPDF = (props) => {
  const { t } = useTranslation("common");
  const { userDetails, invoiceDetail, adminDetails } = props;

  const additionalUserVATAmount = invoiceDetail.totalServiceFee * 0.22;
  const additionalDealerVATAmount = "0,00";

  const totalTaxableValue =
    invoiceDetail.totalParkingCharge + invoiceDetail.totalServiceFee;

  const totalAmount = totalTaxableValue + additionalUserVATAmount;

  const totalRefills =
    invoiceDetail.totalRefillAmount - invoiceDetail.totalCommisionAmount;

  const cashbackAmount =
    (invoiceDetail.cashbackAmount * 100) / invoiceDetail.totalParkingCharge;

  return (
    <div>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
            {userDetails.role === "DEALER" ? t("receipt") : t("invoices")}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <div id="pdf-content">
              <div className="container">
                <img
                  width="200px"
                  height="80px"
                  className="mb-3"
                  src={NeosLogo}
                  alt="Neos Logo"
                />
                <Row style={{ padding: "0px 15px" }}>
                  <table style={{ border: "1px solid black", width: "50%" }}>
                    <thead>
                      <tr>
                        <th style={{ padding: "5px", paddingLeft: "10px" }}>
                          {userDetails.role === "DEALER"
                            ? "Ricevuta :"
                            : "Fattura di cortesia :"}
                        </th>
                        <th>
                          {invoiceDetail.courtesyReceipt
                            ? invoiceDetail.courtesyReceipt
                            : "--"}
                        </th>
                      </tr>
                    </thead>
                    <thead style={{ borderBottom: "1px solid" }}>
                      <tr>
                        <th style={{ padding: "5px", paddingLeft: "10px" }}>
                          Data:
                        </th>
                        <th>
                          {invoiceDetail.creationDate
                            ? moment(invoiceDetail.creationDate).format(
                                "DD/MM/YYYY"
                              )
                            : "--"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: "2px", paddingLeft: "10px" }}>
                          Cod. Cliente:
                        </td>
                        <td>{userDetails.id ? userDetails.id : "--"}</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td style={{ padding: "2px", paddingLeft: "10px" }}>
                          P.IVA Cliente:
                        </td>
                        <td>{userDetails.vat ? userDetails.vat : "--"}</td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: "2px",
                            paddingLeft: "10px",
                            borderBottom: "1px solid",
                          }}
                        >
                          C. Fisc. Cliente:
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid",
                          }}
                        >
                          {userDetails.taxCode ? userDetails.taxCode : "--"}
                        </td>
                      </tr>
                    </tbody>

                    <thead>
                      <tr>
                        <th style={{ paddingLeft: "5px" }}>Pagamento</th>
                      </tr>
                    </thead>
                    <tbody style={{ borderBottom: "1px solid" }}>
                      <tr>
                        <td
                          style={{
                            paddingBottom: "28px",
                            paddingLeft: "15px",
                          }}
                        >
                          {userDetails.preferredPaymentType
                            ? userDetails.preferredPaymentType
                            : "--"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-6" style={{ paddingLeft: "0px" }}>
                    <p style={{ paddingLeft: "10px" }}>
                      <b>Spett.le</b>
                      <br />
                      {userDetails.username
                        ? userDetails.username + " " + userDetails.surname
                        : userDetails.email}
                    </p>
                    <p style={{ float: "right", marginTop: "-77px" }}>
                      Pagina 1 di 1
                    </p>
                    <p style={{ paddingLeft: "10px" }}>
                      {userDetails.address
                        ? userDetails.address +
                          " " +
                          userDetails.town +
                          " " +
                          userDetails.province
                        : "--"}
                      <br />
                      {userDetails.cap ? userDetails.cap : "--"}
                    </p>
                    <div
                      style={{
                        border: "1px solid black",
                        borderLeft: "none",
                        paddingTop: "3px",
                      }}
                    >
                      <p style={{ paddingLeft: "5px" }}>
                        Banca di Appoggio:{" "}
                        {adminDetails.supportedBank
                          ? adminDetails.supportedBank
                          : "--"}
                        {", "}
                        CC:
                        {adminDetails.cc ? adminDetails.cc : "--"}
                        {", "} CIN: {adminDetails.cin ? adminDetails.cin : "--"}
                      </p>
                      <p style={{ paddingLeft: "10px" }}>
                        {adminDetails.bankName ? adminDetails.bankName : "--"}
                        <br />
                        IBAN: {adminDetails.iban ? adminDetails.iban : "--"}
                      </p>
                    </div>
                  </div>
                </Row>
                <div className="col-12" style={{ display: "grid" }}>
                  <table border={0}>
                    <thead>
                      <tr
                        style={{
                          borderBottom: "1px solid",
                          borderLeft: "1px solid",
                        }}
                      >
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                          }}
                        >
                          Articolo
                        </th>
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                            width: "390px",
                          }}
                        >
                          Descrizione
                        </th>
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                            paddingLeft: "10px",
                          }}
                        >
                          Qta'
                        </th>
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                          }}
                        >
                          UdM
                        </th>
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                          }}
                        >
                          Prezzo €
                        </th>
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                          }}
                        >
                          Sconto %
                        </th>
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                          }}
                        >
                          Importo €
                        </th>
                        <th
                          style={{
                            borderRight: "1px solid",
                            textAlign: "center",
                          }}
                        >
                          IVA
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {userDetails.role === "DEALER"
                            ? "RICARICA APP PDR"
                            : "PARCHEGGIO APP"}
                        </td>
                        <td className="ps-2">
                          {userDetails.role === "DEALER"
                            ? "RICARICA APP PDR"
                            : "Parcheggi"}
                          <br />
                          {userDetails.role === "DEALER"
                            ? "ricariche app per il periodo dal"
                            : "per il periodo da"}{" "}
                          {moment(invoiceDetail.fromDate).format("DD/MM/YYYY")}{" "}
                          al {moment(invoiceDetail.toDate).format("DD/MM/YYYY")}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {/* {userDetails.role === "DEALER"
                            ? invoiceDetail.totalRefills
                            : invoiceDetail.totalStops} */}
                          1
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER" ? "Piece" : "Pezzo"}{" "}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER"
                            ? `€${ReplaceFunction(
                                invoiceDetail.totalRefillAmount
                              )}`
                            : invoiceDetail.totalParkingCharge !== undefined &&
                              `€${ReplaceFunction(
                                invoiceDetail.totalParkingCharge?.toFixed(2)
                              )}`}
                        </td>
                        <td style={{ textAlign: "center" }}>0</td>

                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER"
                            ? `€${ReplaceFunction(
                                invoiceDetail.totalRefillAmount
                              )}`
                            : invoiceDetail.totalParkingCharge !== undefined &&
                              `€${ReplaceFunction(
                                invoiceDetail.totalParkingCharge?.toFixed(2)
                              )}`}
                        </td>
                        <td style={{ textAlign: "center" }}>0,00</td>
                      </tr>
                      <tr>
                        <td>
                          {userDetails.role === "DEALER"
                            ? "AGGIOPROVVIGIONE"
                            : "SERVICEFEEAPP"}
                        </td> 
                        <td className="ps-2">
                          {userDetails.role === "DEALER"
                            ? "Aggio/Provvigione Neos Tech"
                            : "Service fee parcheggi APP"}
                          <br />
                          per il periodo dal{" "}
                          {moment(invoiceDetail.fromDate).format(
                            "DD/MM/YYYY"
                          )}{" "}
                          al {moment(invoiceDetail.toDate).format("DD/MM/YYYY")}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {/* {userDetails.role === "DEALER"
                            ? invoiceDetail.totalRefills
                            : invoiceDetail.totalStops} */}
                          1
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER" ? "Piece" : "Pezzo"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER"
                            ? "-" +
                              `€${ReplaceFunction(
                                invoiceDetail.totalCommisionAmount
                              )}`
                            : invoiceDetail.totalServiceFee !== undefined &&
                              `€${ReplaceFunction(
                                invoiceDetail.totalServiceFee?.toFixed(2)
                              )}`}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER" ? "" : "0"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER"
                            ? "-" +
                              `€${ReplaceFunction(
                                invoiceDetail.totalCommisionAmount
                              )}`
                            : invoiceDetail.totalServiceFee !== undefined &&
                              `€${ReplaceFunction(
                                invoiceDetail.totalServiceFee?.toFixed(2)
                              )}`}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {userDetails.role === "DEALER" ? "0,0" : "22,00"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="row">
                  <p style={{ fontSize: "13px", marginBottom: "10px" }}>
                    <b>Contributo Conal assolto ove dovuto.</b>
                  </p>
                  <div
                    className="col-10 mb-5"
                    style={{
                      display: "grid",
                      textAlign: "center",
                    }}
                  >
                    <table border={0} style={{ border: "1px solid black" }}>
                      <thead style={{ border: "1px solid black" }}>
                        <tr>
                          <th
                            style={{ borderRight: "1px solid", width: "200px" }}
                          >
                            Partita IVA
                          </th>
                          <th
                            style={{ borderRight: "1px solid", width: "225px" }}
                          >
                            Descrizione
                          </th>
                          <th style={{ borderRight: "1px solid" }}>
                            Imponibile IVA
                          </th>
                          <th style={{ borderRight: "1px solid" }}>
                            Importo IVA
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              borderRight: "1px solid",
                              borderBottom: "1px solid",
                            }}
                          >
                            {userDetails.role === "DEALER" ? "0" : "22"}
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid",
                              borderBottom: "1px solid",
                            }}
                          >
                            {userDetails.role === "DEALER"
                              ? "N.I.ART.74"
                              : "IVA 22%"}
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid",
                              borderBottom: "1px solid",
                            }}
                          >
                            {userDetails.role === "DEALER"
                              ? "-" +
                                `€${ReplaceFunction(
                                  invoiceDetail.totalCommisionAmount
                                )}`
                              : invoiceDetail.totalServiceFee !== undefined &&
                                `€${ReplaceFunction(
                                  invoiceDetail.totalServiceFee?.toFixed(2)
                                )}`}
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid",
                              borderBottom: "1px solid",
                            }}
                          >
                            {userDetails.role === "DEALER"
                              ? additionalDealerVATAmount
                              : additionalUserVATAmount !== undefined &&
                                `€${ReplaceFunction(
                                  additionalUserVATAmount?.toFixed(2)
                                )}`}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ borderRight: "1px solid" }}>0</td>
                          <td style={{ borderRight: "1px solid" }}>
                            {userDetails.role === "DEALER"
                              ? "Esente Art. 15 - N1"
                              : "N.I.ART.74"}
                          </td>
                          <td style={{ borderRight: "1px solid" }}>
                            {userDetails.role === "DEALER"
                              ? `€${ReplaceFunction(
                                  invoiceDetail.totalRefillAmount?.toFixed(2)
                                )}`
                              : invoiceDetail.totalParkingCharge !==
                                  undefined &&
                                `€${ReplaceFunction(
                                  invoiceDetail.totalParkingCharge?.toFixed(2)
                                )}`}
                          </td>
                          <td style={{ borderRight: "1px solid" }}>0,00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      style={{
                        border: "1px solid black",
                        padding: "0px",
                        width: "fit-content",
                        float: "inline-end",
                        borderBottom: "none",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "5px 15px 5px 16px",
                        }}
                      >
                        <b>
                          {" "}
                          {userDetails.role === "DEALER"
                            ? "Ricevuta:"
                            : "Fattura di cortesia:"}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="col-12" style={{ display: "grid" }}>
                    <table border={0}>
                      <thead>
                        <tr>
                          <th style={{ width: "200px" }} className="ps-2">
                            {userDetails.role === "DEALER"
                              ? "Scadenze"
                              : "Pagato"}
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                            }}
                            className="pe-2"
                          >
                            Totali
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              width: "164px",
                            }}
                            className="pe-2"
                          >
                            {userDetails.role === "DEALER"
                              ? `€${ReplaceFunction(totalRefills?.toFixed(2))}`
                              : `€${ReplaceFunction(
                                  totalTaxableValue?.toFixed(2)
                                )}`}
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              width: "135px",
                            }}
                            className="pe-2"
                          >
                            {userDetails.role === "DEALER"
                              ? additionalDealerVATAmount
                              : `€${ReplaceFunction(
                                  additionalUserVATAmount?.toFixed(2)
                                )}`}
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              width: "155px",
                            }}
                            className="pe-2"
                          >
                            {userDetails.role === "DEALER"
                              ? totalRefills?.toFixed(2)
                              : `€${ReplaceFunction(totalAmount?.toFixed(2))}`}
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ border: "1px solid black" }}>
                        <tr>
                          <td
                            style={{ paddingBottom: "25px" }}
                            className="ps-2"
                          >
                            {invoiceDetail.expiryDate
                              ? moment(invoiceDetail.expiryDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "--"}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              borderRight: "1px solid",
                              paddingBottom: "25px",
                            }}
                            className="pe-2"
                          >
                            {userDetails.role === "DEALER"
                              ? `€${ReplaceFunction(totalRefills?.toFixed(2))}`
                              : `€${ReplaceFunction(totalAmount?.toFixed(2))}`}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              paddingBottom: "25px",
                              textAlign: "center",
                              fontSize: "13px",
                              paddingTop: "10px",
                            }}
                          >
                            Si prega di notare che gli interessi di mora
                            maturano alla data di scadenza
                            <br />
                            automatico per effetto del D.Lgs. n.231 del 09.10.02
                            direttiva 2000/35/CE
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12">
                  <div style={{ textAlign: "center" }}>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "30px",
                        fontWeight: 600,
                      }}
                    >
                      {adminDetails.username} {adminDetails.surname} :{" "}
                      {adminDetails.address}, {adminDetails.cap}{" "}
                      {adminDetails.town}
                      <br />
                      Tel: {adminDetails.phoneNo} Fax: {adminDetails.fax}{" "}
                      e-mail: {adminDetails.email}
                      <br /> C.F.: {adminDetails.taxCode} and P.I.:{" "}
                      {adminDetails.vat}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* {invoiceBase64 && (
              <Document
                file={invoiceBase64}
                onLoadSuccess={onDocumentTermsLoadSuccess}
                className="terms-pdf-container"
              >
                {Array.from(new Array(numPagesTerms), (el, index) => (
                  <PageWithNumber
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PreviewPDF;
