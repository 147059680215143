import React, { Fragment, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listWeek from "@fullcalendar/list";
import { Col, Row, Card, Modal, Form, Button } from "react-bootstrap";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useRef } from "react";
import withLoader from "../../layout/Loader/WithLoader";
import HolidayService from "../../services/holiday.service";
import { ScrollToTop } from "../../utils/ScrollTop";
import {
  ANCHORORIGIN,
  ERROR,
  FORMIKERROR,
  SUCCESS,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

const NationalHolidays = () => {
  const holidayService = HolidayService();
  const { t } = useTranslation("common");
  const calendarRef = useRef(null);
  let eventGuid = 0;
  function createEventId() {
    return String(eventGuid++);
  }

  const [isYearly, setIsYearly] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [event, setEvent] = useState("");
  const [update, setUpdate] = useState(false);
  const [calendarStartDate, setCalendarStartDate] = useState(
    Date.parse(new Date())
  );
  const [calendarEndDate, setCalendarEndDate] = useState(
    Date.parse(new Date())
  );
  const [eventDates, setEventDates] = useState([]);

  // Dates which are showable and which are not (Month date are shown not other month date shown)
  const handleDatesSet = (arg) => {
    const start = arg.view.currentStart;
    const end = arg.view.currentEnd;

    setCalendarStartDate(new Date(start).getTime());
    setCalendarEndDate(new Date(end).getTime());

    //   setCalendarStartDate(new Date(start));
    //   setCalendarEndDate(new Date(end));
  };
  let eventsByDate = [];

  // Get all holiday list for data shown
  const getHoliday = async () => {
    // Get calendar Using calendarRef
    let calendarApi = calendarRef.current?.calendar.view.calendar;
    calendarApi?.removeAllEvents();
    calendarApi?.unselect();

    // Add holiday event in API
    const response = await holidayService.getHoliday(
      calendarStartDate,
      calendarEndDate
    );
    const data = response.data.apiresponse.data;

    const newEventDates = [];

    data.map((res) => {
      const date = moment(res.date).format("YYYY-MM-DD");

      // const utCDate = moment(res.date).utc();
      //
      // const formattedUtcTime = utCDate.format("YYYY-MM-DD");
      //

      calendarAddEvent(res.id, res.eventName, date, date, calendarApi);
      newEventDates.push(date);
    });

    // Set events date for that when we try to add another event then we can prevent that
    setEventDates(newEventDates);
  };

  const calendarAddEvent = (id, event, start, end, calendarApi) => {
    const newStartDate = moment(start);
    const newEndDate = moment(end);
    const currentYear = moment(calendarStartDate).year();
    const targetYear = 5000;

    if (newStartDate.year() === targetYear) {
      // for (let year = currentYear; year <= targetYear; year++) {
      const newStart = moment(start).year(currentYear);
      const newEnd = moment(end).year(currentYear);

      // Using this we can show all the events in the calendar data
      calendarApi?.addEvent({
        id: id,
        title: event,
        start: newStart.toDate(),
        end: newEnd.toDate(),
        allDay: true,
        backgroundColor: "#ffffff",
        textColor: "#000000",
      });

      while (newStart.isSameOrBefore(newEnd, "day")) {
        let dateStr = newStart.format("YYYY-MM-DD");
        let selectedDateCell = document.querySelector(
          `[data-date="${dateStr}"]`
        );

        if (selectedDateCell) {
          selectedDateCell.classList.add("fc-day-selected");
        }

        newStart.add(1, "day");
      }
    } else {
      // If not yearly, add the event for the current year only
      calendarApi?.addEvent({
        id: id,
        title: event,
        start: start,
        end: end,
        allDay: true,
        backgroundColor: "#ffffff",
        textColor: "#000000",
      });
    }

    while (newStartDate.isSameOrBefore(newEndDate, "day")) {
      let dateStr = newStartDate.format("YYYY-MM-DD");

      // Add backgrounf color for the default date
      let selectedDateCell = document.querySelector(`[data-date="${dateStr}"]`);

      if (selectedDateCell) {
        selectedDateCell.classList.add("fc-day-selected");
      }

      newStartDate.add(1, "day");
    }
  };

  // Toggle modal of add & update
  const toggleModal = () => {
    setShowModal(!showModal);
    if (showModal) {
      setIsYearly(false);
    }
    formik.resetForm();
  };

  // Open delete Modal
  const openDeleteModal = (clickInfo) => {
    setDeleteModal(true);

    setEvent({
      ...event,
      delete: clickInfo.event.title,
      clickInfo: clickInfo,
    });
  };

  // Initial Values of form fields
  const initialValues = {
    date: "",
    eventName: "",
    type: "NATIONAL_HOLIDAY",
  };

  const validationSchema = yup.object().shape({
    event: yup.string().required("Event is Required"),
  });

  // Submit Form For add new Holiday event
  const handleFormSubmit = async (values, action) => {
    setEvent(values.event);
    let calendarApi = calendarRef.current.calendar.view.calendar;
    calendarApi.unselect();

    let startDate = moment(event.start);
    let endDate = moment(event.end).subtract(1, "day");

    if (isYearly) {
      startDate = moment(event.start).year(5000);
      endDate = moment(event.end).year(5000).subtract(1, "day");
    } else {
      startDate = moment(event.start);
      endDate = moment(event.end).subtract(1, "day");
    }

    calendarAddEvent(
      createEventId(),
      values.event,
      startDate,
      endDate,
      calendarApi
    );

    let startMilli;
    if (isYearly) {
      startMilli = moment(event.start).year(5000).valueOf();
    } else {
      startMilli = moment(event.start).valueOf();
    }

    // Add event API Call
    try {
      if (!event.id) {
        await holidayService.addHoliday({
          date: startMilli,
          eventName: values.event,
          type: "NATIONAL_HOLIDAY",
        });
        await getHoliday();
        setShowModal(false);
        action.resetForm();
      } else {
        await holidayService.updateHoliday({
          id: event.id,
          date: startMilli,
          eventName: values.event,
          type: "NATIONAL_HOLIDAY",
        });

        await getHoliday();
        setShowModal(false);
      }
      setIsYearly(false);
      formik.resetForm();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  // Handle delete Event when click on event delete button
  const handleEventClick = async () => {
    event.clickInfo.event.remove();

    // get id from events
    const id = event.clickInfo.event.id;

    let startDate = moment(event.clickInfo.event.startStr);
    let endDate = moment(event.clickInfo.event.startStr);

    const response = await holidayService.deleteHoliday(id);

    // Remove Background color when deleted
    while (startDate.isSameOrBefore(endDate, "day")) {
      let dateStr = startDate.format("YYYY-MM-DD");
      let selectedDateCell = document.querySelector(`[data-date="${dateStr}"]`);

      if (eventsByDate.map((res) => res)) {
        if (selectedDateCell) {
          selectedDateCell.classList.remove("fc-day-selected");
        }
      }

      startDate.add(1, "day");
    }
    // Set events dates empty that's why when we need to add new event that time we can add new event
    setDeleteModal(false);
    setEventDates([]);
  };

  // Handle date select events
  const handleDateSelect = async (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();

    if (selectInfo.event?.id) {
      // if already have id than call API and set default field value
      setUpdate(true);
      const response = await holidayService.getHolidaybyId(
        selectInfo.event?.id
      );

      const result = response.data.apiresponse.data;

      if (moment(result.date).year() === 5000) {
        setIsYearly(true);
        formik.setFieldValue(`isYearly`, true);
      }
      toggleModal();
      formik.setFieldValue("id", result.id);
      formik.setFieldValue("event", result.eventName);
      formik.setFieldValue("type", result.type);
      formik.setFieldValue("date", result.date);

      // Set all the required fields in events state
      if (selectInfo.event) {
        setEvent({
          ...event,
          id: result.id,
          title: result.eventName,
          start: selectInfo.event.startStr,
          end: selectInfo.event.endStr,
          allDay: selectInfo.event.allDay,
        });
      } else {
        setEvent({
          ...event,
          id: result.id,
          title: result.eventName,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    } else {
      toggleModal();
      setUpdate(false);
      formik.resetForm();
      if (selectInfo.event) {
        setEvent({
          ...event,
          start: selectInfo.event.startStr,
          end: selectInfo.event.endStr,
          allDay: selectInfo.event.allDay,
        });
      } else {
        setEvent({
          ...event,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    }
  };

  // Allowed date before today dates are not allowed and already have event have not allowed
  const handleDateAllow = (selectInfo) => {
    const selectedDate = moment(selectInfo.startStr);
    const today = moment().startOf("day");

    if (eventDates.includes(selectedDate.format("YYYY-MM-DD"))) {
      return false;
    }
    return selectedDate.isSameOrAfter(today);
  };

  // Add Event name and edit, delete button
  const eventContent = (arg) => {
    return (
      <div className="custom-event d-flex align-items-center">
        <div className="fw-bold text-dark ms-2">{arg.event.title}</div>
        <div className="d-flex flex-column ms-auto me-2">
          <button
            className="btn btn-sm p-0"
            onClick={() => handleDateSelect(arg)}
          >
            <i className="fe fe-edit"></i>
          </button>
          <button
            className="btn btn-sm p-0"
            onClick={() => openDeleteModal(arg)}
          >
            <i className="fe fe-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const handleshowStreet = (e) => {
    const isChecked = e.target.checked;

    setIsYearly(isChecked);
    formik.setFieldValue(`isYearly`, isChecked);
  };

  useEffect(() => {
    getHoliday();
    ScrollToTop();
  }, [calendarEndDate, calendarStartDate]);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-24 mg-b-5 mb-3">
          {t("nationalholidays")}
        </h2>

        <Row className="row-sm">
          <Col sm={12} md={12}>
            <Card className="custom-card">
              <Card.Body>
                <Row className="" id="wrap">
                  <Col xl={12} id="calendar-wrap">
                    <div id="calendar">
                      <FullCalendar
                        ref={calendarRef}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                          listWeek,
                        ]}
                        headerToolbar={{
                          right: "prev,next today",
                          center: "title",
                          left: "",
                        }}
                        // initialDate="5000-12-01"
                        initialView="dayGridMonth"
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        select={handleDateSelect}
                        selectAllow={handleDateAllow}
                        eventContent={eventContent}
                        datesSet={handleDatesSet}
                        showNonCurrentDates={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Add event Modal */}
      <Modal
        show={showModal}
        onHide={toggleModal}
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
            {update
              ? t("update") + " " + t("event")
              : t("add") + " " + t("event")}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("event") + " " + t("name")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.event}
                onChange={formik.handleChange}
                name="event"
                placeholder={t("event") + " " + t("name")}
                style={{ borderColor: "#D3D3DE" }}
              />
              {formik.errors.event && formik.touched.event ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.event}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group>
              <label className="custom-checkbox d-flex">
                <span className="tx-16">{t("wanttospecifyyear")}</span>
                <input
                  type="checkbox"
                  className="custom-control-checkbox tx-16"
                  name={`example-checkbox`}
                  // value={isYearly}
                  value="option1"
                  defaultChecked={isYearly}
                  onChange={(e) => {
                    handleshowStreet(e);
                  }}
                />
              </label>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button type="submit" className="btn-sm">
                {t("submit")}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Delete event Modal */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto">{t("deleteevent")}</h5>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group text-center tx-16">
            {t("deletesentance")} <strong>{event.delete}</strong> {t("event")}?
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={handleEventClick}
              variant="danger"
              className="btn-sm"
            >
              {t("delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default withLoader(NationalHolidays);
