import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const BusinessPartnerService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getAllBusinessPartner = async () => {
    const response = await serverCall.customAxios.get(`/businessPartner/`, {
      headers,
    });
    return response;
  };

  const getBusinessPartner = async (id) => {
    const response = await serverCall.customAxios.get(
      `/businessPartner/${id}`,
      {
        headers,
      }
    );
    return response;
  };

  const createBusinessPartner = async (body) => {
    const response = await serverCall.customAxios.post(
      `/businessPartner/`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const updateBusinessPartner = async (id, body) => {
    const response = await serverCall.customAxios.post(
      `/businessPartner/update/${id}`,
      body,
      { headers }
    );
    return response;
  };

  const getWithFilter = (pageNumber, pageSize, searchText) => {
    const response = serverCall.customAxios.get(
      `/businessPartner/all?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`,
      { headers }
    );
    return response;
  };

  const deleteChildBusinessPartner = async (id) => {
    const response = await serverCall.customAxios.delete(
      `/user/childbusinesspartner/${id}`,
      { headers }
    );
    return response;
  };

  const deleteBusinessPartner = async (id) => {
    const response = await serverCall.customAxios.delete(
      `/businessPartner/${id}`,
      {
        headers,
      }
    );
    return response;
  };
  const getBusinessPartnerByCityId = async (id) => {
    const response = await serverCall.customAxios.get(
      `/businessPartner/city/${id}`,
      {
        headers,
      }
    );
    return response;
  };

  return {
    getAllBusinessPartner,
    createBusinessPartner,
    getBusinessPartner,
    updateBusinessPartner,
    getWithFilter,
    deleteChildBusinessPartner,
    deleteBusinessPartner,
    getBusinessPartnerByCityId,
  };
};
export default BusinessPartnerService;
