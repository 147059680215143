import { useState } from "react";

function useDialogState() {
  const [open, setOpen] = useState(false);
  const [opendata, setOpenData] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenData = () => {
    setOpenData(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseData = () => {
    setOpenData(false);
  };

  return {
    open,
    opendata,
    handleClickOpen,
    handleClickOpenData,
    handleClose,
    handleCloseData,
  };
}

export default useDialogState;
