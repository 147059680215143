import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import Multiselect from "react-select";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import { CUSTOMDROPDOWNSTYLE, FORMIKERROR } from "../../utils/constants";
import Enums from "../../utils/enums";
import { useTranslation } from "react-i18next";

const AddBusinessPartner = (props) => {
  const { ADDRESS_TYPE, PREFERRED_PAYMENT } = Enums();
  const { t } = useTranslation("common");
  const {
    faxNumber,
    vatNumber,
    atelephoneNumber,
    ctelephoneNumber,
    ttelephoneNumber,
    handleVAT,
    handleFaxNumberChange,
    update,
    city,
    formik,
    handleChangePhone,
    handlePhoneNumberChange,
    addAddressSlot,
    addAddressData,
    removeAddress,
    addNumbers,
    addNumber,
    removeNumber,
    openPaymentType,
    handleOpenPaymentType,
    handleClosePaymentType,
    openAddressType,
    handleOpenAddressType,
    handleCloseAddressType,
    handleshowStreet,
    isShowStreet,
    handlePincodeChange,
    handleInvoiceCode,
  } = props;

  return (
    <Modal
      {...props}
      // size="xl"
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {update ? t("updatebusinesspartner") : t("addbusinesspartner")}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row className="row-sm">
            {/* <Col lg={4}> */}
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("name")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
                placeholder={t("name")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.name && formik.touched.name ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.name}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("vatnumber")} :</Form.Label>
              <Form.Control
                type="text"
                value={vatNumber}
                onChange={handleVAT}
                name="vatNo"
                placeholder={t("vatnumber")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.vatNo && formik.touched.vatNo ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.vatNo}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("email")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
                placeholder={t("email")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.email && formik.touched.email ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.email}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("certifateemail")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.certificateEmail}
                onChange={formik.handleChange}
                name="certificateEmail"
                placeholder={t("certifateemail")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.certificateEmail &&
              formik.touched.certificateEmail ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.certificateEmail}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("eleinvoicecode")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.einvoiceCode}
                onChange={handleInvoiceCode}
                name="einvoiceCode"
                placeholder={t("eleinvoicecode")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.einvoiceCode && formik.touched.einvoiceCode ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.einvoiceCode}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("city")} :</Form.Label>
              <Select
                classNamePrefix="Select2"
                closeMenuOnSelect={false}
                isMulti
                options={city}
                name="cityIds"
                value={formik.values.cityIds}
                onChange={(selectedOption) =>
                  formik.setFieldValue("cityIds", selectedOption)
                }
                placeholder={t("city")}
                styles={CUSTOMDROPDOWNSTYLE}
              />
              {formik.errors.cityIds && formik.touched.cityIds ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.cityIds}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("fax")} :</Form.Label>
              <Form.Control
                type="text"
                value={faxNumber}
                onChange={handleFaxNumberChange}
                name="fax"
                placeholder={t("fax")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.fax && formik.touched.fax && (
                <Form.Label style={FORMIKERROR}>{formik.errors.fax}</Form.Label>
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("taxcode")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.taxCode}
                onChange={formik.handleChange}
                name="taxCode"
                placeholder={t("taxcode")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.taxCode && formik.touched.taxCode ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.taxCode}
                </Form.Label>
              ) : null}
            </Form.Group>

            {/* Add and remove slot if we want multiple slots of numbers */}
            <Form.Label className="text-dark">{t("mobilenumber")} :</Form.Label>
            {/* <div
              style={{
                overflow: "auto",
                paddingTop: "15px",
                marginBottom: "15px",
                maxHeight: "140px",
                border: "0.5px solid #efefef",
                padding: "10px 10px 0px 10px",
              }}
            > */}
            {[...Array(addNumbers)].map((_, index) => (
              <>
                <Form.Group
                  className="form-group"
                  key={index}
                  style={{ display: "flex" }}
                >
                  <PhoneInput
                    country={"it"}
                    value={`${formik.values.contanctNumbers[index]?.countryCode} ${formik.values.contanctNumbers[index]?.phoneNo}`}
                    onChange={(country, value) =>
                      handleChangePhone(index, country, value)
                    }
                    inputStyle={{ width: "inherit" }}
                  />
                  {index === addNumbers - 1 ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm ms-2"
                        onClick={addNumber}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      {index > 0 && (
                        <button
                          type="button"
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => removeNumber(index)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger ms-2"
                      onClick={() => removeNumber(index)}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  )}
                </Form.Group>
                {formik.errors.contanctNumbers?.[index] &&
                formik.touched.contanctNumbers?.[index] ? (
                  <Form.Label style={FORMIKERROR}>
                    {
                      (formik.errors.contanctNumbers?.[index]?.countryCode,
                      formik.errors.contanctNumbers?.[index]?.phoneNo)
                    }
                  </Form.Label>
                ) : null}
              </>
            ))}
            {/* </div> */}

            <Form.Group className="form-group">
              <label className="custom-checkbox d-flex">
                <span className="tx-16">{t("doyouwanttoallowshowstreet")}</span>
                <input
                  type="checkbox"
                  className="custom-control-checkbox tx-16"
                  name={`example-checkbox`}
                  value="option1"
                  defaultChecked={isShowStreet}
                  onChange={(e) => {
                    handleshowStreet(e);
                  }}
                />
              </label>
            </Form.Group>
            {/* </Col>
            <Col lg={4}> */}
            <div
              style={{
                overflow: "auto",
                paddingTop: "15px",
                marginBottom: "15px",
                maxHeight: "450px",
                border: "0.5px solid #efefef",
                padding: "10px 10px 0px 10px",
              }}
            >
              {/* Add and remove slot if we want multiple slots of address */}
              {[...Array(addAddressSlot)].map((_, index) => (
                <>
                  <div className="d-flex">
                    <Form.Label className="text-black">
                      Address. {index + 1}
                    </Form.Label>
                    <div className="ms-auto mb-1">
                      {index === addAddressSlot - 1 ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm ms-2"
                            onClick={addAddressData}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-danger ms-2"
                              onClick={() => removeAddress(index)}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => removeAddress(index)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      )}
                    </div>
                  </div>
                  <Form.Group className="form-group" key={index}>
                    <div key={index}>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("addresstype")} :
                        </Form.Label>
                        <Multiselect
                          classNamePrefix="Select2"
                          options={ADDRESS_TYPE}
                          singleSelect
                          placeholder={t("addresstype")}
                          displayValue="key"
                          name={`addressList[${index}].type`}
                          menuIsOpen={openAddressType[index]}
                          onMenuOpen={() => handleOpenAddressType(index)}
                          onMenuClose={() => handleCloseAddressType(index)}
                          value={ADDRESS_TYPE.find(
                            (option) =>
                              option.value ===
                              formik.values.addressList[index]?.type
                          )}
                          onChange={(selectedOption) =>
                            formik.setFieldValue(
                              `addressList[${index}].type`,
                              selectedOption.value
                            )
                          }
                          styles={CUSTOMDROPDOWNSTYLE}
                        />
                        {formik.errors.addressList &&
                        formik.errors.addressList[index]?.type &&
                        formik.touched.addressList &&
                        formik.touched.addressList[index]?.type ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.addressList[index]?.type}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("streetaddress")} :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formik.values.addressList[index]?.street}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `addressList[${index}].street`,
                              e.target.value
                            )
                          }
                          name={`addressList[${index}].street`}
                          placeholder={t("streetaddress")}
                          style={{ borderColor: "#d3d3de" }}
                        />
                        {formik.errors.addressList &&
                        formik.errors.addressList[index]?.street &&
                        formik.touched.addressList &&
                        formik.touched.addressList[index]?.street ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.addressList[index].street}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("city")} :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formik.values.addressList[index]?.city}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `addressList[${index}].city`,
                              e.target.value
                            )
                          }
                          name={`addressList[${index}].city`}
                          placeholder={t("city")}
                          style={{ borderColor: "#d3d3de" }}
                        />
                        {formik.errors.addressList &&
                        formik.errors.addressList[index]?.city &&
                        formik.touched.addressList &&
                        formik.touched.addressList[index]?.city ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.addressList[index].city}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("province")} :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formik.values.addressList[index]?.state}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `addressList[${index}].state`,
                              e.target.value
                            )
                          }
                          name={`addressList[${index}].state`}
                          placeholder={t("province")}
                          style={{ borderColor: "#d3d3de" }}
                        />
                        {formik.errors.addressList &&
                        formik.errors.addressList[index]?.state &&
                        formik.touched.addressList &&
                        formik.touched.addressList[index]?.state ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.addressList[index]?.state}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("pincode")} :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formik.values.addressList[index]?.pincode}
                          onChange={(e) => handlePincodeChange(e, index)}
                          name={`addressList[${index}].pincode`}
                          placeholder={t("pincode")}
                          style={{ borderColor: "#d3d3de" }}
                        />
                        {formik.errors.addressList &&
                        formik.errors.addressList[index] &&
                        formik.errors.addressList[index]?.pincode &&
                        formik.touched.addressList &&
                        formik.touched.addressList[index]?.pincode ? (
                          <Form.Label style={FORMIKERROR}>
                            {formik.errors.addressList[index].pincode}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                  </Form.Group>
                </>
              ))}
            </div>

            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("note")} :</Form.Label>
              <Form.Control
                as="textarea"
                value={formik.values.note}
                onChange={formik.handleChange}
                name="note"
                rows={1}
                placeholder={t("note")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.note && formik.touched.note ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.note}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("cig")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.cig}
                onChange={formik.handleChange}
                name="cig"
                placeholder={t("cig")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.cig && formik.touched.cig ? (
                <Form.Label style={FORMIKERROR}>{formik.errors.cig}</Form.Label>
              ) : null}
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("preferredpaymenttype")} :
              </Form.Label>
              <Multiselect
                classNamePrefix="Select2"
                options={PREFERRED_PAYMENT}
                singleSelect
                placeholder={t("preferredpaymenttype")}
                displayValue="label"
                name="preferredPaymentType"
                menuIsOpen={openPaymentType}
                onMenuOpen={handleOpenPaymentType}
                onMenuClose={handleClosePaymentType}
                value={PREFERRED_PAYMENT.find(
                  (option) =>
                    option.value === formik.values.preferredPaymentType
                )}
                onChange={(selectedOption) =>
                  formik.setFieldValue(
                    "preferredPaymentType",
                    selectedOption.value
                  )
                }
                styles={CUSTOMDROPDOWNSTYLE}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("ccholdername")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.holderName}
                onChange={formik.handleChange}
                name="holderName"
                placeholder={t("ccholdername")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.holderName && formik.touched.holderName ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.holderName}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("bankaccountIBAN")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.bankIBAN}
                onChange={formik.handleChange}
                name="bankIBAN"
                placeholder={t("bankaccountIBAN")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.bankIBAN && formik.touched.bankIBAN ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.bankIBAN}
                </Form.Label>
              ) : null}
            </Form.Group>
            {/* </Col>
            <Col lg={4}> */}
            {/* <Form.Group className="form-group">
                <hr className="mt-1" />
              </Form.Group> */}

            <h5 className="text-dark mb-3 tx-uppercase">{t("commercial")}</h5>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("surname")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.commercialSurname}
                onChange={formik.handleChange}
                name="commercialSurname"
                placeholder={t("surname")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.commercialSurname &&
              formik.touched.commercialSurname ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.commercialSurname}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("email")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.commercialEmail}
                onChange={formik.handleChange}
                name="commercialEmail"
                placeholder={t("email")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.commercialEmail &&
              formik.touched.commercialEmail ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.commercialEmail}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("telephone")} :</Form.Label>
              <Form.Control
                type="text"
                name="commercialTelephone"
                placeholder={t("telephone")}
                value={ctelephoneNumber}
                onChange={(event) =>
                  handlePhoneNumberChange(event, "commercial")
                }
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.touched.commercialTelephone &&
              formik.errors.commercialTelephone ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.commercialTelephone}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <hr className="mt-1" />
            </Form.Group>

            <h5 className="text-dark mb-3">{t("technical")}</h5>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("surname")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.technicalSurname}
                onChange={formik.handleChange}
                name="technicalSurname"
                placeholder={t("surname")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.technicalSurname &&
              formik.touched.technicalSurname ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.technicalSurname}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("email")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.technicalEmail}
                onChange={formik.handleChange}
                name="technicalEmail"
                placeholder={t("email")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.technicalEmail && formik.touched.technicalEmail ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.technicalEmail}
                </Form.Label>
              ) : null}
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("telephone")} :</Form.Label>
              <Form.Control
                type="text"
                name="technicalTelephone"
                placeholder={t("telephone")}
                value={ttelephoneNumber}
                onChange={(event) =>
                  handlePhoneNumberChange(event, "technical")
                }
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.touched.technicalTelephone &&
              formik.errors.technicalTelephone ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.technicalTelephone}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <hr className="mt-1" />
            </Form.Group>

            <h5 className="text-dark mb-3">{t("administrative")}</h5>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("surname")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.administrativeSurname}
                onChange={formik.handleChange}
                name="administrativeSurname"
                placeholder={t("surname")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.administrativeSurname &&
              formik.touched.administrativeSurname ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.administrativeSurname}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("email")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.administrativeEmail}
                onChange={formik.handleChange}
                name="administrativeEmail"
                placeholder={t("email")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.administrativeEmail &&
              formik.touched.administrativeEmail ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.administrativeEmail}
                </Form.Label>
              ) : null}
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("telephone")} :</Form.Label>
              <Form.Control
                type="text"
                name="administrativeTelephone"
                placeholder={t("telephone")}
                value={atelephoneNumber}
                onChange={(event) =>
                  handlePhoneNumberChange(event, "administrative")
                }
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.touched.administrativeTelephone &&
              formik.errors.administrativeTelephone ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.administrativeTelephone}
                </Form.Label>
              ) : null}
            </Form.Group>
            {/* </Col> */}
            <Form.Group>
              <Button
                type="submit"
                className="btn ripple btn-primary btn-block"
              >
                {update ? t("update") : t("add")}
              </Button>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddBusinessPartner;
