import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const AreaService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getAllArea = async (pageNumber, pageSize, searchText, language) => {
    const response = await serverCall.customAxios.get(
      `/area/all/filter?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`,
      {
        headers,
      }
    );
    return response;
  };

  const getArea = async () => {
    const response = await serverCall.customAxios.get(`/area/all`, { headers });
    return response;
  };

  const addArea = async (body) => {
    const response = await serverCall.customAxios.post(`/area/`, body, {
      headers,
    });
    return response;
  };

  const updateArea = async (body) => {
    const response = await serverCall.customAxios.put(
      `/area/?id=${body.id}`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const getAreaById = async (id) => {
    const response = await serverCall.customAxios.get(`/area/${id}`, {
      headers,
    });
    return response;
  };

  const getAreaByCity = async (id) => {
    const response = await serverCall.customAxios.get(`/area/city/${id}`, {
      headers,
    });
    return response;
  };

  const deleteArea = async (id) => {
    const response = await serverCall.customAxios.delete(`/area/${id}`, {
      headers,
    });
    return response;
  };

  return {
    getAllArea,
    addArea,
    getAreaById,
    updateArea,
    getArea,
    deleteArea,
    getAreaByCity,
  };
};

export default AreaService;
