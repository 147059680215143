import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { FORMIKERROR } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export function Approve(props) {
  const { t } = useTranslation("common");
  const { formik, error } = props;

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
            {t("addrechargelimit")}
          </h5>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Row className="row-sm">
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("currentrechargelimit")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("currentrechargelimit")}
                  value={"€ " + formik.values.currentRechargeLimit}
                  disabled
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("percantageofearning")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("percantageofearning")}
                  value={formik.values.percantageOfEarning + " %"}
                  disabled
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("rechargerequest")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={"€ " + formik.values.rechargeRequest}
                  onChange={formik.handleChange}
                  name="rechargeRequest"
                  placeholder={t("rechargerequest")}
                  style={{ borderColor: "#d3d3de" }}
                  disabled
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Row>
                  <Col md={6} style={{ borderRight: "1px solid #B3B3B3" }}>
                    <Form.Label className="text-dark">
                      {t("percentageofrechargerequests")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={"€ " + formik.values.afterrechargelimit}
                      onChange={formik.handleChange}
                      name="afterrechargelimit"
                      placeholder={t("percentageofrechargerequests")}
                      style={{ borderColor: "#d3d3de" }}
                      disabled
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Label className="text-dark">
                      {t("additionofpoe")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={"€ " + formik.values.additionalAmount}
                      onChange={formik.handleChange}
                      name="additionalAmount"
                      placeholder={t("additionofpoe")}
                      style={{ borderColor: "#d3d3de" }}
                      disabled
                    />
                  </Col>
                </Row>
              </Form.Group>

              <div className="d-flex justify-content-end">
                <Button type="submit" variant="primary" className="ms-3 btn-sm">
                  {t("approve")}
                </Button>
              </div>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
