import { useEffect, useState } from "react";
import Loader from "./Loader";

const withLoader = (WrappedComponent) => {
  return () => {
    const [loading, setLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
      const handleBeforeUnload = () => {
        setShowLoader(false); // Hide the first loader when the page is being refreshed
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, []);

    return (
      <>
        {showLoader && loading && <Loader />}
        <WrappedComponent />
      </>
    );
  };
};

export default withLoader;
