import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const DashboardService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getAdminDashboard = async (body) => {
    const response = await serverCall.customAxios.post(
      `/dashboard/admin/`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const getPaidDealerDashboard = async (body) => {
    const response = await serverCall.customAxios.post(
      `/dashboard/paid/dealer`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const getFreeDealerDashboard = async (body) => {
    const response = await serverCall.customAxios.post(
      `/dashboard/free/dealer`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const getBusinessPartnerDashboard = async (body) => {
    const response = await serverCall.customAxios.post(
      `/dashboard/businesspartner`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  return {
    getAdminDashboard,
    getPaidDealerDashboard,
    getFreeDealerDashboard,
    getBusinessPartnerDashboard,
  };
};

export default DashboardService;
