import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const ParkingHistoryService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getParkingHistory = async (body) => {
    const response = await serverCall.customAxios.post(
      `/booking/history`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const getParkingHistoryById = async (id) => {
    const response = await serverCall.customAxios.post(
      `/booking/history/${id}`,
      {
        headers,
      }
    );
    return response;
  };

  return {
    getParkingHistory,
    getParkingHistoryById,
  };
};

export default ParkingHistoryService;
