import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const InvoiceService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getAdminInvoice = async (body) => {
    const response = await serverCall.customAxios.post(
      `/invoice/admin/`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const getDealerInvoice = async (pageLimit, pageNumber) => {
    const response = await serverCall.customAxios.post(
      `/invoice/user?pageLimit=${pageLimit}&pageNumber=${pageNumber}`,
      {
        headers,
      }
    );
    return response;
  };

  const downloadXML = async (id) => {
    const response = await serverCall.customAxios.post(`/invoice/${id}/xml`, {
      headers,
    });
    return response;
  };

  const invoiceDetails = async (id) => {
    const response = await serverCall.customAxios.get(`/invoice/${id}`, {
      headers,
    });
    return response;
  };

  const confirmInvoice = async (id) => {
    const response = await serverCall.customAxios.post(
      `/invoice/${id}/confirm`,
      {
        headers,
      }
    );
    return response;
  };

  return {
    getAdminInvoice,
    getDealerInvoice,
    downloadXML,
    invoiceDetails,
    confirmInvoice,
  };
};

export default InvoiceService;
