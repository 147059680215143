import { useFormik } from "formik";
import { ANCHORORIGIN, FORMIKERROR, SUCCESS } from "../../utils/constants";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { SUCCESSFULLY_DEALER_REQUEST_REJECTED } from "../../utils/messages";
import { enqueueSnackbar } from "notistack";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const RejectRequest = (props) => {
  const { t } = useTranslation("common");
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: yup.object().shape({
      reason: yup.string().required("Please enter valid reason!"),
    }),
    onSubmit: () => {
      props.onHide();
      enqueueSnackbar(t("SUCCESSFULLY_DEALER_REQUEST_REJECTED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
    },
  });

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("reject") + " " + t("request")}
        </h5>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row className="row-sm">
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                value={formik.values.reason}
                onChange={formik.handleChange}
                name="reason"
                placeholder={t("reason")}
                style={{ borderColor: "#D3D3DE" }}
              />{" "}
              {formik.errors.reason && formik.touched.reason ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.reason}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" variant="danger" className="btn-sm">
                {t("reject")}
              </Button>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default RejectRequest;
