import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DeleteCity = (props) => {
  const { t } = useTranslation("common");
  const { deleteCity, details } = props;

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
            {t("deletecity")}
          </h5>
        </Modal.Header>

        <Modal.Body className="text-center pb-4">
          <Form.Group className="form-group">
            <p className="mt-3 fs-18">
              {t("deletesentance")} {details.name} {t("city")} ?
            </p>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                onClick={() => deleteCity(details.id)}
                variant="danger"
                className="ms-3 me-4 btn-sm"
              >
                {t("delete")}
              </Button>
            </div>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteCity;
