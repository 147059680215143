import moment from "moment";
import React, { useState } from "react";
import InvoiceService from "../../services/invoice.service";
import jsPDF from "jspdf";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import UserService from "../../services/user.service";
import { ReplaceFunction } from "../../utils/constants";

const ExportPDF = ({ id }) => {
  const invoiceService = InvoiceService();
  const userService = UserService();
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);

  const exportInvoicePDF = async (id) => {
    const response = await invoiceService.invoiceDetails(id);
    const invoiceDetail = response.data.apiresponse.data.invoiceDetail;
    const userDetail = response.data.apiresponse.data.userDetail;

    const adminDetailResponse = await userService.getAdmin();
    const adminDetails = adminDetailResponse.data.apiresponse.data;

    const additionalUserVATAmount = invoiceDetail.totalServiceFee * 0.22;
    const additionalDealerVATAmount = "0,00";

    const totalTaxableValue =
      invoiceDetail.totalParkingCharge + invoiceDetail.totalServiceFee;

    const totalAmount = totalTaxableValue + additionalUserVATAmount;

    const totalRefills =
      invoiceDetail.totalRefillAmount - invoiceDetail.totalCommisionAmount;

    const cashbackAmount =
      (invoiceDetail.cashbackAmount * 100) / invoiceDetail.totalParkingCharge;

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape
    const textColor = [0, 0, 0];
    const pageWidth = 570.7;

    const doc = new jsPDF(orientation, unit, size);

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 30, 10, 150, 60);

    // set data table columns names
    const newData = data1.map((elt) => []);
    newData.push([
      {
        content:
          userDetail.role === "DEALER" ? "Ricevuta:" : "Fattura di cortesia:",
        // rowSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
      {
        content: invoiceDetail.courtesyReceipt
          ? invoiceDetail.courtesyReceipt
          : "--",
        // rowSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 0.5, right: 0.5, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);

    newData.push([
      {
        content: "Data:",
        // rowSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 0, right: 0, bottom: 0.5, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
      {
        content: invoiceDetail.creationDate
          ? moment(invoiceDetail.creationDate).format("DD/MM/YYYY")
          : "--",
        // rowSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 0, right: 0.5, bottom: 0.5, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);

    newData.push([
      {
        content: "Cod. Cliente:",
        styles: {
          halign: "left",
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
      {
        content: userDetail.id ? userDetail.id : "--",
        styles: {
          halign: "left",
          lineWidth: { top: 0.5, right: 0.5, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    newData.push([
      {
        content: "P.IVA Cliente:",
        styles: {
          halign: "left",
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
      {
        content: userDetail.vat ? userDetail.vat : "--",
        styles: {
          halign: "left",
          lineWidth: { top: 0, right: 0.5, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    newData.push([
      {
        content: "C. Fisc. Cliente: ",
        styles: {
          halign: "left",
          lineWidth: { top: 0, right: 0, bottom: 0.5, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
      {
        content: userDetail.taxCode ? userDetail.taxCode : "--",
        styles: {
          halign: "left",
          lineWidth: { top: 0, right: 0.5, bottom: 0.5, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    newData.push([
      {
        content: "Pagamento",
        // rowSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: 10,
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
      {
        content: "",
        // rowSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: 9,
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    newData.push([
      {
        content: userDetail.preferredPaymentType
          ? userDetail.preferredPaymentType
          : "--",
        styles: {
          halign: "left",
          // margin: { left: 80 },
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    newData.push([
      {
        content: "",
        styles: {
          halign: "left",
          // margin: { left: 80 },
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);

    const table2 = data2.map((elt) => []);
    table2.push([
      {
        content: "Spett.le",
        // rowSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: 10,
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    table2.push([
      {
        content: userDetail.username
          ? userDetail.username + " " + userDetail.surname
          : userDetail.email,
        styles: {
          halign: "left",
          // margin: { left: 80 },
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    const table3 = data3.map((elt) => []);
    table3.push([
      {
        content: userDetail.address
          ? userDetail.address +
            " " +
            userDetail.town +
            " " +
            userDetail.province
          : "--",
        // rowSpan: 1,
        styles: {
          halign: "left",
          // fontStyle: "bold",
          fontSize: 9,
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    table3.push([
      {
        content: userDetail.cap ? userDetail.cap : "--",
        styles: {
          halign: "left",
          // margin: { left: 80 },
          fontSize: 9,
          cellPadding: { top: 0, bottom: 10, left: 5 },
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    const table4 = data4.map((elt) => []);
    table4.push([
      {
        content: `Banca di Appoggio: ${
          adminDetails.supportedBank ? adminDetails.supportedBank : "--"
        } CC: ${adminDetails.cc ? adminDetails.cc : "--"} CIN: ${
          adminDetails.cin ? adminDetails.cin : "--"
        }`,
        styles: {
          halign: "left",
          lineWidth: { top: 0.5, right: 0.5, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    table4.push([
      {
        content: adminDetails.bankName
          ? `${adminDetails.bankName} Banca`
          : "--",
        styles: {
          halign: "left",
          lineWidth: { top: 0, right: 0.5, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);
    table4.push([
      {
        content: `IBAN: ${adminDetails.iban ? adminDetails.iban : "--"}`,
        styles: {
          halign: "left",
          lineWidth: { top: 0, right: 0.5, bottom: 0, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
        },
      },
    ]);

    const lastTable = data5.map((elt) => []);
    lastTable.push([
      {
        content: invoiceDetail.expiryDate
          ? moment(invoiceDetail.expiryDate).format("DD/MM/YYYY")
          : "--",
        styles: {
          halign: "left",
          lineWidth: { top: 0.5, right: 0, bottom: 0.5, left: 0.5 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
          cellWidth: 130,
        },
      },
      {
        content:
          userDetail.role === "DEALER"
            ? ReplaceFunction(totalRefills?.toFixed(2))
            : ReplaceFunction(totalAmount?.toFixed(2)),
        styles: {
          halign: "right",
          lineWidth: { top: 0.5, right: 0, bottom: 0.5, left: 0 }, // Border width
          lineColor: [128, 128, 128], // Border color (black)
          cellWidth: 130,
        },
      },
      {
        content:
          userDetail.role === "DEALER"
            ? "Vi segnaliamo che alla data di scadenza maturano interessi di mora automatici per effetto del DLGS N. 231 del 09.10.02 direttiva 2000/35/CE."
            : " Si prega di notare che gli interessi di mora maturano alla data di scadenza automatico per effetto del D.Lgs. n.231 del 09.10.02 direttiva 2000/35/CE",
        styles: {
          halign: "center",
          lineWidth: { top: 0.5, right: 0.5, bottom: 0.5, left: 0.5 }, // Border width
          cellPadding: { top: 10, right: 5, bottom: 10, left: 5 },
          lineColor: [128, 128, 128], // Border color (black),
          fontSize: 7,
        },
      },
    ]);

    const title = "Contributo Conai assolto ove dovuto.";
    doc.setFontSize(7);
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.setFont("helvetica", "normal");
    doc.text(title, 40, 585);

    // vat table
    const VatNewNumberBody = [
      {
        vat: userDetail.role === "DEALER" ? "0" : "22",
        description: userDetail.role === "DEALER" ? "N.I.ART.74" : "IVA 22%",
        taxable:
          userDetail.role === "DEALER"
            ? "-" +
              ReplaceFunction(invoiceDetail.totalCommisionAmount?.toFixed(2))
            : ReplaceFunction(invoiceDetail.totalServiceFee?.toFixed(2)),
        vatAmount:
          userDetail.role === "DEALER"
            ? additionalDealerVATAmount
            : ReplaceFunction(additionalUserVATAmount?.toFixed(2)),
      },
      {
        vat: 0,
        description:
          userDetail.role === "DEALER" ? "Esente Art. 15 - N1" : "N.I.ART.74",
        taxable:
          userDetail.role === "DEALER"
            ? ReplaceFunction(invoiceDetail.totalRefillAmount?.toFixed(2))
            : ReplaceFunction(invoiceDetail.totalParkingCharge?.toFixed(2)),
        vatAmount: userDetail.role === "DEALER" ? "00,00" : "0,00",
      },
    ];

    // vat table
    const VatnewNumber = [
      { content: "C.Iva", colSpan: 1, styles: { cellWidth: 50 } },
      { content: "Descrizione", colSpan: 1, styles: { cellWidth: 170 } },
      { content: "Imponibile IVA", colSpan: 1 },
      { content: "Importo IVA", colSpan: 1 },
    ];

    // vat table
    const headStylesForVatTable = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "left",
      fontStyle: "bold",
      fontSize: 9,
      lineWidth: { top: 0.5, right: 0.5, bottom: 1.5, left: 0.5 },
      lineColor: [128, 128, 128],
    };

    // vat table
    const bodyStylesForVatTable = {
      fillColor: "#ffffff",
      halign: "left",
      textColor: "black",
      cellPadding: { left: 5, right: 5, top: 3, bottom: 3 },
      fontSize: 8,
      lineWidth: 0.5,
      lineColor: [128, 128, 128],
    };

    // vat table
    const columnStylesForVatTable = {
      fillColor: "#ffffff",
      0: { halign: "center" }, // Align the content to the right for the last column\
      2: { halign: "right" },
      3: { halign: "right" },
    };

    // vat table
    const alternateRowVatStyles = {
      fillColor: "#ffffff", // Background color for alternating rows
      textColor: [...textColor], // Text color for alternating rows
      lineWidth: 0.5,
      lineColor: [128, 128, 128],
    };

    // vat table
    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 590, // Adjust the startY value as needed
        head: [VatnewNumber], // Use the new table header
        body: VatNewNumberBody.map((row) =>
          Object.values(row).map((value) => String(value))
        ), // Use the new table data
        headStyles: headStylesForVatTable,
        bodyStyles: bodyStylesForVatTable,
        columnStyles: columnStylesForVatTable,
        alternateRowStyles: alternateRowVatStyles,
        margin: { right: 180 },
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    // Courtesy invoice
    const invoiceTable = [
      {
        content: "",
        colSpan: 1,
        styles: {
          cellWidth: 130,
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0 },
        },
      },
      {
        content: "",
        colSpan: 1,
        styles: {
          cellWidth: 130,
          lineWidth: { top: 0, right: 0, bottom: 0.5, left: 0 },
        },
      },
      {
        content: "",
        colSpan: 1,
        styles: {
          cellWidth: 60,
          lineWidth: { top: 0, right: 0, bottom: 0.5, left: 0 },
        },
      },
      {
        content: "",
        styles: {
          cellWidth: 60,
          lineWidth: { top: 0, right: 0, bottom: 0.5, left: 0 },
        },
      },
      {
        content:
          userDetail.role === "DEALER" ? "Ricevuta:" : "Fattura di cortesia:",
        colSpan: 1,
      },
    ];

    //  Courtesy invoice
    const invoiceDataTable = [
      {
        deadlines: userDetail.role === "DEALER" ? "Scadenze" : "Pagato",
        totals: "Totali",
        totals1:
          userDetail.role === "DEALER"
            ? ReplaceFunction(totalRefills?.toFixed(2))
            : ReplaceFunction(totalTaxableValue?.toFixed(2)),
        totals2:
          userDetail.role === "DEALER"
            ? additionalDealerVATAmount
            : ReplaceFunction(additionalUserVATAmount?.toFixed(2)),
        totals3:
          userDetail.role === "DEALER"
            ? ReplaceFunction(totalRefills?.toFixed(2))
            : ReplaceFunction(totalAmount?.toFixed(2)),
      },
    ];

    // Courtesy invoice
    const headStylesForInvoiceTable = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      fontStyle: "bold",
      fontSize: 10,
      lineWidth: { top: 0.5, right: 0.5, bottom: 0, left: 0.5 },
      lineColor: [128, 128, 128],
    };

    // Courtesy invoice
    const bodyStylesForInvoiceTable = {
      fillColor: "#ffffff",
      halign: "left",
      textColor: "black",
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 9,
      lineWidth: 0.5,
      lineColor: [128, 128, 128],
    };

    // Courtesy invoice
    const columnStylesForInvoiceTable = {
      fillColor: "#ffffff",
      0: {
        halign: "left",
        fontStyle: "bold",
        lineWidth: { top: 0, right: 0.5, bottom: 0, left: 0 },
      }, // Align the content to the right for the last column\
      1: {
        halign: "right",
        fontStyle: "bold",
        lineWidth: {
          top: 0.5,
          right: 0.5,
          bottom: 0.5,
          left: 0.5,
        },
      },
      2: {
        halign: "right",
        lineWidth: {
          top: 0.5,
          right: 0.5,
          bottom: 0.5,
          left: 0.5,
        },
      },
      3: {
        halign: "right",
        lineWidth: {
          top: 0.5,
          right: 0.5,
          bottom: 0.5,
          left: 0.5,
        },
      },
      4: {
        halign: "right",
        lineWidth: {
          top: 0.5,
          right: 0.5,
          bottom: 0.5,
          left: 0.5,
        },
      },
    };

    // Courtesy invoice
    const alternateRowInvoiceStyles = {
      fillColor: "#ffffff", // Background color for alternating rows
      textColor: [...textColor], // Text color for alternating rows
      lineWidth: 0.5,
      lineColor: [128, 128, 128],
    };

    const headStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      // fontStyle: "bold",
      fontSize: 13,
      lineWidth: { top: 0, right: 0, bottom: 1.5, left: 0 },
      lineColor: [...textColor],
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 3, bottom: 3 },
      fontSize: 9,
    };

    const columnStyles = {
      4: { halign: "right" }, // Align the content to the right for the last column
    };

    const alternateRowStyles = {
      fillColor: "#ffffff", // Background color for alternating rows
      textColor: [...textColor], // Text color for alternating rows
    };

    // item table
    const newDataForNewTable = [
      {
        item:
          userDetail.role === "DEALER" ? "RICARICA APP PDR" : "PARCHEGGIO APP",
        Description: `${
          userDetail.role === "DEALER" ? "RICARICA APP PDR" : "Parcheggi"
        } ${
          userDetail.role === "DEALER"
            ? "ricariche app per il periodo dal"
            : "per il periodo da"
        } ${moment(invoiceDetail.fromDate).format("DD/MM/YYYY")} al ${moment(
          invoiceDetail.toDate
        ).format("DD/MM/YYYY")}`,
        qty: 1,
        // userDetail.role === "DEALER"
        //   ? invoiceDetail.totalRefills
        //   : invoiceDetail.totalStops,
        UoM: "Pezzo",
        price:
          userDetail.role === "DEALER"
            ? `€${ReplaceFunction(invoiceDetail.totalRefillAmount?.toFixed(2))}`
            : invoiceDetail.totalParkingCharge === 0
            ? "0,00"
            : `€${ReplaceFunction(
                invoiceDetail.totalParkingCharge?.toFixed(2)
              )}`,
        discount: "0",
        amount:
          userDetail.role === "DEALER"
            ? `€${ReplaceFunction(invoiceDetail.totalRefillAmount?.toFixed(2))}`
            : invoiceDetail.totalParkingCharge === 0
            ? "0,00"
            : `€${ReplaceFunction(
                invoiceDetail.totalParkingCharge?.toFixed(2)
              )}`,
        vat: "0,00",
      },
      {
        item:
          userDetail.role === "DEALER" ? "AGGIOPROVVIGIONE" : "SERVICEFEEAPP",
        Description: `${
          userDetail.role === "DEALER"
            ? "Aggio/Provvigione Neos Tech"
            : "Service fee parcheggi APP"
        } per il periodo dal ${moment(invoiceDetail.fromDate).format(
          "DD/MM/YYYY"
        )} al ${moment(invoiceDetail.toDate).format("DD/MM/YYYY")}`,
        qty:
          // userDetail.role === "DEALER"
          //   ? invoiceDetail.totalRefills
          //   : invoiceDetail.totalStops,
          1,
        UoM: "Pezzo",
        price:
          userDetail.role === "DEALER"
            ? "-" +
              `€${ReplaceFunction(
                invoiceDetail.totalCommisionAmount?.toFixed(2)
              )}`
            : invoiceDetail.totalServiceFee === 0
            ? "0,00"
            : `€${ReplaceFunction(invoiceDetail.totalServiceFee?.toFixed(2))}`,
        discount: "0",
        amount:
          userDetail.role === "DEALER"
            ? "-" +
              `€${ReplaceFunction(
                invoiceDetail.totalCommisionAmount?.toFixed(2)
              )}`
            : invoiceDetail.totalServiceFee === 0
            ? "0,00"
            : `€${ReplaceFunction(invoiceDetail.totalServiceFee?.toFixed(2))}`,
        vat: userDetail.role === "DEALER" ? "0,0" : "22,00",
      },
    ];
    // item table
    const headerForNewTable = [
      { content: "Articolo", colSpan: 1, styles: { cellWidth: 80 } },
      { content: "Descrizione", colSpan: 1, styles: { cellWidth: 170 } },
      { content: "Qta'", colSpan: 1 },
      { content: "UdM", colSpan: 1 },
      { content: "Prezzo €", colSpan: 1 },
      { content: "Sconto %", colSpan: 1 },
      { content: "Importo €", colSpan: 1 },
      { content: "IVA", colSpan: 1 },
    ];

    // item table
    const headStylesForNewTable = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "left",
      fontStyle: "bold",
      fontSize: 9,
      lineWidth: { top: 0.5, right: 0.5, bottom: 1.5, left: 0.5 },
      lineColor: [128, 128, 128],
    };

    // item table
    const bodyStylesForNewTable = {
      fillColor: "#ffffff",
      halign: "left",
      textColor: "black",
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 8,
    };

    // item table
    const columnStylesForNewTable = {
      fillColor: "#ffffff",
      2: { halign: "right" }, // Align the content to the right for the last column
    };

    // item table
    const alternateRowNewStyles = {
      fillColor: "#ffffff", // Background color for alternating rows
      textColor: [...textColor], // Text color for alternating rows
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 80,
        // head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
        margin: { right: 310 },
      });
      doc.autoTable({
        startY: 81, // Adjust the startY as needed to align the tables
        startX: doc.autoTable.previous.finalX + 10,
        // head: headers,
        body: table2,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
        margin: { left: 290 },
      });
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 5, // Adjust the startY to set table3 below table2
        startX: doc.autoTable.previous.finalX + 10,
        // head: headers,
        body: table3,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
        margin: { left: 290 },
      });
      doc.autoTable({
        startY: doc.autoTable.previous.finalY - 5, // Adjust the startY to set table3 below table2
        startX: doc.autoTable.previous.finalX + 10,
        // head: headers,
        body: table4,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
        margin: { left: 285 },
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    // item table
    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 200, // Adjust the startY value as needed
        head: [headerForNewTable], // Use the new table header
        body: newDataForNewTable.map((row) =>
          Object.values(row).map((value) => String(value))
        ), // Use the new table data
        headStyles: headStylesForNewTable,
        bodyStyles: bodyStylesForNewTable,
        columnStyles: columnStylesForNewTable,
        alternateRowStyles: alternateRowNewStyles,
      });

      const footer1 = `${adminDetails.username} ${adminDetails.surname}: ${adminDetails.address}, ${adminDetails.cap} ${adminDetails.town}`;
      const footer1Width = doc.getStringUnitWidth(footer1) * 8;
      const footer1Center = (pageWidth - footer1Width) / 2;
      doc.setFontSize(8);
      doc.setDrawColor(...textColor); // Border color (black)
      doc.setTextColor(...textColor); // Text color (black)
      doc.setFont("helvetica", "normal");
      doc.text(footer1, footer1Center, 770);

      const footer2 = `Tel: ${adminDetails.phoneNo} Fax: ${adminDetails.fax} e-mail: ${adminDetails.email}`;
      const footer2Width = doc.getStringUnitWidth(footer2) * 8;
      const footer2Center = (pageWidth - footer2Width) / 2;
      doc.setFontSize(8);
      doc.setDrawColor(...textColor); // Border color (black)
      doc.setTextColor(...textColor); // Text color (black)
      doc.setFont("helvetica", "normal");
      doc.text(footer2, footer2Center, 781);

      const footer3 = `C.F.: ${adminDetails.taxCode} and P.I.:${adminDetails.vat}`;
      const footer3Width = doc.getStringUnitWidth(footer3) * 7;
      const footer3Center = (pageWidth - footer3Width) / 2;
      doc.setFontSize(7);
      doc.setDrawColor(...textColor); // Border color (black)
      doc.setTextColor(...textColor); // Text color (black)
      doc.setFont("helvetica", "normal");
      doc.text(footer3, footer3Center, 790);
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }
    // invoice table
    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 665, // Adjust the startY value as needed
        head: [invoiceTable], // Use the new table header
        body: invoiceDataTable.map((row) =>
          Object.values(row).map((value) => String(value))
        ), // Use the new table data
        headStyles: headStylesForInvoiceTable,
        bodyStyles: bodyStylesForInvoiceTable,
        columnStyles: columnStylesForInvoiceTable,
        alternateRowStyles: alternateRowInvoiceStyles,
      });
      doc.autoTable({
        startY: 707,
        body: lastTable,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }
    doc.save(
      `${userDetail.username}_${
        userDetail.role === "DEALER" ? "Receipt" : "Invoice"
      }.pdf`
    );
  };

  return (
    <div>
      <button
        className="btn btn-primary btn-sm me-2"
        onClick={() => exportInvoicePDF(id)}
      >
        <i className="fas fa-file-pdf"></i>
      </button>
    </div>
  );
};

export default ExportPDF;
