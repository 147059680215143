import "./assets/css/style.css";
import React, { useEffect } from "react";
import "./index.scss";
import Router from "./app/router/routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Auth, { setLoggedIn, setToken } from "./store/slice/auth";
import "bootstrap-daterangepicker/daterangepicker.css";

function App() {
  const auth = Auth();
  const dispatch = useDispatch();

  const neosStorageString = localStorage.getItem("neos-storage");
  const neosStorage = JSON.parse(neosStorageString);

  useEffect(() => {
    if (neosStorage && neosStorage.token) {
      auth.setToken(localStorage.getItem("token"));
    }
    if (neosStorage && neosStorage.isLoggedIn) {
      dispatch(
        auth.setLoggedIn(JSON.parse(neosStorage && neosStorage.isLoggedIn))
      );
    }
    // eslint-disable-next-line
  }, []);

  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const storedIsLoggedIn = neosStorage && neosStorage.isLoggedIn;
    dispatch(auth.setLoggedIn(storedIsLoggedIn));
  }, [isLoggedIn]);

  const isLoggedin = isLoggedIn || (neosStorage && neosStorage.token);

  return (
    <>
      <React.Suspense>
        <Router isLoggedIn={isLoggedin} />
      </React.Suspense>
    </>
  );
}

export default App;
