import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const HolidayService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getHoliday = (startDate, endDate) => {
    const response = serverCall.customAxios.get(
      `/holiday/?endDate=${endDate}&startDate=${startDate}`,
      { headers }
    );
    return response;
  };

  const getHolidaybyId = (id) => {
    const response = serverCall.customAxios.get(`/holiday/${id}`, { headers });
    return response;
  };

  const deleteHoliday = (id) => {
    const response = serverCall.customAxios.delete(`/holiday/${id}`, {
      headers,
    });
    return response;
  };

  const addHoliday = (body) => {
    const response = serverCall.customAxios.post(`/holiday/`, body, {
      headers,
    });
    return response;
  };

  const updateHoliday = (body) => {
    const response = serverCall.customAxios.put(`/holiday/`, body, { headers });
    return response;
  };

  return {
    addHoliday,
    getHoliday,
    updateHoliday,
    getHolidaybyId,
    deleteHoliday,
  };
};

export default HolidayService;
