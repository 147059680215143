import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import withLoader from "../../layout/Loader/WithLoader";
import ParkingSlotService from "../../services/parkingSlot.service";
import AreaService from "../../services/area.service";
import moment from "moment";
import Enums from "../../utils/enums";
import { useTranslation } from "react-i18next";
import DeleteParking from "./DeleteParking";
import { enqueueSnackbar } from "notistack";
import {
  ANCHORORIGIN,
  ERROR,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";

const ViewParkingSlots = () => {
  const parkingSlotService = ParkingSlotService();
  const areaService = AreaService();
  const { t } = useTranslation("common");
  const { DAYS } = Enums();
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [areaData, setAreaData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [details, setDetails] = useState({});

  const getParkingById = async () => {
    const response = await parkingSlotService.getParkingById(id);
    const result = response.data.apiresponse.data;
    const newData = response.data.apiresponse.data.slots.map(
      (slot, index) => {}
    );
    setData(response.data.apiresponse.data);
    setDetails({ name: result.name, id });
    getAreaById(response.data.apiresponse.data.areaId);
  };

  const getAreaById = async (id) => {
    const response = await areaService.getAreaById(id);
    setAreaData(response.data.apiresponse.data);
  };

  const deleteParking = async (id) => {
    try {
      await parkingSlotService.removeParking(id);
      setModalShow(false);
      enqueueSnackbar(t("SUCCESSFULLY_PARKING_DELETED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      navigate("/parking/");
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  useEffect(() => {
    getParkingById();
  }, []);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">{t("parkingdetails")}</h2>
        {modalShow && (
          <DeleteParking
            show={modalShow}
            onHide={() => setModalShow(false)}
            deleteParking={deleteParking}
            details={details}
          />
        )}
        <Row className="row row-sm">
          <Col sm={12} lg={12} xl={12}>
            <Card className="custom-card">
              <Card.Body>
                <div className="border-bottom mb-3">
                  <FormGroup className="form-group">
                    <Row className=" row-sm">
                      <div className="ms-auto d-flex justify-content-end">
                        <Link
                          to={`/parkhistory/`}
                          state={{
                            area: data.areaId,
                            city: data.area?.cityDetails?.id,
                          }}
                          className="btn btn-sm btn-primary"
                        >
                          <i className="si si-docs"></i> {t("parkhistory")}
                        </Link>
                        <Link
                          to={`/parking/parkingslot`}
                          state={{ id: id }}
                          className="btn btn-sm btn-primary ms-2"
                        >
                          <i className="fe fe-edit"></i> {t("edit")}
                        </Link>

                        <button
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => setModalShow(true)}
                        >
                          <i className="fe fe-trash"></i> {t("delete")}
                        </button>
                      </div>
                    </Row>
                  </FormGroup>
                </div>
                <div>
                  <div className="row">
                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("name")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={data.name} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("area")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={areaData.name} disabled />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("businessuser")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={areaData.userDetails?.username}
                            disabled
                          />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("pincode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={areaData?.pincode} disabled />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("servidefee(percentage)")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control value={areaData?.serviceFee} disabled />
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("minimimservicefee(amount)")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              areaData?.minServiceFee
                                ? `€${ReplaceFunction(
                                    areaData?.minServiceFee?.toFixed(2)
                                  )}`
                                : areaData?.minServiceFee
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("easyparkcode")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={areaData?.easyParkCode}
                            disabled
                          />
                        </Col>

                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("minimumcost")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              areaData.minCost ? `${areaData.minCost}` : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Row className=" row-sm">
                        <Col md={2} className="d-flex align-items-center">
                          <Form.Label className="text-dark">
                            {t("initialfreetime(minutes)")} :
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            value={
                              areaData.freeTime
                                ? areaData.freeTime / (60 * 1000)
                                : "--"
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <hr />

                    {data.slots &&
                      data.slots
                        .slice() // Create a shallow copy of the array to avoid modifying the original
                        .sort((a, b) => {
                          if (!a.periodRequired && b.periodRequired) {
                            return 1;
                          }
                          // If a has periodRequired true and b has periodRequired false, a should come before b
                          else if (a.periodRequired && !b.periodRequired) {
                            return -1;
                          }
                          // For other cases, sort based on the fromDate property
                          else {
                            const fromTimeA = moment.utc(a.fromDate);
                            const fromTimeB = moment.utc(b.fromDate);
                            return fromTimeA.isBefore(fromTimeB)
                              ? -1
                              : fromTimeA.isAfter(fromTimeB)
                              ? 1
                              : 0;
                          }
                        })
                        .map((slot, index) => (
                          <div key={index}>
                            <h5 className="text-dark mb-3">
                              {t("slot")} {index + 1} :
                            </h5>
                            <FormGroup className="form-group">
                              <Row className=" row-sm">
                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("periodvalidity")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={
                                      slot.periodRequired
                                        ? slot.isyearly
                                          ? moment(slot.fromDate).format(
                                              "DD/MM/YYYY"
                                            ) +
                                            " - " +
                                            moment(slot.toDate).format(
                                              "DD/MM/YYYY"
                                            )
                                          : moment(slot.fromDate).format(
                                              "DD/MM"
                                            ) +
                                            " - " +
                                            moment(slot.toDate).format("DD/MM")
                                        : "-"
                                    }
                                    disabled
                                  />
                                </Col>

                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("costoccurance")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={
                                      slot.costOccurance
                                        ? slot.costOccurance
                                        : "--"
                                    }
                                    disabled
                                  />
                                </Col>
                              </Row>
                            </FormGroup>

                            <FormGroup className="form-group">
                              <Row className=" row-sm">
                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("dayvalidity")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={slot.dayValidity}
                                    disabled
                                  />
                                </Col>
                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("days")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    as="textarea"
                                    rows={1}
                                    value={slot.days
                                      .map((dayValue) => DAYS[dayValue].label)
                                      .join(", ")}
                                    disabled
                                  />
                                </Col>
                              </Row>
                            </FormGroup>

                            {slot.costOccurance !== "DAY" && (
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("firstPeriod")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        slot.firstPeriod ? slot.firstPeriod : 0
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("firstCost")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        slot.firstCost
                                          ? `€${slot.firstCost}`
                                          : 0
                                      }
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                            )}

                            <FormGroup className="form-group">
                              <Row className=" row-sm">
                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("cost")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={
                                      slot.cost
                                        ? `€${ReplaceFunction(
                                            slot.cost?.toFixed(2)
                                          )}`
                                        : "--"
                                    }
                                    disabled
                                  />
                                </Col>

                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("maxparktime(hour)")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={slot.maxParkTime}
                                    disabled
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            {slot.costOccurance !== "DAY" && (
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("ratefrequency")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={slot.rateFrequency}
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("ratefrequencyunit")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={slot.rateFrequencyUnit}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                            )}
                            <FormGroup className="form-group">
                              <Row className=" row-sm">
                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("hours")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={
                                      moment
                                        .utc(slot.fromTime)
                                        .format("HH:mm") +
                                      " - " +
                                      moment.utc(slot.toTime).format("HH:mm")
                                    }
                                    disabled
                                  />
                                </Col>
                                {/* <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("minimumcost")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={
                                      slot.minCost ? `€${slot.minCost}` : "--"
                                    }
                                    disabled
                                  />
                                </Col> */}

                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("lunchtime")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  {slot.fromLaunchTime ? (
                                    <Form.Control
                                      value={
                                        moment
                                          .utc(slot.fromLaunchTime)
                                          .format("HH:mm") +
                                        " - " +
                                        moment
                                          .utc(slot.toLaunchTime)
                                          .format("HH:mm")
                                      }
                                      disabled
                                    />
                                  ) : (
                                    <Form.Control value="0" disabled />
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className="form-group">
                              <Row className=" row-sm">
                                <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("isyearly")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={slot.isyearly ? "Yes" : "No"}
                                    disabled
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup className="form-group">
                              <Row className=" row-sm">
                                {/* <Col
                                  md={2}
                                  className="d-flex align-items-center"
                                >
                                  <Form.Label className="text-dark">
                                    {t("initialfreetime(minutes)")} :
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    value={
                                      slot.freeTime
                                        ? slot.freeTime / 60000
                                        : "--"
                                    }
                                    disabled
                                  />
                                </Col> */}
                              </Row>
                            </FormGroup>
                            {data.slots.length > 1 && <hr className="mt-3" />}
                          </div>
                        ))}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default withLoader(ViewParkingSlots);
