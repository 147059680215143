import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const TransactionService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getDealerToUserRefills = async (body) => {
    const response = await serverCall.customAxios.post(
      `/transaction/user/dealer/refills`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const paymentDetails = async (id) => {
    const response = await serverCall.customAxios.get(`/transaction/${id}`, {
      headers,
    });
    return response;
  };

  const appproveDisputeRequest = async (
    dealerCredit,
    requestedId,
    userAmount
  ) => {
    const response = await serverCall.customAxios.put(
      `/transaction/recharge/admin/dispute?dealerCredit=${dealerCredit}&requestedId=${requestedId}&userAmount=${userAmount}`,
      {
        headers,
      }
    );
    return response;
  };

  const changeStatusOfRechargeByDealer = async (
    reasonCancel,
    requestedId,
    status
  ) => {
    const response = await serverCall.customAxios.put(
      `/transaction/recharge/user/status?reasonCancel=${reasonCancel}&requestedId=${requestedId}&status=${status}`,
      {
        headers,
      }
    );
    return response;
  };

  const createOrder = async (amount) => {
    const response = await serverCall.customAxios.post(
      `/transaction/order/dealer?amount=${amount}`,
      {
        headers,
      }
    );
    return response;
  };

  const getManualRechargeOfUserByDealer = async (data) => {
    const response = await serverCall.customAxios.post(
      `/transaction/manual/dealer/user`,
      data,
      {
        headers,
      }
    );
    return response;
  };

  const getDealerRefills = async (data) => {
    const response = await serverCall.customAxios.post(
      `/transaction/dealer/refills`,
      data,
      {
        headers,
      }
    );
    return response;
  };

  const makeManualRecharge = async (amount, userId) => {
    const response = await serverCall.customAxios.post(
      `/transaction/manual/user?amount=${amount}&userId=${userId}`,
      {
        headers,
      }
    );
    return response;
  };

  const changeStatusOfDealerRequest = async (
    status,
    reasonCancel,
    rechargeLimit,
    requestId
  ) => {
    const response = await serverCall.customAxios.put(
      `/transaction/dealer/status/${status}?reasonCancel=${reasonCancel}&rechargeLimit=${rechargeLimit}&requestId=${requestId}`,
      {
        headers,
      }
    );
    return response;
  };

  const getUserRefillsAll = async (data) => {
    const response = await serverCall.customAxios.post(
      `/transaction/all/user/refills`,
      data,
      { headers }
    );
    return response;
  };

  return {
    getDealerToUserRefills,
    paymentDetails,
    appproveDisputeRequest,
    changeStatusOfRechargeByDealer,
    createOrder,
    getManualRechargeOfUserByDealer,
    getDealerRefills,
    makeManualRecharge,
    changeStatusOfDealerRequest,
    getUserRefillsAll,
  };
};

export default TransactionService;
