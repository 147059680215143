import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Container,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import OTPInput from "otp-input-react";
import Countdown from "react-countdown";
import AuthService from "../../services/auth.service";
import { OTP_VERIFIED } from "../../utils/messages";
import {
  ANCHORORIGIN,
  ERROR,
  FORMIKERROR,
  SUCCESS,
} from "../../utils/constants";
import "react-toastify/dist/ReactToastify.min.css";
import SetNewPassword from "./SetNewPassword";
import { useTranslation } from "react-i18next";
import IT from "../../../assets/img/it.svg";
import US from "../../../assets/img/us.png";

const VerifyOTP = ({ email }) => {
  const authService = AuthService();
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(localStorage.getItem("language"));
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [token, setToken] = useState("");
  const [OTP, setOTP] = useState("");
  const [showOTPVerify, setShowOTPVerify] = useState(true);

  const initialTime =
    parseInt(localStorage.getItem("initialTime")) || Date.now();
  const expiryTime = initialTime + 120000;

  const currentTime = Date.now();
  const timeRemaining = Math.max(0, expiryTime - currentTime);

  const [remainingTime, setRemainingTime] = useState(timeRemaining);

  useEffect(() => {
    localStorage.setItem("initialTime", initialTime.toString());
  }, [initialTime]);

  const handleLanguageChange = (e) => {
    window.localStorage.setItem("language", e);
    i18n.changeLanguage(e);
    setValue(e);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      const newRemainingTime = Math.max(0, expiryTime - currentTime);
      setRemainingTime(newRemainingTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [expiryTime]);

  const handleChange = (OTP) => {
    setOTP(OTP);
    formik.setFieldValue("otp", OTP);
  };

  const handleFormSubmit = async (values) => {
    const { otp } = values;

    try {
      setLoading(true);
      const response = await authService.verifyOTP(email, otp);

      const token = response.data.apiresponse.data;
      setToken(token);
      localStorage.removeItem("initialTime");

      // Set New Password Page Call and Verify OTP Page Remove
      setShowNewPassword(true);
      setShowOTPVerify(false);

      enqueueSnackbar(t("OTP_VERIFIED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    otp: "",
  };

  const validationSchema = yup.object().shape({
    otp: yup.number().required("Please Enter OTP!"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <div className="tx-danger">{t("otpexpired")}!</div>;
    } else {
      return (
        <span className="tx-danger fw-bold">
          0{minutes}:{seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("language") !== null) {
      const lang = localStorage.getItem("language");
      i18n.changeLanguage(lang);
      setValue(lang);
    } else {
      setValue("en");
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    }
  }, []);

  return (
    <>
      {showNewPassword && <SetNewPassword email={email} token={token} />}
      {showOTPVerify && (
        <Fragment>
          <div className="d-flex align-items-center ms-3 mt-2">
            <span className="text-muted tx-15">{t("selectlanguage")} : </span>
            <Dropdown
              id="nav-dropdown-dark-example"
              title={"en"}
              className="main-profile-menu"
              onSelect={(e) => handleLanguageChange(e)}
            >
              <Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="text-dark"
              >
                {value === "it" ? (
                  <span>
                    {" "}
                    <img src={IT} alt="" width={"20px"} /> Italian
                  </span>
                ) : (
                  <span>
                    <img src={US} alt="" width={"20px"} /> English
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-lang-login">
                <Dropdown.Item
                  eventKey={"en"}
                  // className="dropdown-item"
                  className="header-navheading d-flex align-items-center"
                  style={{ cursor: "pointer", height: "45px" }}
                >
                  <span className="align-items-center">
                    <img src={US} alt="" width={"20px"} /> English
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={"it"}
                  className="header-navheading d-flex align-items-center"
                  style={{ cursor: "pointer", height: "45px" }}
                >
                  <span className="align-items-center">
                    <img src={IT} alt="" width={"20px"} /> Italian
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className="page main-signin-wrapper"
            style={{ marginTop: "-48px" }}
          >
            <Row className="signpages text-center">
              <Col md={12}>
                <Card style={{ height: "387px" }}>
                  <Row className="row-sm" style={{ height: "387px" }}>
                    <Col
                      lg={6}
                      xl={5}
                      className="d-flex justify-content-center d-lg-block text-center bg-primary details"
                    >
                      <div className="mt-5 pt-4 p-2 pos-absolute">
                        <img
                          src={
                            require("../../../assets/img/svgs/user.svg").default
                          }
                          className="ht-100 mb-0"
                          alt="user"
                        />
                        <h5 className="mt-4 text-white">
                          {t("createyouraccount")}
                        </h5>
                        <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                          {t("signuptocreatesentance")}
                        </span>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      xl={7}
                      xs={12}
                      sm={12}
                      className="login_form d-flex align-items-center"
                    >
                      <Container fluid>
                        <Row className="row-sm">
                          <Card.Body className="mt-2 mb-2">
                            <form onSubmit={formik.handleSubmit}>
                              <Form.Group
                                className="text-start form-group"
                                controlId="formEmail"
                              >
                                <h5 className="text-start mb-3 d-flex justify-content-center">
                                  {t("verifyotp")}
                                </h5>
                                <Form.Group
                                  className="text-start form-group"
                                  controlId="formEmail"
                                >
                                  <Form.Label className="text-align-center">
                                    {t("enteryourotp")} {email}
                                  </Form.Label>
                                  <div className="otp">
                                    <OTPInput
                                      value={OTP}
                                      onChange={handleChange}
                                      autoFocus
                                      OTPLength={6}
                                      inputClassName="inputStyle"
                                      otpType="number"
                                      name="otp"
                                      disabled={false}
                                      secure
                                    />
                                  </div>
                                  {formik.errors.otp && formik.touched.otp ? (
                                    <Form.Label style={FORMIKERROR}>
                                      {formik.errors.otp}
                                    </Form.Label>
                                  ) : null}
                                </Form.Group>
                                <div className="d-flex justify-content-center d-flex">
                                  <p className="me-1 m-0 text-dark">
                                    {t("otpexpiredin")}:
                                  </p>
                                  <Countdown
                                    date={Date.now() + remainingTime}
                                    renderer={renderer}
                                  />
                                </div>

                                {loading ? (
                                  <Button className="btn ripple btn-main-primary btn-block mt-2">
                                    <CircularProgress
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        color: "#ffffff",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-block mt-4 me-3"
                                    // disabled={loading}
                                  >
                                    {t("submit")}
                                  </button>
                                )}
                              </Form.Group>
                            </form>
                          </Card.Body>
                        </Row>
                        <ToastContainer />
                      </Container>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Fragment>
      )}
    </>
  );
};

VerifyOTP.propTypes = {};

VerifyOTP.defaultProps = {};

export default VerifyOTP;
