import React, { useState, useEffect } from "react";
import { Button, Card, Modal, Row } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import DataTable from "react-data-table-component";
import {
  ANCHORORIGIN,
  CUSTOM_STYLES,
  EMPTY,
  ERROR,
  PAGELIMIT,
  SUCCESS,
} from "../../utils/constants";
import { ScrollToTop } from "../../utils/ScrollTop";
import useDialogState from "../../hooks/useDialog";
import withLoader from "../../layout/Loader/WithLoader";
import UserService from "../../services/user.service";
import CityService from "../../services/city.service";
import AddController from "./AddController";
import "react-data-table-component-extensions/dist/index.css";
import BusinessPartnerService from "../../services/businessPartner.service";
import { SUCCESSFULLY_CHILD_PARTNER_DELETED } from "../../utils/messages";
import { useTranslation } from "react-i18next";

const ControllerAccounts = () => {
  const { t } = useTranslation("common");
  const businessPartnerService = BusinessPartnerService();
  const userService = UserService();
  const cityService = CityService();
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [newCity, setNewCity] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [details, setDetails] = useState({});

  const localStorageData = localStorage.getItem("neos-storage");
  const parsedData = JSON.parse(localStorageData);

  const id = parsedData.id;

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  // Get Child Business Partner data for data table list
  const getChildBusinessPartner = async () => {
    const response = await userService.getAllChildBusinessPartners(
      dataPerPage,
      pageNumber,
      id ? id : EMPTY,
      "CHILD_BUSINESS_PARTNER",
      searchText
    );
    ScrollToTop();

    setData(response.data.apiresponse.data.users);
  };

  // Searchinf of table
  const handlesearch = (e) => {
    setSearchText(e.target.value);
  };

  // Get All city Names
  const getAllCity = async () => {
    const response = await cityService.getCityByUserId(id);
    setNewCity(response.data.apiresponse.data);
  };

  // Selected City of the dropdown
  const selectCity = async (selectedOption) => {
    formik.setFieldValue(
      "cityIds",
      selectedOption.map((payment) => payment)
    );
  };

  // City Nmaes array
  const city = [
    ...newCity.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  ];

  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      childUserPassword: "",
      cityIds: [],
      town: "",
      parentUserId: id,
      role: "CHILD_BUSINESS_PARTNER",
    },

    validationSchema: yup.object().shape({
      userName: yup.string().required("Please Enter User Name!"),
      email: yup.string().email().required("Please Enter Email!"),
      childUserPassword: yup.string(),
      cityIds: yup.mixed().required("Please Enter City!"),
    }),
    onSubmit: async (values, action) => {
      const {
        id,
        userName,
        email,
        childUserPassword,
        cityIds,
        town,
        role,
        parentUserId,
      } = values;

      const ids = cityIds.map((id) => (id.value ? id.value : id));

      try {
        if (!values.id) {
          const response = await userService.addUser({
            username: userName,
            email,
            childUserPassword,
            cityIds: ids,
            town,
            role,
            parentUserId,
          });
        } else {
          const response = await userService.updateUser(id, {
            username: userName,
            email,
            cityIds: ids,
            town,
            role,
            parentUserId,
            childUserPassword,
          });
        }
        getChildBusinessPartner();
        // action.resetForm();
        // setNewCity([]);
        // closeDialog();
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    },
  });

  const closeDialog = () => {
    handleDialogClose();
    formik.resetForm();
  };

  // Data table columns and data
  const columns = [
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: "uniqueId",
      cell: (row) => (
        <div title={`${row.uniqueId}`}>
          {row.uniqueId ? row.uniqueId : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: "username",
      cell: (row) => (
        <div title={`${row.username}`}>
          {row.username ? row.username : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("email")}</strong>,
      selector: "email",
      cell: (row) => (
        <div title={`${row.email}`}>{row.email ? row.email : "--"}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("city")}</strong>,
      selector: "cities",
      cell: (row) => (
        <div title={`${row.cities}`}>{row.cities ? row.cities : "--"}</div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("creationdate")}</strong>,
      selector: "creationDate",
      cell: (row) => (
        <div
          title={`${
            row.creationDate
              ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
              : "--"
          }`}
        >
          {row.creationDate
            ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("action")}</strong>,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-2">
            <Link
              onClick={() => AddUserModal(row.id)}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-pencil"></i>
            </Link>
          </div>
          <div>
            <Link
              onClick={() => handleOpen(row.username, row.id)}
              className="btn btn-danger btn-sm"
            >
              <i className="fe fe-trash"></i>
            </Link>
          </div>
        </div>
      ),

      sortable: true,
    },
  ];

  const handleOpen = (name, id) => {
    setModalShow(true);
    setDetails({ name, id });
  };

  const deleteControllerAccount = async (id) => {
    try {
      await businessPartnerService.deleteChildBusinessPartner(id);
      enqueueSnackbar(t("SUCCESSFULLY_CHILD_PARTNER_DELETED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      setModalShow(false);
      getChildBusinessPartner();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  // Open Add & Update Modal
  const AddUserModal = async (id) => {
    handleDialogOpen();
    if (id !== "") {
      const response = await userService.getChildBusinessPartnersById(id);
      const result = response.data.apiresponse.data;

      // Add Defaut value when click on update
      const cityNames = result.cities.map((res) => res.cityDetail);
      const cityValues = cityNames.map((cityName) => ({
        value: cityName.id,
        label: cityName.name,
      }));

      setUpdate(true);
      formik.setFieldValue("id", result.user.id);
      formik.setFieldValue("userName", result.user.username);
      formik.setFieldValue("email", result.user.email);
      formik.setFieldValue("cityIds", cityValues);
    } else {
      // Empty Values when click on add
      setUpdate(false);
      formik.setFieldValue("id", "");
      formik.setFieldValue("userName", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("childUserPassword", "");
      formik.setFieldValue("cityIds", "");
    }
  };

  useEffect(() => {
    getChildBusinessPartner();
    getAllCity();
  }, []);

  useEffect(() => {
    getChildBusinessPartner();
  }, [dataPerPage, searchText]);

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("controlleraccount")}
      </h2>
      <Card>
        <div className="p-3">
          <Row>
            <div className="mb-3 d-flex">
              <button
                variant="danger"
                className="btn btn-primary btn-sm ms-2"
                onClick={() => AddUserModal("")}
              >
                <i className="fe fe-edit"></i> {t("add")}
              </button>
              <div className="d-flex add-search ms-auto">
                <div className="d-flex align-items-center md-form mt-auto ms-auto">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-md py-3"
                      value={searchText}
                      onChange={handlesearch}
                      placeholder={t("search") + "..."}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>

          {isDialogOpen && (
            <AddController
              city={city}
              show={isDialogOpen}
              onHide={closeDialog}
              formik={formik}
              selectCity={selectCity}
              newCity={newCity}
              update={update}
            />
          )}
          {modalShow && (
            <DeleteAccount
              show={modalShow}
              onHide={() => setModalShow(false)}
              details={details}
              deleteControllerAccount={deleteControllerAccount}
            />
          )}
          <DataTable
            title="Controlles Accounts"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={CUSTOM_STYLES}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(ControllerAccounts);

const DeleteAccount = (props) => {
  const { t } = useTranslation("common");
  const { details, deleteControllerAccount } = props;

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
            {t("delete") + " " + t("controlleraccount")}
          </h5>
        </Modal.Header>
        <Modal.Body className="text-center p-2 pb-4">
          <p className="mg-b-20 mg-x-20 fs-18 mt-3">
            {t("deletesentance")} {t("controlleraccount")} {t("of")}{" "}
            {details.name}?
          </p>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={() => deleteControllerAccount(details.id)}
              variant="danger"
              className="ms-3 me-4 btn-sm"
            >
              {t("delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
