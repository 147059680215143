import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
ChartJS.register(...registerables);

const PaidDealerDashboardGraph = ({
  totalAmountOfRechargeOfDay,
  totalRechargeOfDay,
}) => {
  const { t } = useTranslation("common");

  const getMonthlyAmountOfRechargeOfDayData = (data) => {
    const monthlyCounts = Array(12).fill(0);
    data.graph?.forEach((entry) => {
      const dateParts = entry.date.split("-");
      const month = parseInt(dateParts[1], 10);
      if (month >= 1 && month <= 12) {
        monthlyCounts[month - 1] += entry.totalAmount;
      }
    });

    return monthlyCounts;
  };
  const totalMonthlyAmountOfRechargeOfDay = getMonthlyAmountOfRechargeOfDayData(
    totalAmountOfRechargeOfDay
  );

  const gettotalMonthalyRechargeOfDays = (data) => {
    const monthlyCounts = Array(12).fill(0);
    data.graph?.forEach((entry) => {
      const dateParts = entry.date.split("-");
      const month = parseInt(dateParts[1], 10);
      if (month >= 1 && month <= 12) {
        monthlyCounts[month - 1] += entry.count;
      }
    });

    return monthlyCounts;
  };
  const totalMonthalyRechargeOfDays =
    gettotalMonthalyRechargeOfDays(totalRechargeOfDay);

  // Linechart
  const linechart = {
    labels: [
      t("jan"),
      t("feb"),
      t("mar"),
      t("apr"),
      t("may"),
      t("jun"),
      t("jul"),
      t("aug"),
      t("sep"),
      t("oct"),
      t("nov"),
      t("dec"),
    ],
    datasets: [
      {
        label: t("totalrechargeoftheday"),
        data: totalMonthalyRechargeOfDays,
        borderWidth: 3,
        backgroundColor: "transparent",
        borderColor: "#6259ca",
        pointBackgroundColor: "ffffff",
        pointRadius: 0,
        type: "line",
        tension: 0.4,
      },
      {
        label: t("totalamountrechargeoftheday"),
        data: totalMonthlyAmountOfRechargeOfDay,
        borderWidth: 3,
        backgroundColor: "transparent",
        borderColor: "#742774",
        pointBackgroundColor: "#ffffff",
        pointRadius: 0,
        type: "line",
        tension: 0.3,
      },
    ],
  };
  const linechartoptions = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
      },
      legend: {
        position: "top",
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          fontColor: "#c8ccdb",
        },
        barPercentage: 0.7,
        display: true,
        grid: {
          borderColor: "rgba(119, 119, 142, 0.2)",
        },
      },
      y: {
        display: true,
        grid: {
          borderColor: "rgba(119, 119, 142, 0.2)",
        },
        scaleLabel: {
          display: true,
          labelString: "Thousands",
          fontColor: "transparent",
        },
      },
    },
    interaction: {
      intersect: false,
    },
  };

  return (
    <Line
      options={linechartoptions}
      data={linechart}
      className="barchart chart-dropshadow2 ht-300 chartjs-render-monitor"
      height="100"
    />
  );
};

export default PaidDealerDashboardGraph;
