import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const CityService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getAllCity = async (pageNumber, pageSize, searchText) => {
    const response = await serverCall.customAxios.get(
      `/city/all?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`,
      { headers }
    );
    return response;
  };

  const addCity = async (body) => {
    const response = await serverCall.customAxios.post(`/city/`, body, {
      headers,
    });
    return response;
  };

  const updateCity = async (id, body) => {
    const response = await serverCall.customAxios.put(`/city/${id}`, body, {
      headers,
    });
    return response;
  };

  const getCity = async (id) => {
    const response = await serverCall.customAxios.get(`/city/${id}`, {
      headers,
    });
    return response;
  };

  const getAllPageCity = async () => {
    const response = await serverCall.customAxios.get(`/city/`, {
      headers,
    });
    return response;
  };

  const getCityByUserId = async (id) => {
    const response = await serverCall.customAxios.get(`/city/user/${id}`, {
      headers,
    });
    return response;
  };

  const deleteCity = async (id) => {
    const response = await serverCall.customAxios.delete(`/city/${id}`, {
      headers,
    });
    return response;
  };

  return {
    getAllCity,
    addCity,
    getCity,
    getAllPageCity,
    updateCity,
    getCityByUserId,
    deleteCity,
  };
};

export default CityService;
