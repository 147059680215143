import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const PaymentService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const createOrder = async (body) => {
    const response = await serverCall.customAxios.post(`/payment/order`, body, {
      headers,
    });
    return response;
  };

  const getRefills = async (body) => {
    const response = await serverCall.customAxios.post(
      `/payment/refills`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  // const getDealerToUserRefills = async (body) => {
  //   const response = await serverCall.customAxios.post(
  //     `/payment/user/dealer/refills`,
  //     body,
  //     {
  //       headers,
  //     }
  //   );
  //   return response;
  // };

  const statusChange = async (status, rechargeLimit, requestId) => {
    const response = await serverCall.customAxios.put(
      `/payment/dealer/status/${status}?rechargeLimit=${rechargeLimit}&requestId=${requestId}`,
      { headers }
    );
    return response;
  };

  const cancelStatusChange = async (status, reasonCancel, requestId) => {
    const response = await serverCall.customAxios.put(
      `/payment/dealer/status/${status}?reasonCancel=${reasonCancel}&requestId=${requestId}`,
      { headers }
    );
    return response;
  };

  const paymentDetails = async (id) => {
    const response = await serverCall.customAxios.get(`/payment/${id}`, {
      headers,
    });
    return response;
  };

  return {
    createOrder,
    getRefills,
    statusChange,
    cancelStatusChange,
    // getDealerToUserRefills,
    paymentDetails,
  };
};

export default PaymentService;
