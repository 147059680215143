import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { FORMIKERROR } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const AddController = (props) => {
  const { t } = useTranslation("common");
  const { formik, city, selectCity, update } = props;
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
            {update
              ? t("update") + " " + t("controlleraccount")
              : t("add") + " " + t("controlleraccount")}
          </h5>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Row className="row-sm">
              <Form.Group className="form-group">
                <Form.Label className="text-dark">{t("username")} :</Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  name="userName"
                  placeholder={t("username")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.userName && formik.touched.userName ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.userName}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">{t("email")} :</Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                  placeholder={t("email")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.email && formik.touched.email ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.email}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("childuserpassword")} :
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.childUserPassword}
                  onChange={formik.handleChange}
                  name="childUserPassword"
                  placeholder={t("childuserpassword")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.childUserPassword &&
                formik.touched.childUserPassword ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.childUserPassword}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">{t("city")} :</Form.Label>
                <Select
                  classNamePrefix="Select2"
                  closeMenuOnSelect={false}
                  isMulti
                  options={city}
                  name="cityIds"
                  placeholder={t("city")}
                  value={formik.values.cityIds}
                  onChange={selectCity}
                />
                {formik.errors.cityIds && formik.touched.cityIds ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.cityIds}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Button
                  type="submit"
                  className="btn ripple btn-primary btn-block"
                >
                  {update ? t("update") : t("add")}
                </Button>
              </Form.Group>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddController;
