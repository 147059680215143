import React, { useState } from "react";
import Multiselect from "react-select";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CUSTOMDROPDOWNSTYLE, FORMIKERROR } from "../../utils/constants";
import Enums from "../../utils/enums";
import STATE from "../../utils/city.json";
import "react-phone-input-2/lib/style.css";

const AddCity = (props) => {
  const { t } = useTranslation("common");
  const { COMISSION } = Enums();
  // const [newSelectedState, setNewSelectedState] = useState("");
  // const [newSelectedCity, setNewSelectedCity] = useState("");
  const {
    formik,
    update,
    handleDateChange,
    handleMultipleDate,
    handleRepeatedHoliday,
    selectState,
    selectedState,
    selectCommision,
    selectCity,
    updatedStates,
    updatedCities,
    openState,
    handleOpenState,
    handleCloseState,
    openCity,
    handleOpenCity,
    handleCloseCity,
    newCity,
    handleOpenComission,
    handleCloseComission,
    openComission,
    stateOptions,
    cityOptions,
    setSearch,
    loadMore,
    visibleOptions,
    filteredOptions,
    changeCashback,
    cashbackInAmount,
    handleTogglePassword,
    showPassword,
    handleRemoveBussinessPartnerAPICredentials,
    handleAddBussinessPartnerAPICredentials,
    addBussinessPartnerAPICredentials,
    BUSINESS_PARTNER_API_NAME,
    openBusinessPartnerAPIName,
    handleOpenBusinessPartnerAPIName,
    handleCloseBusinessPartnerAPIName,
  } = props;

  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {update ? t("update") + " " + t("city") : t("add") + " " + t("city")}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row className="row-sm">
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("city")} : </Form.Label>
              {/* <Select
                classNamePrefix="Select2"
                id="name"
                name="name"
                displayValue="key"
                placeholder={t("city")}
                singleSelect
                options={cityOptions}
                menuIsOpen={openCity}
                onMenuOpen={handleOpenCity}
                onMenuClose={handleCloseCity}
                value={cityOptions.find(
                  (option) => option.label === formik.values.name
                )}
                onChange={selectCity}
                styles={CUSTOMDROPDOWNSTYLE}
              /> */}
              <Select
                classNamePrefix="Select2"
                id="name"
                name="name"
                displayValue="CITY" // Update to the correct property for display value
                placeholder="Select a city"
                singleSelect
                options={filteredOptions.slice(0, visibleOptions)}
                menuIsOpen={openCity}
                onMenuOpen={handleOpenCity}
                onMenuClose={handleCloseCity}
                onMenuScrollToBottom={loadMore}
                onChange={selectCity}
                value={cityOptions.find(
                  (option) => option.label === formik.values.name
                )}
                onInputChange={(inputValue) => setSearch(inputValue)}
              />
              {formik.errors.name && formik.touched.name ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.name}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("province")} :</Form.Label>
              <Select
                id="state"
                name="state"
                options={stateOptions}
                menuIsOpen={openState}
                onMenuOpen={handleOpenState}
                onMenuClose={handleCloseState}
                value={stateOptions.find(
                  (option) => option.value === formik.values.state
                )}
                placeholder={t("province")}
                onChange={selectState}
              />
              {/* <Form.Control
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                name="state"
                placeholder={t("province")}
                style={{ borderColor: "#d3d3de" }}
              /> */}
              {formik.errors.state && formik.touched.state ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.state}
                </Form.Label>
              ) : null}
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("choosestartandenddate")} :
              </Form.Label>
              <DatePicker
                calendarPosition="bottom-left"
                name="startDate endDate"
                value={[formik.values.startDate, formik.values.endDate]}
                onChange={(startDate, endDate) =>
                  handleDateChange(startDate, endDate)
                }
                range={true}
                dateSeparator=" to "
                rangeHover
                format="DD/MM/YYYY"
                style={{
                  width: "inherit",
                  height: "38px",
                  borderColor: "#D3D3DE",
                }}
                placeholder={t("choosestartandenddate")}
                minDate={update ? null : new Date()}
              />
              {formik.errors.startDate && formik.touched.startDate ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.startDate}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("holidaylist")} :
              </Form.Label>
              <DatePicker
                calendarPosition="bottom-left"
                name="holidayList.date"
                value={formik.values.holidayList?.map((item) => item.date)}
                onChange={(date) => handleMultipleDate(date)}
                multiple={true}
                format={"DD/MM/YYYY"}
                style={{
                  width: "inherit",
                  height: "38px",
                  borderColor: "#D3D3DE",
                }}
                placeholder={t("holidaylist")}
                minDate={update ? null : new Date()}
              />
              {formik.errors.holidayList?.map((item) => item?.date) &&
              formik.touched.holidayList?.map((item) => item?.date) ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.holidayList?.map((item) => item?.date)}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("holidayerepeatedeveryyear")} :
              </Form.Label>
              <DatePicker
                calendarPosition="bottom-left"
                name="repeatedholidayList.date"
                value={formik.values.repeatedholidayList?.map(
                  (item) => item.date
                )}
                onChange={(date) => handleRepeatedHoliday(date)}
                multiple={true}
                format={"DD/MM"}
                style={{
                  width: "inherit",
                  height: "38px",
                  borderColor: "#D3D3DE",
                }}
                placeholder={t("holidayerepeatedeveryyear")}
                minDate={update ? null : new Date()}
              />
              {formik.errors.repeatedholidayList?.map((item) => item?.date) &&
              formik.touched.repeatedholidayList?.map((item) => item?.date) ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.repeatedholidayList?.map((item) => item?.date)}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("updatenote")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.updateNote}
                onChange={formik.handleChange}
                name="updateNote"
                placeholder={t("updatenote")}
                style={{ borderColor: "#D3D3DE" }}
              />
              {formik.errors.updateNote && formik.touched.updateNote ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.updateNote}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("commission")} :</Form.Label>
              <Multiselect
                classNamePrefix="Select2"
                options={COMISSION}
                menuIsOpen={openComission}
                onMenuOpen={handleOpenComission}
                onMenuClose={handleCloseComission}
                value={COMISSION.find(
                  (option) => option.value === formik.values.commision
                )}
                singleSelect
                placeholder={t("commission")}
                displayValue="key"
                style={{
                  width: "inherit",
                  height: "38px",
                  borderColor: "#D3D3DE",
                }}
                name="commision"
                onChange={selectCommision}
                styles={CUSTOMDROPDOWNSTYLE}
              />
              {formik.errors.commision && formik.touched.commision ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.commision}
                </Form.Label>
              ) : null}
            </Form.Group>
            {/* <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("cashbackamount")} :
              </Form.Label>
              <Form.Control
                type="number"
                value={formik.values.cashbackAmount}
                onChange={formik.handleChange}
                name="cashbackAmount"
                placeholder={t("cashbackamount")}
                style={{ borderColor: "#D3D3DE" }}
              />
              {formik.errors.cashbackAmount && formik.touched.cashbackAmount ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.cashbackAmount}
                </Form.Label>
              ) : null}
            </Form.Group> */}
            <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {cashbackInAmount
                  ? t("cashbackamount")
                  : t("cashbacamountinpr")}{" "}
                :
              </Form.Label>
              <div class="form-group">
                <div class="input-group">
                  <Form.Control
                    type="number"
                    value={
                      cashbackInAmount
                        ? formik.values.cashbackAmount
                        : formik.values.cashbackAmountInPr
                    }
                    onChange={formik.handleChange}
                    name={
                      cashbackInAmount ? "cashbackAmount" : "cashbackAmountInPr"
                    }
                    placeholder={
                      cashbackInAmount
                        ? t("cashbackamount")
                        : t("cashbacamountinpr")
                    }
                    style={{ borderColor: "#D3D3DE" }}
                  />
                  <span class="input-group-btn">
                    <select class="form-control" onChange={changeCashback}>
                      <option value="$">$</option>
                      <option value="%">%</option>
                    </select>
                  </span>
                </div>
              </div>
              {(formik.errors.cashbackAmount ||
                formik.errors.cashbackAmountInPr) &&
              (formik.touched.cashbackAmount ||
                formik.touched.cashbackAmountInPr) ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.cashbackAmount}
                </Form.Label>
              ) : null}
            </Form.Group>

            {/* <Form.Group className="form-group">
              <Form.Label className="text-dark">
                {t("endpointurl")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.endpointUrl}
                onChange={formik.handleChange}
                name="endpointUrl"
                placeholder={t("endpointurl")}
                style={{ borderColor: "#D3D3DE" }}
              />
              {formik.errors.endpointUrl && formik.touched.endpointUrl ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.endpointUrl}
                </Form.Label>
              ) : null}
            </Form.Group> */}

            {/* <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("username")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.username}
                onChange={formik.handleChange}
                name="username"
                placeholder={t("username")}
                style={{ borderColor: "#D3D3DE" }}
              />
              {formik.errors.username && formik.touched.username ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.username}
                </Form.Label>
              ) : null}
            </Form.Group>

            <Form.Group
              className="text-start form-group"
              controlId="formpassword"
            >
              <Form.Label className="text-dark">{t("password")}</Form.Label>
              <div
                className="d-flex"
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <Form.Control
                  className="form-control"
                  placeholder="Enter your password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <i
                  style={{
                    position: "absolute",
                    top: "38%",
                    right: "4%",
                  }}
                  onClick={handleTogglePassword}
                  className={`fa ${
                    showPassword ? "fa-eye-slash" : "fa-eye"
                  } text-dark`}
                ></i>
              </div>
            </Form.Group> */}

            <div
              style={{
                overflow: "auto",
                paddingTop: "15px",
                marginBottom: "15px",
                maxHeight: "450px",
                border: "0.5px solid #efefef",
                padding: "10px 10px 0px 10px",
              }}
            >
              {addBussinessPartnerAPICredentials < 1 && (
                <div className="d-flex align-items-center">
                  <Form.Label className="text-black">
                    {t("bussinesspartnerapicredentials")}
                  </Form.Label>
                  <div className="ms-auto mb-1">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm ms-2"
                      onClick={handleAddBussinessPartnerAPICredentials}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              )}
              {/* Add and remove slot if we want multiple slots of address */}
              {[...Array(addBussinessPartnerAPICredentials)].map((_, index) => (
                <>
                  <div className="d-flex">
                    <Form.Label className="text-black">
                      {t("bussinesspartnerapicredentials")}. {index + 1}
                    </Form.Label>
                    <div className="ms-auto mb-1">
                      <>
                        {index === addBussinessPartnerAPICredentials - 1 && (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm ms-2"
                            onClick={handleAddBussinessPartnerAPICredentials}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        )}
                        {/* {index > 0 && ( */}
                        <button
                          type="button"
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() =>
                            handleRemoveBussinessPartnerAPICredentials(index)
                          }
                        >
                          <i className="fa fa-times"></i>
                        </button>
                        {/* )} */}
                      </>
                      {/* ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() =>
                            handleRemoveBussinessPartnerAPICredentials(index)
                          }
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      )} */}
                    </div>
                  </div>
                  <Form.Group className="form-group" key={index}>
                    <div key={index}>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("businesspartnerapiname")} :
                        </Form.Label>
                        <Multiselect
                          classNamePrefix="Select2"
                          options={BUSINESS_PARTNER_API_NAME}
                          singleSelect
                          placeholder={t("businesspartnerapiname")}
                          displayValue="key"
                          name={`businessPartnerAPICredentials[${index}].businessPartnerAPIName`}
                          menuIsOpen={openBusinessPartnerAPIName[index]}
                          onMenuOpen={() =>
                            handleOpenBusinessPartnerAPIName(index)
                          }
                          onMenuClose={() =>
                            handleCloseBusinessPartnerAPIName(index)
                          }
                          value={BUSINESS_PARTNER_API_NAME.find(
                            (option) =>
                              option.value ===
                              formik.values.businessPartnerAPICredentials[index]
                                ?.businessPartnerAPIName
                          )}
                          onChange={(selectedOption) =>
                            formik.setFieldValue(
                              `businessPartnerAPICredentials[${index}].businessPartnerAPIName`,
                              selectedOption.value
                            )
                          }
                          styles={CUSTOMDROPDOWNSTYLE}
                        />
                        {formik.errors.businessPartnerAPICredentials &&
                        formik.errors.businessPartnerAPICredentials[index]
                          ?.businessPartnerAPIName &&
                        formik.touched.businessPartnerAPICredentials &&
                        formik.touched.businessPartnerAPICredentials[index]
                          ?.businessPartnerAPIName ? (
                          <Form.Label style={FORMIKERROR}>
                            {
                              formik.errors.businessPartnerAPICredentials[index]
                                ?.businessPartnerAPIName
                            }
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("username")} :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={formik.handleChange}
                          placeholder={t("username")}
                          style={{ borderColor: "#D3D3DE" }}
                          name={`businessPartnerAPICredentials[${index}].username`}
                          value={
                            formik.values.businessPartnerAPICredentials[index]
                              ?.username
                          }
                        />
                        {formik.errors.businessPartnerAPICredentials &&
                        formik.errors.businessPartnerAPICredentials[index]
                          ?.username &&
                        formik.touched.businessPartnerAPICredentials &&
                        formik.touched.businessPartnerAPICredentials[index]
                          ?.username ? (
                          <Form.Label style={FORMIKERROR}>
                            {
                              formik.errors.businessPartnerAPICredentials[index]
                                ?.username
                            }
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      <Form.Group
                        className="text-start form-group"
                        controlId="formpassword"
                      >
                        <Form.Label className="text-dark">
                          {t("password")}
                        </Form.Label>
                        <div
                          className="d-flex"
                          style={{
                            position: "relative",
                            display: "flex",
                          }}
                        >
                          <Form.Control
                            className="form-control"
                            placeholder="Enter your password"
                            type={showPassword ? "text" : "password"}
                            onChange={formik.handleChange}
                            name={`businessPartnerAPICredentials[${index}].password`}
                            value={
                              formik.values.businessPartnerAPICredentials[index]
                                ?.password
                            }
                          />
                          <i
                            style={{
                              position: "absolute",
                              top: "38%",
                              right: "4%",
                            }}
                            onClick={handleTogglePassword}
                            className={`fa ${
                              showPassword ? "fa-eye-slash" : "fa-eye"
                            } text-dark`}
                          ></i>
                        </div>
                        {formik.errors.businessPartnerAPICredentials &&
                        formik.errors.businessPartnerAPICredentials[index]
                          ?.password &&
                        formik.touched.businessPartnerAPICredentials &&
                        formik.touched.businessPartnerAPICredentials[index]
                          ?.password ? (
                          <Form.Label style={FORMIKERROR}>
                            {
                              formik.errors.businessPartnerAPICredentials[index]
                                ?.password
                            }
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label className="text-dark">
                          {t("endpointurl")} :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={
                            formik.values.businessPartnerAPICredentials[index]
                              ?.endpointUrl
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              `businessPartnerAPICredentials[${index}].endpointUrl`,
                              e.target.value
                            )
                          }
                          name={`businessPartnerAPICredentials[${index}].endpointUrl`}
                          placeholder={t("endpointurl")}
                          style={{ borderColor: "#d3d3de" }}
                        />
                        {formik.errors.businessPartnerAPICredentials &&
                        formik.errors.businessPartnerAPICredentials[index]
                          ?.endpointUrl &&
                        formik.touched.businessPartnerAPICredentials &&
                        formik.touched.businessPartnerAPICredentials[index]
                          ?.endpointUrl ? (
                          <Form.Label style={FORMIKERROR}>
                            {
                              formik.errors.businessPartnerAPICredentials[index]
                                ?.endpointUrl
                            }
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                    </div>
                  </Form.Group>
                </>
              ))}
            </div>

            <Form.Group>
              <Button
                type="submit"
                className="btn ripple btn-primary btn-block"
              >
                {update ? t("update") : t("add")}
              </Button>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCity;
