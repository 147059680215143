export const SUCCESSFULLY_LOGIN = "Login successfully.";
export const SUCCESSFULLY_LOGOUT = "User logout.";

export const CHILD_USER_LOGIN_ERROR = "Child User Cannot Login!";

export const SUCCESSFULLY_USER_CREATED = "User Created Successfully.";
export const SUCCESSFULLY_USER_UPDATED = "User Updated Successfully.";
export const SUCCESSFULLY_USER_DELETED = "User Deleted Successfully.";

export const SUCCESSFULLY_AREA_ADDED = "Area Added Successfully.";
export const SUCCESSFULLY_AREA_UPDATED = "Area Updated Successfully.";
export const SUCCESSFULLY_AREA_DELETED = "Area Deleted Successfully.";

export const SUCCESSFULLY_CITY_ADDED = "City Added Successfully.";
export const SUCCESSFULLY_CITY_UPDATED = "City Updated Successfully.";
export const SUCCESSFULLY_CITY_DELETED = "City Deleted Successfully.";

export const SUCCESSFULLY_BUSINESSPARTNER_DELETED =
  "Business Partner Deleted Successfully.";
export const SUCCESSFULLY_BUSINESSPARTNER_CREATED =
  "Business Partner Created Successfully.";
export const SUCCESSFULLY_BUSINESSPARTNER_UPDATED =
  "Business Partner Updated Successfully.";

export const SUCCESSFULLY_DEALER_REQUEST_APPROVED =
  "Dealer Request Approved Successfully.";
export const SUCCESSFULLY_DEALER_REQUEST_RECHARGE =
  "Dealer Reuqests Recharge Successfully.";
export const SUCCESSFULLY_DEALER_REQUEST_REJECTED =
  "Dealer Reuqests Rejected Successfully.";

export const SUCCESSFULLY_PARKING_ADDED = "Parking Added Successfully.";
export const SUCCESSFULLY_PARKING_UPDATED = "Parking Updated Successfully.";
export const SUCCESSFULLY_PARKING_DELETED = "Parking Deleted Successfully.";

export const SUCCESSFULLY_CHILD_PARTNER_DELETED =
  "Child Business Deleted Successfully.";

export const ADMIN_PROFILE_UPDATE_SUCCESSFULLY =
  "Admin Profile Updated Successfully.";
export const DEALER_PROFILE_UPDATE_SUCCESSFULLY =
  "Dealer Profile Updated Successfully.";
export const BUSINESS_PARTNER_PROFILE_UPDATE_SUCCESSFULLY =
  "Business Partner Profile Updated Successfully.";
export const CHILD_BUSINESS_PARTNER_PROFILE_UPDATE_SUCCESSFULLY =
  "Child Business Partner Profile Updated Successfully.";

export const SET_NEW_PASSWORD_SUCCESSFULLY = "Set New Password Successfully!";
export const OTP_VERIFIED = "OTP Verified!";

export const RECHARGE_ADDED = "Successfully Recharged!";
