import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FORMIKERROR } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Divider } from "antd";

const AddDisputeRequest = (props) => {
  const { t } = useTranslation("common");
  const {
    formik,
    handleOpen,
    handleUserAmountChange,
    handleDealerAmountChange,
    amount,
    date,
  } = props;

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("submit") + " " + t("dispute")}
        </h5>
      </Modal.Header>

      <Modal.Body>
        <form>
          <Row className="row-sm">
            <Form.Group>
              <Form.Label className="text-black tx-16">
                {t("totalamount")} : {amount}
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-black tx-16">
                {t("date")} : {moment(date).format("DD/MM/YYYY HH:mm:ss")}
              </Form.Label>
            </Form.Group>
            <Divider className="mt-2" />

            <Col md={6}>
              <h5 className="tx-semibold mb-3 ms-auto tx-uppercase">
                {t("dealer")}
              </h5>
              <Form.Group className="form-group">
                <Form.Label>{t("dealername")}</Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.dealername}
                  onChange={formik.handleChange}
                  name="dealername"
                  placeholder={t("dealername")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.dealername && formik.touched.dealername ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.dealername}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>{t("rechargelimit")}</Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.dealerWalletAmount}
                  onChange={formik.handleChange}
                  name="dealerWalletAmount"
                  placeholder={t("dealer") + " " + t("rechargelimit")}
                  style={{ borderColor: "#d3d3de" }}
                  disabled
                />
                {formik.errors.dealerWalletAmount &&
                formik.touched.dealerWalletAmount ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.dealerWalletAmount}
                  </Form.Label>
                ) : null}
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>{t("recharge")}</Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.dealerAmount}
                  onChange={handleDealerAmountChange}
                  name="dealerAmount"
                  placeholder={t("dealer") + " " + t("rechargelimit")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.dealerAmount && formik.touched.dealerAmount ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.dealerAmount}
                  </Form.Label>
                ) : null}
              </Form.Group>

              {formik.values.dealerAfterPayment ? (
                <Form.Group className="form-group">
                  <Form.Label>
                    {t("afterwalletamount") + " " + t("of") + " " + t("dealer")}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formik.values.dealerAfterPayment}
                    name="dealerAfterPayment"
                    placeholder={t("dealer") + " " + t("amount")}
                    style={{ borderColor: "#d3d3de" }}
                    disabled
                  />
                </Form.Group>
              ) : (
                ""
              )}

              {/* <Form.Group>
                <div>
                  <Button
                    onClick={handleOpen}
                    className="btn ripple btn-primary btn-sm d-flex ms-auto tx-uppercase"
                  >
                    {t("submit")}
                  </Button>
                </div>
              </Form.Group> */}
            </Col>

            <Col md={6}>
              <h5 className="tx-semibold mb-3 ms-auto tx-uppercase">
                {t("user")}
              </h5>
              <Form.Group className="form-group">
                <Form.Label>{t("username")}</Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  name="username"
                  placeholder={t("username")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.username && formik.touched.username ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.username}
                  </Form.Label>
                ) : null}
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>{t("walletamount")}</Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.userWalletAmount}
                  onChange={formik.handleChange}
                  name="userWalletAmount"
                  placeholder={t("walletamount")}
                  style={{ borderColor: "#d3d3de" }}
                  disabled
                />
                {formik.errors.userWalletAmount &&
                formik.touched.userWalletAmount ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.userWalletAmount}
                  </Form.Label>
                ) : null}
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>{t("amount")}</Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.userAmount}
                  onChange={handleUserAmountChange}
                  name="userAmount"
                  placeholder={t("user") + " " + t("amount")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.userAmount && formik.touched.userAmount ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.userAmount}
                  </Form.Label>
                ) : null}
              </Form.Group>

              {formik.values.userAfterPayment ? (
                <Form.Group className="form-group">
                  <Form.Label>
                    {t("afterwalletamount") + " " + t("of") + " " + t("user")}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formik.values.userAfterPayment}
                    name="userAfterPayment"
                    style={{ borderColor: "#d3d3de" }}
                    disabled
                  />
                </Form.Group>
              ) : (
                ""
              )}

              <Form.Group>
                <div>
                  <Button
                    // type="submit"
                    onClick={handleOpen}
                    className="btn ripple btn-primary btn-sm d-flex ms-auto tx-uppercase"
                  >
                    {t("submit")}
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const ConfirmRequest = (props) => {
  const { t } = useTranslation("common");
  const { handleClose, formik } = props;
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-v  center"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("submit") + " " + t("dispute")}
        </h5>
      </Modal.Header>

      <Modal.Body className="text-center p-0 pb-4">
        <form onSubmit={formik.handleSubmit}>
          <p className="mg-b-20 mg-x-20 fs-18 mt-3">
            {t("areyousurewanttoupdate")} {t("walletamount")} of{" "}
            {formik.values.dealername} ?
          </p>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              // onClick={handleClose}
              variant="success"
              className="me-3 btn-sm"
            >
              {t("submit")}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddDisputeRequest;
