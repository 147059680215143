import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Button, Card, Form, Modal, Row } from "react-bootstrap";
import useDialogState from "../../hooks/useDialog";
import withLoader from "../../layout/Loader/WithLoader";
import {
  ANCHORORIGIN,
  FORMIKERROR,
  PAGELIMIT,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import PaymentService from "../../services/payment.service";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Select } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import jsPDF from "jspdf";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import TransactionService from "../../services/transaction.service";
import Enums from "../../utils/enums";

const neosStorageString = localStorage.getItem("neos-storage");
const neosStorage = JSON.parse(neosStorageString);

const userId = neosStorage && neosStorage.id;

const RechargeRequest = () => {
  const { RANGES } = Enums();
  const paymentService = PaymentService();
  const transactionService = TransactionService();
  const { t } = useTranslation("common");
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [showModal, setShowModal] = useState(false);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const handleOpen = () => {
    handleDialogOpen();
  };

  const getRefills = async () => {
    const response = await transactionService.getDealerRefills({
      endDate: new Date(endDate).getTime(),
      needDealerList: true,
      pageLimit: dataPerPage,
      pageNumber: pageNumber,
      startDate: new Date(startDate).getTime(),
      userId: userId,
    });
    setData(response.data.apiresponse.data.dealerRefills);
    setTotalRows(response.data.apiresponse.data.totalRecords);
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handleStatusChange = (paymentId, status) => {
    setShowCancelModal(true);
    formik.setFieldValue("requestId", paymentId);
    formik.setFieldValue("status", status);
  };

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: yup.object().shape({
      reason: yup.string().required("Please enter valid reason!"),
    }),
    onSubmit: async (values, action) => {
      const { requestId, reason, status } = values;
      setShowModal(false);
      setShowCancelModal(false);
      const response = await transactionService.changeStatusOfDealerRequest(
        status,
        reason,
        0,
        requestId
      );

      action.resetForm();
      getRefills();
      enqueueSnackbar(t("SUCCESSFULLY_DEALER_REQUEST_REJECTED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
    },
  });

  useEffect(() => {
    getRefills();
  }, []);

  useEffect(() => {
    getRefills();
  }, [pageNumber, startDate, endDate, dataPerPage]);

  const columns = [
    {
      name: <strong className="tx-uppercase">NO</strong>,
      selector: "index",
      cell: (row, index) => (
        <div title={`${totalRows - index}`}>{totalRows - index}</div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("importbeforerecharge")}</strong>
      ),
      selector: "beforeWalletAmount",
      cell: (row) => (
        <div
          title={`€${
            row.beforeWalletAmount
              ? ReplaceFunction(row.beforeWalletAmount?.toFixed(2))
              : row.beforeWalletAmount
          }`}
        >
          {`€${
            row.beforeWalletAmount
              ? ReplaceFunction(row.beforeWalletAmount?.toFixed(2))
              : row.beforeWalletAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("importrecharge")}</strong>,
      selector: "grossAmount",
      cell: (row) => (
        <div
          title={`${
            row.grossAmount
              ? `${
                  row.approvalStatus === "APPROVED"
                    ? `€${
                        row.grossAmount - row.amountOfEarning !== 0
                          ? ReplaceFunction(
                              (
                                row.grossAmount?.toFixed(2) -
                                row.amountOfEarning?.toFixed(2)
                              ).toFixed(2)
                            )
                          : row.grossAmount - row.amountOfEarning
                      }`
                    : `€${ReplaceFunction(row.grossAmount?.toFixed(2))}`
                }`
              : "--"
          }`}
        >
          {row.grossAmount
            ? `${
                row.approvalStatus === "APPROVED"
                  ? `€${
                      row.grossAmount - row.amountOfEarning !== 0
                        ? ReplaceFunction(
                            (
                              row.grossAmount?.toFixed(2) -
                              row.amountOfEarning?.toFixed(2)
                            ).toFixed(2)
                          )
                        : row.grossAmount - row.amountOfEarning
                    }`
                  : `€${ReplaceFunction(row.grossAmount?.toFixed(2))}`
              }`
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("importafterrecharge")}</strong>
      ),
      selector: "afterWalletAmount",
      cell: (row) => (
        <div
          title={`€${
            row.afterWalletAmount
              ? ReplaceFunction(row.afterWalletAmount?.toFixed(2))
              : row.afterWalletAmount
          }`}
        >
          {`€${
            row.afterWalletAmount
              ? ReplaceFunction(row.afterWalletAmount?.toFixed(2))
              : row.afterWalletAmount
          }`}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("paydate")}</strong>,
      selector: "payDate",
      cell: (row) => (
        <div title={`${moment(row.payDate).format("DD/MM/YYYY HH:mm:ss")}`}>
          {moment(row.payDate).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("transactionstatus")}</strong>,
      selector: "transactionStatus",
      cell: (row) => (
        <div className="d-flex">
          <div className="">
            {row.transactionStatus === "IN_PROGRESS" && (
              <div className="text-warning">
                <i class="fa fa-hourglass"></i> {t("inprogress")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "COMPLETED" && (
              <div className="text-success">
                <i className="fa fa-check"></i> {t("completed")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "REFUND" && (
              <div className="text-info">
                <i className="fa fa-rotate-left"></i> {t("refunded")}
              </div>
            )}
          </div>
          <div className="">
            {row.transactionStatus === "CANCEL" && (
              <div className="text-danger">
                <i className="fa fa-close"></i> {t("cancel")}
              </div>
            )}
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("approvalstatus")}</strong>,
      selector: "approvalStatus",
      cell: (row) => (
        <div className="d-flex">
          <div className="">
            {row.approvalStatus === "PENDING" && (
              <div className="select-pending">
                <Select
                  value={"PENDING"}
                  onChange={(newStatus) =>
                    handleStatusChange(row.paymentId, newStatus)
                  }
                  style={{ width: "110px" }}
                >
                  <option
                    value="CANCELLED"
                    className="text-white mt-1"
                    style={{ backgroundColor: "#F53729" }}
                  >
                    <i className="si si-close"></i> {t("cancel")}
                  </option>
                  <option
                    value="PENDING"
                    className="d-none"
                    style={{ backgroundColor: "#F1911D" }}
                  >
                    <div>
                      <i className="fa fa-clock"></i> {t("pending")}
                    </div>
                  </option>
                </Select>
              </div>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "EXPIRED" && (
              <button
                className="btn btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                  backgroundColor: "#dd3232",
                  color: "#ffffff",
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#a32525";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "#dd3232";
                }}
              >
                <i class="fas fa-stopwatch"></i> {t("expired")}
              </button>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "APPROVED" && (
              <button
                className="btn btn-success btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i className="fa fa-check"></i> {t("approved")}
              </button>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "INITIATE" && (
              <button
                className="btn btn-info btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i className="fas fa-stopwatch"></i> {t("initiated")}
              </button>
            )}
          </div>
          <div className="">
            {row.approvalStatus === "CANCELLED" && (
              <button
                className="btn btn-danger btn-sm"
                style={{
                  width: "110px",
                  textAlign: "center",
                  cursor: "default",
                }}
              >
                <i className="si si-close"></i> {t("canceled")}
              </button>
            )}
          </div>
          <div className="">{row.approvalStatus === null && <div>--</div>}</div>
        </div>
      ),
      sortable: true,
      minWidth: "130px",
    },
    {
      name: <strong className="tx-uppercase">{t("receipt")}</strong>,
      selector: "approvalStatus",
      cell: (row) => (
        <div>
          {row.approvalStatus === "APPROVED" ? (
            <button
              className="btn btn-primary btn-sm"
              onClick={() =>
                exportPDF(
                  row.username,
                  row.surname,
                  row.beforeWalletAmount,
                  row.grossAmount,
                  row.afterWalletAmount,
                  row.creationDate,
                  row.payDate,
                  row.approvalStatus,
                  row.amountOfEarning,
                  row.percantageOfEarning
                )
              }
            >
              <i class="fas fa-file-invoice"></i>
            </button>
          ) : (
            "--"
          )}
        </div>
      ),
      sortable: true,
    },
  ];

  const exportPDF = (
    username,
    surname,
    beforeWalletAmount,
    grossAmount,
    afterWalletAmount,
    creationDate,
    payDate,
    approvalStatus,
    amountOfEarning,
    percantageOfEarning
  ) => {
    const unit = "pt";
    const size = "A5"; // Use A1, A2, A3, or A4
    const orientation = "landscape"; // portrait or landscape
    const titleFontSize = 20;
    const textColor = [0, 0, 0];

    const doc = new jsPDF(orientation, unit, size);

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 400, 20, 150, 60);

    const title = "Ricevuta di ricarica rivenditore";
    doc.setFontSize(titleFontSize);
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.setFont("helvetica", "bold");
    doc.text(title, 40, 60);

    const varName = "Nome utente :";
    doc.setFont("helvetica", "normal");
    doc.setFontSize(18);
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(varName, 40, 120); // Adjust the vertical position as needed

    const name = username + " " + surname;
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(name, 320, 120); // Adjust the vertical position as needed

    const varRechargeLimit = "Saldo precedente :";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(varRechargeLimit, 40, 150); // Adjust the vertical position as needed

    const rechargeLimit = `€${
      beforeWalletAmount
        ? ReplaceFunction(beforeWalletAmount?.toFixed(2))
        : beforeWalletAmount
    }`;
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(rechargeLimit, 320, 150); // Adjust the vertical position as needed

    const varGross = "Importo ricarica :";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(varGross, 40, 180); // Adjust the vertical position as needed

    const gross = `€${
      approvalStatus === "APPROVED"
        ? grossAmount - amountOfEarning !== 0
          ? ReplaceFunction(
              (grossAmount?.toFixed(2) - amountOfEarning?.toFixed(2)).toFixed(2)
            )
          : grossAmount - amountOfEarning
        : ReplaceFunction(grossAmount?.toFixed(2))
    }`;
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(gross, 320, 180); // Adjust the vertical position as needed

    const dealersFee = "Commissione del rivenditore :";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(dealersFee, 40, 210); // Adjust the vertical position as needed

    const dealersFeeValue = `€${
      percantageOfEarning
        ? ReplaceFunction(percantageOfEarning?.toFixed(2))
        : percantageOfEarning
    }`;
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(dealersFeeValue, 320, 210); // Adjust the vertical position as needed

    const varAfterAmount = "Saldo finale :";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(varAfterAmount, 40, 240); // Adjust the vertical position as needed

    const afterAmount = `€${
      afterWalletAmount
        ? ReplaceFunction(afterWalletAmount?.toFixed(2))
        : afterWalletAmount
    }`;
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(afterAmount, 320, 240); // Adjust the vertical position as needed

    const varCreationDate = "Data di richiesta di ricarica :";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(varCreationDate, 40, 270); // Adjust the vertical position as needed

    const date = `${moment(creationDate).format("DD/MM/YYYY")}`;
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(date, 320, 270); // Adjust the vertical position as needed

    const varPayDate = "Data di conferma :";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(varPayDate, 40, 300); // Adjust the vertical position as needed

    const finalePayDate = `${moment(payDate).format("DD/MM/YYYY")}`;
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(finalePayDate, 320, 300); // Adjust the vertical position as needed

    doc.save(`${username}_Recharge_Receipt.pdf`);
  };

  const customStyles = {
    headCells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "175px",
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
      },
    },
    cells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "175px",
        },
      },
    },
    rows: {
      style: {
        ":hover": {
          backgroundColor: "#EEEEEE !important",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#fafafa", // Set the background color for even rows
        },
        "&:nth-child(even)": {
          backgroundColor: "#FFFFFF", // Set the background color for odd rows
        },
        "&:nth-child(even) > :last-child": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :last-child": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even) > :nth-last-child(2)": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :nth-last-child(2)": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on even rows
          },
        },
        "&:nth-child(odd):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on odd rows
          },
        },
      },
    },
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("rechargerequest")}
      </h2>

      {showCancelModal && (
        <RejectRequest
          show={showCancelModal}
          onHide={() => setShowCancelModal(false)}
          formik={formik}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}

      {showModal && (
        <ConfirmReject
          show={showModal}
          onHide={() => setShowModal(false)}
          hideLastModal={() => setShowCancelModal(false)}
          formik={formik}
        />
      )}

      <Card>
        <div className="p-3">
          <div className="d-flex mb-3">
            <Form.Group className="form-group mb-0">
              {/* <DateRangePicker
                initialSettings={{
                  startDate: moment(new Date())
                    .subtract(1, "months")
                    .format("MM/DD/YYYY"),
                  endDate: moment(new Date()).format("MM/DD/YYYY"),
                  ranges: RANGES,
                }}
                onApply={setDates}
              >
                <input
                  type="text"
                  value={
                    moment(startDate).format("DD/MM/YYYY") +
                    " - " +
                    moment(endDate).format("DD/MM/YYYY")
                  }
                  className="form-control"
                />
              </DateRangePicker> */}

              <DateRangePicker
                initialSettings={{
                  startDate: moment(new Date()).format("DD/MM/YYYY"),
                  endDate: moment(new Date()).format("DD/MM/YYYY"),
                  ranges: RANGES,
                  autoUpdateInput: false,
                  locale: {
                    format: "DD/MM/YYYY", // Specify the desired format
                  },
                }}
                onApply={setDates}
                onCancel={cancelDate}
                onHide={cancelDate}
              >
                {startDate && endDate ? (
                  <input
                    type="text"
                    placeholder={t("selectperiodvalidity")}
                    value={
                      startDate && endDate
                        ? `${moment(startDate).format("DD/MM/YYYY")} - ${moment(
                            endDate
                          ).format("DD/MM/YYYY")}`
                        : ""
                    }
                    defaultValue=""
                    onChange={(startDate, endDate) =>
                      handleDateChange(startDate, endDate)
                    }
                    className="form-control"
                  />
                ) : (
                  <input
                    type="text"
                    placeholder={t("selectperiodvalidity")}
                    defaultValue=""
                    className="form-control"
                  />
                )}
              </DateRangePicker>
            </Form.Group>

            <button
              variant="danger"
              className="btn btn-primary btn-sm ms-auto"
              onClick={handleOpen}
            >
              <i className="fe fe-plus"></i> {t("add")}
            </button>
          </div>
          {isDialogOpen && (
            <AmountDialog
              show={isDialogOpen}
              onHide={handleDialogClose}
              getRefills={getRefills}
            />
          )}
          <DataTable
            title="Recharge Request"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={customStyles}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(RechargeRequest);

const AmountDialog = (props) => {
  const { t } = useTranslation("common");
  const transactionService = TransactionService();
  const { getRefills, onHide } = props;

  // const {
  //   open: isDialogOpen,
  //   handleClickOpen: handleDialogOpen,
  //   handleClose: handleDialogClose,
  // } = useDialogState();

  // const handleOpen = () => {
  //   handleDialogOpen();
  //   // props.onHide();
  // };

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: yup.object().shape({
      amount: yup.string().required("Please enter valid reason!"),
    }),
    onSubmit: async (values, action) => {
      const { amount } = values;
      const response = await transactionService.createOrder(amount);

      action.resetForm();
      getRefills();
      onHide();
      enqueueSnackbar("Order Created Successfully", {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
    },
  });

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">{t("amount")}</h5>
      </Modal.Header>

      <Modal.Body>
        {/* <div ref={paypal}></div> */}

        <Row className="row-sm">
          <form onSubmit={formik.handleSubmit}>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("amount")} :</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                placeholder={t("amount")}
                style={{ borderColor: "#d3d3de" }}
              />{" "}
            </Form.Group>
            <div className="d-flex">
              <button
                className="btn btn-primary btn-sm ms-auto"
                // onClick={handleOpen}
                type="submit"
              >
                {t("paynow")}
              </button>
              {/* {isDialogOpen && (
              <AddAmount
                show={isDialogOpen}
                onHide={handleDialogClose}
                value={value}
                dialogClose={props.onHide}
                getRefills={getRefills}
              />
            )} */}
            </div>
          </form>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const AddAmount = (props) => {
  const paymentService = PaymentService();
  const { t } = useTranslation("common");
  const { value, onHide, dialogClose, getRefills } = props;

  useEffect(() => {
    window.paypal
      ?.Buttons({
        // Set up the transaction
        createOrder: (data, actions, err) => {
          return actions.order
            .create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  description: "Amount Transfered",
                  amount: {
                    currency_code: "EUR",
                    value: value,
                  },
                },
              ],
            })
            .then(async (id) => {
              const response = await paymentService.createOrder({
                amount: value,
                orderId: id,
                userId: userId,
              });

              return id;
            });
        },

        // Finalize the transaction
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();

          return actions.order.capture().then(function (details) {
            // Show a success message to the buyer

            // alert(
            //   "Transaction completed by " + details.payer.name.given_name + "!"
            // );
            enqueueSnackbar(
              "Transaction completed by " + details.payer.name.given_name + "!",
              {
                anchorOrigin: ANCHORORIGIN,
                variant: SUCCESS,
              }
            );
            onHide();
            dialogClose();
            getRefills();
          });
        },
        onerror: (err) => {},
      })
      // .render(paypal.current);
      .render("#paypal-button-container");
  }, []);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto">
          {t("add") + " " + t("amount")}
        </h5>
      </Modal.Header>

      <Modal.Body className="text-center p-0 pb-4">
        {/* <div id="paypal-button-container" className="m-3" /> */}
      </Modal.Body>
    </Modal>
  );
};

const RejectRequest = (props) => {
  const { formik, showModal, setShowModal } = props;
  const { t } = useTranslation("common");

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
            {t("cancel") + " " + t("request")}
          </h5>
        </Modal.Header>

        <Modal.Body>
          {/* <form onSubmit={formik.handleSubmit}> */}
          <Row className="row-sm">
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("reason")} :</Form.Label>
              <Form.Control
                as="textarea"
                value={formik.values.reason}
                onChange={formik.handleChange}
                name="reason"
                placeholder={t("reason")}
                style={{ borderColor: "#D3D3DE" }}
              />{" "}
              {formik.errors.reason && formik.touched.reason ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.reason}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="d-flex justify-content-end">
              <Button variant="danger" onClick={handleClick} className="btn-sm">
                {t("cancel")}
              </Button>
            </Form.Group>
          </Row>
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

const ConfirmReject = (props) => {
  const { formik } = props;
  const { t } = useTranslation("common");

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {t("reject") + " " + t("request")}
        </h5>
      </Modal.Header>

      <Modal.Body className="text-center pb-4">
        <form onSubmit={formik.handleSubmit}>
          <Row className="row-sm">
            <Form.Group className="form-group">
              <p className="fs-18">
                {t("cancelsentance")} {t("request")}?
              </p>
            </Form.Group>
            <Form.Group className="d-flex justify-content-end">
              <Button type="submit" variant="danger" className="btn-sm">
                {t("submit")}
              </Button>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};
